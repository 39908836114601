/* eslint-disable prettier/prettier */
import { max_devices } from "@utils/constants"
import styled, { css } from "styled-components/macro"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  height: calc(100% - 10vh);
  overflow-y: scroll;
`

export const BankWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 90vh;
  overflow-y: auto;
  width: 100%;
  align-items: center;
  @media ${max_devices.tablet} {
    height: 80vh;
  }
`

export const CardsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  .add-button {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: white;
    font-size: 26px;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 3%;
    align-items: center;
    right: ${({ show }) => (show ? "32%" : "2%")};
  }
`

export const LikesWrapper = styled.div`
  ${({ show }) =>
    show
      ? css`
          width: 30%;
          overflow-y: auto;
          border-left: 1px solid black;
        `
      : css`
          display: none;
        `}
`

export const CardContainer = styled.div`
  background: rgba(118, 118, 128, 0.24);
  margin: 1rem 0rem 0rem 0rem;
  width: 70vh;
  height: max-content;
  min-height: 70vh;
  display: flex;
  align-items: center;
  border-radius: 30px;
  flex-direction: column;

  .card-header {
    border-bottom: 2px solid rgba(255, 255, 255, 0.08);
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;

    .logo-img {
      background-color: white;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }

  .card-title {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    color: #fff;

    strong {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .main-image {
    width: 100%;
    height: 50vh;
  }

  @media ${max_devices.tablet} {
    width: 95%;
    min-height: auto;
  }
`

export const BankLocationContainer = styled.div`
  display: flex;
  flex-direction: row;

  .map_div {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding: 0 0.5rem;
  }

  .title {
    font-size: 12px;
    font-weight: 500;
    color: rgb(177, 177, 177);
  }

  .location_name {
    font-size: 14px;
    font-weight: 400;
  }
`

export const BankContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 1.5rem;
  color: white;
  height: 100%;
  justify-content: flex-end;
  flex: 1 1;

  .bank_para_div {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
  }

  .title {
    font-size: 12px;
    font-weight: 500;
    color: rgb(177, 177, 177);
  }

  .location_name {
    font-size: 14px;
    font-weight: 400;
  }
`
