import { userConstants } from "../_constants"

const name = localStorage.getItem("headerName")

const initialState = {
  headerName: name ? name : "Home"
}

export function profile(state = initialState, action) {
  switch (action.type) {
    case userConstants.HEADER:
      return {
        headerName: action.payload
      }
    default:
      return state
  }
}
