/* eslint-disable prettier/prettier */
import { sharedService } from "@src/_services"
import { useDispatch, useSelector } from "react-redux"

const useHook = () => {
  const dispatch = useDispatch()

  const { user } = useSelector(state => state.authentication)
  const { balance } = useSelector(state => state.sharedReducer)

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const getTransactionsList = async (api, load) => {
    if (load) {
      isLoading(true)
    }
    return await sharedService
      .apiCall(api)
      .then(async res => {
        isLoading(false)
        return res.data
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          return error.response?.data
        }
      })
  }

  return {
    getTransactionsList,
    user,
    balance
  }
}
export default useHook
