import moment from "moment"

export function getAge(dateString) {
  var today = new Date()
  var birthDate = new Date(dateString)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export const socialDataParser = socialData => {
  switch (socialData.provider) {
    case "instagram":
      return {
        response: socialData,
        lastActive: socialData.last_login,
        name: socialData?.extra_data?.username,
        followers: socialData?.extra_data?.media_count,
        profileLink: `https://www.instagram.com/${socialData?.extra_data?.username}/`
      }
    case "facebook":
      return {
        response: socialData,
        lastActive: socialData.last_login,
        name: `${socialData?.extra_data?.first_name} ${socialData?.extra_data?.last_name} `,
        profileLink: socialData?.extra_data?.link,
        profilePicture: socialData?.extra_data?.picture?.data?.url
      }
    case "linkedin_oauth2": {
      const firstName = socialData.extra_data.firstName.localized.en_US
      const lastName = socialData.extra_data.lastName.localized.en_US
      return {
        response: socialData,
        lastActive: socialData.last_login,
        name: `${firstName} ${lastName} `
      }
    }
    case "twitter":
      return {
        response: socialData,
        lastActive: socialData.last_login,
        name: socialData?.extra_data?.name,
        profileLink: `https://twitter.com/${socialData?.extra_data?.screen_name}`,
        profilePicture: socialData?.extra_data?.profile_image_url_https
      }

    case "tiktok":
      return {
        response: socialData,
        lastActive: socialData.last_login,
        name: socialData?.extra_data?.display_name,
        profileLink: `https://www.tiktok.com/@${socialData?.extra_data?.display_name}`,
        profilePicture: socialData?.extra_data?.avatar_url
      }

    case "google":
      const { custom_url, title, channel_id, thumbnails } =
        socialData?.youtube_channels?.[0] || {}
      return {
        response: socialData,
        lastActive: socialData.last_login,
        profileLink: `https://youtube.com/${custom_url || channel_id}`,
        name: title || socialData?.extra_data?.name,
        profilePicture:
          thumbnails?.default?.url || socialData?.extra_data?.picture
      }

    default:
      return socialData
  }
}

export const validateURL = link => {
  if (link.indexOf("http://") === 0 || link.indexOf("https://") === 0) {
    return true
  } else {
    return false
  }
}

export const getLocalTimeFromNow = date => {
  var stillUtc = moment.utc(date).toDate()
  return moment(stillUtc).local().fromNow()
}

export function formatCurreny(amount) {
  return Number(amount)
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      currencyDisplay: "code"
    })
    .replace("USD", "")
}
