/* eslint-disable prettier/prettier */
import styled from "styled-components"

export const Wrapper = styled.div`
  padding-top: 1rem;
  .walletcard {
    background: rgba(28, 32, 40, 1);
    border-radius: 15px;
  }
  .widthdrawl_head {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    color: white;
    font-weight: 500;
    font-size: 20px;
    align-items: center;
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    .widthdrawl_head {
      padding: 0.5rem;
      font-size: 16px;
    }
  }
`
export const CardBody = styled.div`
  padding: 1rem 2rem;
  color: white;
  .plaid_desc {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 1rem;
  }
  .wallet_secure {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
  }
  .secure_desc {
    color: rgba(255, 255, 255, 0.75);
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 1rem;
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    padding: 0.5rem;
    .plaid_desc {
      font-size: 14px;
    }
    .wallet_secure {
      font-size: 14px;
    }
    .secure_desc {
      font-size: 10px;
    }
  }
`
export const Button = styled.button`
  background: white;
  padding: 0.5rem;
  width: 100%;
  color: black;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
  outline: none;
  border: none;
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 12px;
  }
`
export const AgreePolicy = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  .half_agree {
    font-size: 12px;
  }
  .scndhalf {
    padding-left: 5px;
    font-size: 14px;
    text-decoration: underline;
    color: white;
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    padding: 0.5rem;
    .half_agree {
      font-size: 9px;
    }
    .scndhalf {
      font-size: 10px;
    }
  }
`
