/* eslint-disable prettier/prettier */

/* eslint-disable no-unused-expressions */
import { ATHLETE_ROUTES } from "@screens/home/athelete"
import Chat from "@screens/home/chats"
import { COMPANY_ROUTES } from "@screens/home/company"
import Header from "@screens/home/headers"
import SettingsPage from "@screens/home/settings"
import { PolicyPage, TermsPage, PaymentPage } from "@screens/home/shared"
import Sidebar from "@screens/home/sidebar"
import SocialLinks from "@screens/home/social-links"
import NotFound from "@src/_components/not-found"
import { history } from "@src/_helpers"
import "@src/container/Layout/Dashboard/layout.css"
import React from "react"
import { useSelector } from "react-redux"
import { Switch, Route, BrowserRouter } from "react-router-dom"

const Home = () => {
  const { user } = useSelector(state => state.authentication)
  const token = localStorage.getItem("token")
  const stage = localStorage.getItem("stage")

  if (!token || !user.user_type) {
    localStorage.clear()
    history.push("/login")
  }
  if (stage) {
    window.location.href = `/${stage}`
  }

  const isAthlete = user.user_type === "athlete"

  const SHARED_ROUTES = [
    { path: "/home/chat", component: Chat },
    { path: "/home/settings", component: SettingsPage },
    { path: "/home/policy", component: PolicyPage },
    { path: "/home/terms", component: TermsPage },
    { path: "/home/socials", component: SocialLinks },
    { path: "/home/payments", component: PaymentPage }
  ]

  let USER_ROUTES = isAthlete ? ATHLETE_ROUTES : COMPANY_ROUTES

  return (
    <BrowserRouter>
      <div className="main__wrapper">
        <Sidebar />
        <div className="content__wrapper">
          <Header />

          <Switch>
            {USER_ROUTES.map((item, index) => {
              return <Route key={index} exact {...item} />
            })}

            {SHARED_ROUTES.map((item, index) => {
              return <Route key={index} exact {...item} />
            })}
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default Home
