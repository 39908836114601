import React from "react"

const NotFound = props => {
  return (
    <div className="bg-color">
      <main>
        <div className="container-fluid" style={{ margin: "145px 0px" }}>
          <h2 className="text-white text-center">Page under construction</h2>
        </div>
      </main>
    </div>
  )
}

export default NotFound
