/* eslint-disable prettier/prettier */
import companyUseHook from "../../../useHook"
import { companyService, sharedService } from "@src/_services"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const useHook = (userType, setEditable, companyLogo) => {
  const { user } = useSelector(state => state.authentication)
  const [inputValue, setValue] = useState(
    userType === "owner"
      ? {}
      : {
          ...user.company,
          business_type: "individual",
          business_structure: "multi_member_llc"
        }
  )

  const [errorValue, setErrorValue] = useState({})
  const [mccList, setMccList] = useState([])
  const { profileUpdate } = companyUseHook()
  const dispatch = useDispatch()

  useEffect(() => {
    if (userType === "owner") {
      getOwnerDetails()
    } else {
      sharedService.getMccList().then(res => {
        setValue({ ...inputValue, business_mcc: res.data[0].value })
        setMccList(
          res.data.map(item => {
            return { ...item, label: item.name }
          })
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const handleResponse = data => {
    isLoading(false)
    setEditable(true)
    let message = ""
    if (data.status >= 400) {
      for (const [key, value] of Object.entries(data)) {
        message = message + `${key} : ${JSON.stringify(value)} `
      }
    } else if (data.response) {
      for (const [key, value] of Object.entries(data.response.data)) {
        message = message + `${key} : ${JSON.stringify(value)} `
      }
    }
    message && errorSwal("oops", message)
  }

  const getOwnerDetails = () => {
    isLoading(true)
    companyService
      .getCompanyOwnerInfo()
      .then(res => {
        isLoading(false)
        if (res.status >= 400) {
          handleResponse(res)
        } else {
          setValue(res.data)
        }
      })
      .catch(function (error) {
        handleResponse(error)
      })
  }

  const inputHandler = e => {
    setErrorValue({ ...errorValue, [e.target.name]: "" })
    setValue({ ...inputValue, [e.target.name]: e.target.value })
  }

  const onSubmitOwner = async event => {
    event.preventDefault()

    const requiredFields = {
      first_name: "first name required",
      last_name: "Last Name required",
      date_of_birth: "Invalid date of birth",
      gender: "Geneder required",
      country: "Invalid country",
      state: "Invalid state",
      city: "Invalid city",
      address_line1: "Invalid Address",
      ssn_last_4: "Please provide last four digits or you SSN",
      postal_code: "Invalid postal code",
      phone_number: "Invalid Phone Number"
    }

    let errors = {}
    let errorStatus = false
    for (const [key, value] of Object.entries(requiredFields)) {
      if (!!!inputValue[key]) {
        errors[key] = value
        errorStatus = true
      }
    }
    setErrorValue(errors)
    if (!errorStatus) {
      isLoading(true)
      companyService
        .putCompanyOwnerInfo(inputValue)
        .then(res => {
          handleResponse(res)
        })
        .catch(function (error) {
          handleResponse(error)
        })
    }
  }

  const onSubmitCompany = async event => {
    event.preventDefault()

    const requiredFields = {
      name: "name of company required",
      company_email: "Invalid Email",
      website_url: "required",
      business_type: "required",
      business_structure: "required",
      phone_number: "Invalid Phone Number",
      country: "Invalid country",
      state: "Invalid state",
      city: "Invalid city",
      address_line1: "Street Require",
      address_line2: "Address Required",
      postal_code: "Invalid postal code",
      business_mcc: "required",
      headquarter: "required",
      industry: "Invalid industory",
      about: "required",
      tax_id: "Field Required"
    }

    let errors = {}
    let errorStatus = false
    for (const [key, value] of Object.entries(requiredFields)) {
      if (!!!inputValue[key]) {
        errors[key] = value
        errorStatus = true
      }
    }
    setErrorValue(errors)
    if (!errorStatus) {
      isLoading(true)
      callSetupApi(inputValue)
    }
  }

  const callSetupApi = async data => {
    const profileInfo = data
    const userType = user?.user_type
    let formData = new FormData()

    if (typeof companyLogo !== "string") {
      profileInfo.company_logo = companyLogo
    } else {
      delete profileInfo.company_logo
    }
    if (user.stripe_account_created) {
      delete profileInfo.business_structure
    }

    for (const [key, value] of Object.entries(profileInfo)) {
      if (key === "company_logo") {
        formData.append(`${userType}.${key}`, value)
      } else if (key === "website_url") {
        const url = value.replace("https://", "")
        formData.append(`${userType}.${key}`, `https://${url}`)
      } else {
        formData.append(`${userType}.${key}`, value)
      }
    }
    await profileUpdate({ formData, userType })

    isLoading(false)
    setEditable(false)
  }

  return {
    mccList,
    inputValue,
    setValue,
    errorValue,
    setErrorValue,
    onSubmitOwner,
    inputHandler,
    onSubmitCompany
  }
}
export default useHook
