/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import {
  Col2,
  Heading,
  MainWrapper,
  Transaction,
  Image,
  ButtonsWrapper
} from "./style"
import useHook from "./useHook"
import { formatCurreny, getLocalTimeFromNow } from "@src/utils/helpers"
import React, { useEffect, useState } from "react"

const Transactions = () => {
  const [filteredTransactions, setFilteredTransactions] = useState([])
  const [isTransaction, setIsTransaction] = useState(true)

  const { getTransactionsList } = useHook()
  useEffect(() => {
    const apiTransactions = `/stripe/me/account/balance-transactions/`
    const apiPayouts = `/stripe/bank-payouts/`
    setFilteredTransactions([])
    getTransactions(isTransaction ? apiTransactions : apiPayouts, [], true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransaction])

  const getTransactions = async (api, data, load) => {
    const res = await getTransactionsList(api, load)
    if (res?.results) {
      const list = [...data, ...res.results]
      if (res.next) {
        getTransactions(res.next, list)
      } else {
        filteredTransactionsList(list)
      }
    }
  }

  const filteredTransactionsList = list => {
    if (list?.length) {
      let finalList = []
      for (const transaction of list) {
        isTransaction
          ? (finalList = [
              ...finalList,
              { ...transaction },
              { ...transaction, isFee: true }
            ])
          : (finalList = [...finalList, { ...transaction }])
      }
      setFilteredTransactions(finalList)
    }
  }

  return (
    <Col2>
      <Heading>Previous Transaction</Heading>
      <ButtonsWrapper>
        <li
          className={isTransaction ? "active" : ""}
          onClick={() => setIsTransaction(true)}
        >
          transaction
        </li>
        <li
          className={!isTransaction ? "active" : ""}
          onClick={() => setIsTransaction(false)}
        >
          payouts
        </li>
      </ButtonsWrapper>
      <MainWrapper>
        {filteredTransactions?.map((item, index) => {

          const transactionType =
            item?.type === "credit" || item?.isFee || !isTransaction ? "-" : "+"
          const title = isTransaction
            ? item.balance_transaction?.metadata?.athlete_user_email
            : item?.destination?.bank_name
          const amount = item?.isFee
            ? item.balance_transaction?.fee / 100
            : item.balance_transaction?.amount / 100

          const description = isTransaction
            ? item?.isFee
              ? item.balance_transaction.fee_details[0].description
              : item.balance_transaction.description
            : item.description

          const time = getLocalTimeFromNow(item.balance_transaction.created)

          return (
            <Transaction key={index}>
              <Image>{transactionType}</Image>
              <div className="info">
                <span className="title">{title}</span>
                <span className="sub-title">{description}</span>
              </div>
              <div className="amount">
                <span className="title">{formatCurreny(amount)} USD</span>
                <span className="sub-title">{time}</span>
              </div>
            </Transaction>
          )
        })}
      </MainWrapper>
    </Col2>
  )
}

export default Transactions
