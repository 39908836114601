/* eslint-disable prettier/prettier */
import styled from "styled-components"

export const Wrapper = styled.div`
  background: rgba(28, 32, 40, 1);
  border-radius: 15px;
`
export const CardHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  color: white;
  border-bottom: 1px solid grey;
  .wallet_head_heading {
    font-size: 20px;
    font-weight: 500;
    padding-left: 1rem;
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    padding: 0.5rem;
    .wallet_head_heading {
      font-size: 16px;
      padding-left: 0.5rem;
    }
  }
`
export const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  flex-direction: column;
  .wallet_card_blnc {
    color: #b1b1b1;
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
  }
  .wallet_amount {
    color: white;
    font-size: 28px;
    font-weight: 600;
  }

  .pending-amount {
    color: white;
    font-size: 22px;
    font-weight: 600;
  }

  .pending-currency {
    color: #b1b1b1;
    font-size: 10px;
    font-weight: 400;
  }

  @media screen and (min-width: 280px) and (max-width: 1150px) {
    .wallet_amount {
      font-size: 18px;
    }
    .pending-amount {
      font-size: 14px;
    }
  }
`
