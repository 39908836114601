/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import useHook from "../../useHook"
import {
  CardContainer,
  BankWrapper,
  BankLocationContainer,
  BankContentContainer
} from "./styles"
import "@containers/Layout/Dashboard/company.css"
import {
  MapSvg,
  LikeSvg,
  DislikeSvg,
  FacebookSvg,
  InstagramSvg,
  LinkedinSvg,
  TiktokSvg,
  TwitterSvg,
  youtubeSvg,
  WorldSvg
} from "@svgs"
import { socialDataParser } from "@utils/helpers"
import { getAge } from "@utils/helpers"
import React, { useEffect } from "react"

const CompanyBank = ({ props }) => {
  const {
    getAthletesList,
    atheleteList,
    atheleteNo,
    athletesLikeDislike,
    reset
  } = useHook()

  useEffect(() => {
    getAthletesList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (atheleteNo === atheleteList?.results?.length - 2) {
      getAthletesList()
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atheleteNo])

  const Social_Icon = {
    facebook: FacebookSvg,
    google: youtubeSvg,
    linkedin_oauth2: LinkedinSvg,
    twitter: TwitterSvg,
    tiktok: TiktokSvg,
    instagram: InstagramSvg
  }

  if (Object.keys(atheleteList).length === 0) return <div>No Date</div>
  if (atheleteList.count === 0) return <div>No Date</div>
  if (!atheleteList.results[atheleteNo]) return <div>No Date</div>

  const {
    id,
    user,
    gender,
    date_of_birth,
    state,
    country,
    school,
    bio,
    social_accounts
  } = atheleteList.results[atheleteNo]?.athlete
  const insta = social_accounts?.find(a => a?.provider === "instagram")
  const instaLink = insta && socialDataParser(insta)
  const { first_name, last_name, profile_picture } = user
  return (
    <BankWrapper>
      <CardContainer>
        <div
          className="bank_card_head"
          style={{ justifyContent: "space-between" }}
        >
          <div className="head_content_div">
            <span className="nike_head">{`${first_name} ${last_name}`}</span>
            <span className="sub_head">{`${gender}, ${getAge(
              date_of_birth
            )} years`}</span>
          </div>
          <BankLocationContainer>
            <MapSvg />
            <div className="map_div">
              <span className="title">Located</span>
              <span className="location_name">{`${state}, ${country}`}</span>
            </div>
          </BankLocationContainer>
        </div>
        <img src={profile_picture} className="main-image" alt="" />
        <BankContentContainer>
          <div className="bank_para_div">
            <div
              className="headquart_div"
              style={{ color: "rgba(177, 177, 177, 1)" }}
            >
              school
            </div>
            <div className="location_name">{school}</div>
          </div>
          <p className="location_name" style={{ marginTop: "0.5rem" }}>
            {bio}
          </p>
          <div className="location_div">
            {social_accounts?.map((val, index) => {
              const Icon = Social_Icon[val?.provider] || WorldSvg
              const item = socialDataParser(val)
              if (val.provider !== "instagram") {
                return (
                  <span
                    onClick={
                      item.profileLink
                        ? () => window.open(item.profileLink)
                        : null
                    }
                    className="social_icon"
                    key={index}
                  >
                    <Icon />
                  </span>
                )
              } else return <></>
            })}
          </div>
          {instaLink && (
            <div
              className="insta_div"
              onClick={() => {
                window.open(instaLink.profileLink)
              }}
            >
              <InstagramSvg />
              <div className="insta_content">
                <span className="nike_head">{instaLink?.name}</span>
              </div>
            </div>
          )}

          <div
            className={
              props.pagename === "home" ? "like_dislike_btns_div" : "no_display"
            }
          >
            <button
              className="like_btn"
              onClick={() => athletesLikeDislike(id, false)}
            >
              <DislikeSvg style={{ marginRight: "10px" }} />
              Decline
            </button>

            <button
              className="like_btn"
              onClick={() => athletesLikeDislike(id, true)}
            >
              <LikeSvg style={{ marginRight: "10px" }} />
              Approve
            </button>
          </div>
        </BankContentContainer>
      </CardContainer>
    </BankWrapper>
  )
}

export default CompanyBank
