/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import {
  Wrapper,
  ButtonMain,
  CardsContainer,
  PaymentWrapper,
  WalletBtns
} from "../styles"
import { sharedService } from "@src/_services"
import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js"
import React from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const WithdrawCard = () => {
  const [payAmount, setPayAmount] = useState(false)
  const { balance } = useSelector(state => state.sharedReducer)

  const dispatch = useDispatch()
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState(null)

  React.useEffect(() => {
    paymentRequest?.on("paymentmethod", async e => {
      const res = await sharedService.fetchPaymentIntentClientSecret({
        amount: payAmount
      })
      const clientSecret = res.data.client_secret

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: e.paymentMethod.id
        },
        { handleActions: false }
      )
      if (error) {
        e.complete("fail")
      } else {
        console.log(
          "balance?.pending?.[0]?.amount",
          balance?.pending?.[0]?.amount
        )
        console.log("payAmount", payAmount)
        const userAmount = payAmount * 100
        const amount = balance?.pending?.[0]?.amount + userAmount

        console.log({ amount })

        const obj = balance
        obj.pending[0].amount = amount

        console.log({ obj })

        const bal = {
          ...obj
        }
        console.log({ bal })
        dispatch({
          type: "BALANCE",
          payload: bal
        })

        e.complete("success")
        if (paymentIntent.status === "requires_action") {
          stripe.confirmCardPayment(clientSecret)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequest])

  const handlePay = async () => {
    if (!stripe) {
      return
    } else {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Demo total",
          amount: payAmount * 100
        },
        requestPayerName: true,
        requestPayerEmail: true
      })

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr)
        }
      })
    }
  }

  return (
    <Wrapper>
      <CardsContainer>
        <PaymentWrapper>
          <div class="payment-amount">Payment Amount</div>
          <div className="payment-row ">
            $
            <input
              type="number"
              name="number"
              placeholder="0.00"
              min="0"
              onChange={e => {
                setPayAmount(e.target.value)
                setPaymentRequest(null)
              }}
            />
          </div>
        </PaymentWrapper>
      </CardsContainer>

      <WalletBtns>
        {!paymentRequest && (
          <ButtonMain style={{ marginBottom: "1rem" }} onClick={handlePay}>
            Deposit
          </ButtonMain>
        )}

        {paymentRequest && (
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        )}
      </WalletBtns>
    </Wrapper>
  )
}

export default WithdrawCard
