import { alertConstants } from "../_constants"

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SIDEBAR_SHOW:
      return {
        sidebar_status: true
      }
    case alertConstants.SIDEBAR_HIDE:
      return {
        sidebar_status: false
      }
    case alertConstants.LOADING:
      return {
        status: action.message
      }
    default:
      return state
  }
}
