import React from "react"
import { useSelector } from "react-redux"
import { Route, Redirect } from "react-router-dom"

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector(state => state.authentication)
  const token = localStorage.getItem("token")

  const authented = user.user_type && token

  return (
    <Route
      {...rest}
      render={props =>
        authented ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  )
}
