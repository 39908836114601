/* eslint-disable prettier/prettier */
import { max_devices } from "@utils/constants"
import styled from "styled-components"

export const Wrapper = styled.div`
  color: white;
  display: flex;
  align-items: center;

  .header_head {
    font-size: 28px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
  }

  .subtitle {
    font-size: 12px;
    color: #ffffff70;
  }

  @media ${max_devices.tablet} {
    width: 100%;
    align-items: center;
    .header_head {
      font-size: 22px;
      font-weight: 500;
    }
  }
`
