/* eslint-disable prettier/prettier */
import { alertActions } from "@src/_actions"
import { position_page, company_profile_page } from "@src/_actions/home.action"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

const useHook = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { athleteDetail } = useSelector(state => state.companyReducer)

  const user = useSelector(state => state.authentication)
  const { user_type } = user.user
  const isCompany = user_type === "company"

  const page_position_store = useSelector(
    state => state.homeReducer.page_position
  )
  const company_profile_store = useSelector(
    state => state.homeReducer.profile_position
  )
  const profile = useSelector(state => state.profile)
  const { sidebar_status } = useSelector(state => state.alert)

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const menuClick = () => {
    dispatch(alertActions.sidebar_show())
  }

  const updatePagePosition = item => {
    dispatch(position_page(item))
  }
  const updateCompanyProfile = item => {
    dispatch(company_profile_page(item))
  }

  return {
    isLoading,
    sidebar_status,
    profile,
    menuClick,
    history,
    page_position_store,
    updatePagePosition,
    company_profile_store,
    updateCompanyProfile,
    isCompany,
    athleteDetail
  }
}
export default useHook
