import { SaveButton } from "../style"
import useHook from "./useHook"
import UserInput from "@src/sharedComponents/userInput"
import { BUSSINESS_TYPES, BUSINESS_STRUCTURE_CHOICES } from "@src/utils/data"
import React from "react"

const Company = () => {
  const { errorValue, inputHandler, onSubmitCompany, inputValue, mccList } =
    useHook("company")
  console.log({ inputValue })

  const FIELDS_LIST = [
    {
      label: "Business Type",
      name: "business_type",
      id: "business_type",
      list: BUSSINESS_TYPES,
      value: inputValue.business_type
    },
    {
      label: "Business Structure",
      name: "business_structure",
      id: "business_structure",
      list: BUSINESS_STRUCTURE_CHOICES,
      value: inputValue.business_type
    },
    {
      type: "input",
      label: "Phone Number",
      name: "phone_number",
      id: "phone_number",
      value: inputValue.phone_number
    },
    {
      label: "Merchant Category Code",
      name: "business_mcc",
      id: "business_mcc",
      list: mccList,
      value: inputValue.business_mcc
    },
    {
      type: "input",
      label: "Company Tax ID",
      id: "tax_id",
      name: "tax_id",
      value: inputValue.tax_id
    },
    {
      type: "input",
      label: "Company Privacy Policy Link",
      id: "website_url",
      name: "website_url",
      value: inputValue.website_url
    },
    {
      type: "input",
      label: "Postal Code",
      id: "postal_code",
      name: "postal_code",
      value: inputValue.postal_code
    },
    {
      type: "input",
      label: "City",
      id: "city",
      name: "city",
      value: inputValue.city
    },
    {
      type: "input",
      label: "Street",
      id: "address_line1",
      name: "address_line1",
      value: inputValue.address_line1
    },
    {
      type: "input",
      label: "Address",
      id: "address_line2",
      name: "address_line2",
      value: inputValue.address_line2
    },
    {
      type: "input",
      label: "Postal code",
      id: "postal_code",
      name: "postal_code",
      value: inputValue.postal_code
    }
  ]
  return (
    <>
      {FIELDS_LIST.map((item, i) => {
        return item.type === "input" ? (
          <div key={i}>
            <UserInput {...item} onChange={inputHandler} />
            <span style={{ color: "red", fontSize: "12px" }}>
              {errorValue[item.name]}
            </span>
          </div>
        ) : (
          <div key={i} className="form-floating">
            <select
              className="form-select"
              style={{ marginTop: "20px" }}
              onChange={inputHandler}
              {...item}
            >
              {item.list.map(({ value, label }, i) => (
                <option key={i} value={value || undefined}>
                  {label}
                </option>
              ))}
            </select>
            <label htmlFor={item.id}>{item.label}</label>
          </div>
        )
      })}
      <SaveButton onClick={onSubmitCompany}>Save</SaveButton>
    </>
  )
}
export default Company
