import AtheletePaymentPage from "./athelete"
import CompanyPaymentPage from "./company"
import { MainTab } from "./style"
import React from "react"
import { useSelector } from "react-redux"

const PaymentPage = () => {
  const { user } = useSelector(state => state.authentication)

  return (
    <MainTab className="Tabs">
      <div className="TabsDetail">
        {user.user_type === "athlete" ? (
          <AtheletePaymentPage />
        ) : (
          <CompanyPaymentPage />
        )}
      </div>
    </MainTab>
  )
}

export default PaymentPage
