/* eslint-disable prettier/prettier */
import { sharedService } from "@src/_services"
import { useDispatch, useSelector } from "react-redux"

const useHook = () => {
  const dispatch = useDispatch()

  const { balance } = useSelector(state => state.sharedReducer)
  //   const { companyList } = useSelector(state => state.athleteReducer)

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const getBalance = async () => {
    isLoading(true)
    await sharedService
      .getBalance()
      .then(async res => {
        isLoading(false)
        dispatch(success(res.data))
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          // errorSwal("oops", message)
        }
      })

    function success(data) {
      return { type: "BALANCE", payload: data }
    }
  }

  return {
    getBalance,
    balance
  }
}
export default useHook
