import { SaveButton } from "../style"
import useHook from "./useHook"
import UserInput from "@src/sharedComponents/userInput"
import { CAN_STATES, COUNTRIES, US_STATES } from "@src/utils/data"
import React from "react"

const AtheletePaymentPage = () => {
  const { errorValue, inputHandler, onSubmitButton, inputValue } = useHook()

  const FIELDS_LIST = [
    {
      label: "Country",
      name: "country",
      id: "country",
      list: COUNTRIES,
      value: inputValue.country
    },
    {
      label: "State",
      name: "state",
      id: "state",
      list: inputValue.country === "US" ? US_STATES : CAN_STATES,
      value: inputValue.state
    },
    {
      id: "city",
      name: "city",
      type: "text",
      label: "City",
      value: inputValue.city || ""
    },
    {
      id: "address_line1",
      name: "address_line1",
      type: "text",
      label: "Street",
      value: inputValue.address_line1 || ""
    },
    {
      id: "postal_code",
      name: "postal_code",
      type: "text",
      label: "Postal Code",
      value: inputValue.postal_code || ""
    },

    {
      id: "ssn_last_4",
      name: "ssn_last_4",
      type: "text",
      label: "Tax Information (SSN, Last four digits)",
      value: inputValue.ssn_last_4 || ""
    },
    {
      id: "phone_number",
      name: "phone_number",
      type: "text",
      label: "Phone Number",
      value: inputValue.phone_number || ""
    },
    {
      id: "business_url",
      name: "business_url",
      type: "text",
      label: "Profile URL Link",
      value: inputValue.business_url || ""
    }
  ]

  return (
    <>
      {FIELDS_LIST.map((item, i) => {
        return !item.list ? (
          <div key={i}>
            <UserInput {...item} onChange={inputHandler} />
            <span style={{ color: "red", fontSize: "12px" }}>
              {errorValue[item.name]}
            </span>
          </div>
        ) : (
          <div key={i} className="form-floating">
            <select
              className="form-select"
              style={{ marginTop: "20px" }}
              onChange={inputHandler}
              {...item}
            >
              {item.list.map(({ value, label }, i) => (
                <option key={i} value={value || undefined}>
                  {label}
                </option>
              ))}
            </select>
            <label htmlFor={item.id}>{item.label}</label>
          </div>
        )
      })}
      <SaveButton onClick={onSubmitButton}>Save</SaveButton>
    </>
  )
}
export default AtheletePaymentPage
