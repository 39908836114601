/* eslint-disable prettier/prettier */
const initialstate = {
  campaingsList: {},
  campaignNo: 0,
  atheleteList: {},
  atheleteNo: 0,
  matchesList: {},
  likesList: {},
  athleteDetail: {},
  plansList: [],
  activePlan: {}
}
export const companyReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "COMPANY_CAMPAIGN":
      return {
        ...state,
        campaingsList: action.payload
      }

    case "COMPANY_CAMPAING_NO":
      return {
        ...state,
        campaignNo: action.payload
      }

    case "ATHELETE_LIST":
      return {
        ...state,
        atheleteList: action.payload
      }

    case "ATHELETE_NO":
      return {
        ...state,
        atheleteNo: action.payload
      }

    case "MATCHES_LIST":
      return {
        ...state,
        matchesList: action.payload
      }
    case "COMPANY_CAMPAIGNS_LIKES_LIST":
      return {
        ...state,
        likesList: action.payload
      }

    case "ATHLETE_DETAILS":
      return {
        ...state,
        athleteDetail: action.payload
      }
    case "PLANS_LIST":
      return {
        ...state,
        plansList: action.payload
      }
    case "ACTIVE_PLAN":
      return {
        ...state,
        activePlan: action.payload
      }

    default:
      return state
  }
}
