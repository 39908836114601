/* eslint-disable prettier/prettier */
import { sharedService } from "@src/_services"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useDispatch, useSelector } from "react-redux"

const useHook = () => {
  const dispatch = useDispatch()

  const { user } = useSelector(state => state.authentication)
  const { balance } = useSelector(state => state.sharedReducer)

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const getBalance = async () => {
    isLoading(true)
    await sharedService
      .getBalance()
      .then(async res => {
        isLoading(false)
        dispatch({ type: "BALANCE", payload: res.data })
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })
  }

  return {
    getBalance,
    user,
    balance
  }
}
export default useHook
