import { SaveButton } from "./style"
import useHook from "./useHook"
import InputFields from "@src/sharedComponents/InputFields"
import { COUNTRIES, US_STATES, CAN_STATES, GENDER } from "@src/utils/data"
import React from "react"

const Owner = ({ editable, setEditable }) => {
  const {
    errorValue,
    setErrorValue,
    setValue,
    inputHandler,
    onSubmitOwner,
    inputValue
  } = useHook("owner", setEditable)

  const FIELDS_LIST = [
    {
      id: "first_name",
      name: "first_name",
      label: "First Name *"
    },
    {
      id: "last_name",
      name: "last_name",
      label: "Last Name *"
    },
    {
      id: "gender",
      name: "gender",
      label: "Gender *",
      list: GENDER,
      type: "dropdown"
    },
    {
      id: "date_of_birth",
      name: "date_of_birth",
      label: "Date of Birth *",
      type: "date"
    },
    {
      id: "job_title",
      name: "job_title",
      label: "Job Title *"
    },
    {
      id: "phone_number",
      name: "phone_number",
      label: "Phone Number *"
    },
    {
      id: "ssn_last_4",
      name: "ssn_last_4",
      label: "Tax Information (SSN, Last four digits) *"
    },
    {
      label: "Country *",
      name: "country",
      id: "country",
      type: "dropdown",
      list: COUNTRIES
    },
    {
      label: "State *",
      name: "state",
      id: "state",
      type: "dropdown",
      list: inputValue?.country === "US" ? US_STATES : CAN_STATES
    },
    {
      id: "city",
      name: "city",
      label: "City *"
    },

    {
      id: "address_line1",
      name: "address_line1",
      label: "Street *"
    },
    {
      id: "address_line2",
      name: "address_line2",
      label: "Address *"
    },
    {
      id: "postal_code",
      name: "postal_code",
      label: "Postal Code *",
      type: "number"
    }
  ]
  return (
    <>
      <InputFields
        FIELDS_LIST={FIELDS_LIST}
        inputHandler={inputHandler}
        setErrorList={setErrorValue}
        errorList={errorValue}
        setUserInput={setValue}
        userInput={inputValue}
        editable={!editable}
      />
      {!editable && <SaveButton onClick={onSubmitOwner}>Save</SaveButton>}
    </>
  )
}
export default Owner
