/* eslint-disable prettier/prettier */
import { sharedService } from "@src/_services"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useDispatch, useSelector } from "react-redux"

const useHook = () => {
  const dispatch = useDispatch()

  const { balance } = useSelector(state => state.sharedReducer)
  //   const { companyList } = useSelector(state => state.athleteReducer)

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const getPlaidLinkToken = async () => {
    isLoading(true)
    return await sharedService
      .getPlaidLinkToken()
      .then(async res => {
        isLoading(false)
        return res
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }

        return false
      })
  }

  return {
    getPlaidLinkToken,
    balance
  }
}
export default useHook
