import Company from "./company"
import Owner from "./owner"
import React, { useState } from "react"

const CompanyPaymentPage = () => {
  const [activeTab, setActiveTab] = useState(1)

  return (
    <>
      <ul className="nav">
        <li
          className={activeTab ? "active" : ""}
          onClick={() => setActiveTab(1)}
        >
          Owner Details
        </li>
        <li
          className={!activeTab ? "active" : ""}
          onClick={() => setActiveTab(0)}
        >
          Company Details
        </li>
      </ul>
      <div className="outlet">{activeTab ? <Owner /> : <Company />}</div>
    </>
  )
}

export default CompanyPaymentPage
