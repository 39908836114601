export const GENDER = [
  {
    label: "Male",
    value: "male"
  },
  {
    label: "Female",
    value: "female"
  }
]

export const BUSSINESS_TYPES = [
  {
    label: "individual",
    value: "individual"
  },
  {
    label: "company",
    value: "company"
  }
]

export const COUNTRIES = [
  {
    label: "USA",
    value: "US"
  },
  {
    label: "Canada",
    value: "CA"
  }
]

export const US_STATES = [
  {
    label: "Alabama",
    value: "AL"
  },
  {
    label: "Alaska",
    value: "AK"
  },
  {
    label: "American Samoa",
    value: "AS"
  },
  {
    label: "Arizona",
    value: "AZ"
  },
  {
    label: "Arkansas",
    value: "AR"
  },
  {
    label: "California",
    value: "CA"
  },
  {
    label: "Colorado",
    value: "CO"
  },
  {
    label: "Connecticut",
    value: "CT"
  },
  {
    label: "Delaware",
    value: "DE"
  },
  {
    label: "District Of Columbia",
    value: "DC"
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM"
  },
  {
    label: "Florida",
    value: "FL"
  },
  {
    label: "Georgia",
    value: "GA"
  },
  {
    label: "Guam",
    value: "GU"
  },
  {
    label: "Hawaii",
    value: "HI"
  },
  {
    label: "Idaho",
    value: "ID"
  },
  {
    label: "Illinois",
    value: "IL"
  },
  {
    label: "Indiana",
    value: "IN"
  },
  {
    label: "Iowa",
    value: "IA"
  },
  {
    label: "Kansas",
    value: "KS"
  },
  {
    label: "Kentucky",
    value: "KY"
  },
  {
    label: "Louisiana",
    value: "LA"
  },
  {
    label: "Maine",
    value: "ME"
  },
  {
    label: "Marshall Islands",
    value: "MH"
  },
  {
    label: "Maryland",
    value: "MD"
  },
  {
    label: "Massachusetts",
    value: "MA"
  },
  {
    label: "Michigan",
    value: "MI"
  },
  {
    label: "Minnesota",
    value: "MN"
  },
  {
    label: "Mississippi",
    value: "MS"
  },
  {
    label: "Missouri",
    value: "MO"
  },
  {
    label: "Montana",
    value: "MT"
  },
  {
    label: "Nebraska",
    value: "NE"
  },
  {
    label: "Nevada",
    value: "NV"
  },
  {
    label: "New Hampshire",
    value: "NH"
  },
  {
    label: "New Jersey",
    value: "NJ"
  },
  {
    label: "New Mexico",
    value: "NM"
  },
  {
    label: "New York",
    value: "NY"
  },
  {
    label: "North Carolina",
    value: "NC"
  },
  {
    label: "North Dakota",
    value: "ND"
  },
  {
    label: "Northern Mariana Islands",
    value: "MP"
  },
  {
    label: "Ohio",
    value: "OH"
  },
  {
    label: "Oklahoma",
    value: "OK"
  },
  {
    label: "Oregon",
    value: "OR"
  },
  {
    label: "Palau",
    value: "PW"
  },
  {
    label: "Pennsylvania",
    value: "PA"
  },
  {
    label: "Puerto Rico",
    value: "PR"
  },
  {
    label: "Rhode Island",
    value: "RI"
  },
  {
    label: "South Carolina",
    value: "SC"
  },
  {
    label: "South Dakota",
    value: "SD"
  },
  {
    label: "Tennessee",
    value: "TN"
  },
  {
    label: "Texas",
    value: "TX"
  },
  {
    label: "Utah",
    value: "UT"
  },
  {
    label: "Vermont",
    value: "VT"
  },
  {
    label: "Virgin Islands",
    value: "VI"
  },
  {
    label: "Virginia",
    value: "VA"
  },
  {
    label: "Washington",
    value: "WA"
  },
  {
    label: "West Virginia",
    value: "WV"
  },
  {
    label: "Wisconsin",
    value: "WI"
  },
  {
    label: "Wyoming",
    value: "WY"
  }
]

export const CAN_STATES = [
  {
    label: "Alberta",
    value: "AB"
  },
  {
    label: "British Columbia",
    value: "BC"
  },
  {
    label: "Manitoba",
    value: "MB"
  },
  {
    label: "New Brunswick",
    value: "NB"
  },
  {
    label: "Newfoundland and Labrador",
    value: "NL"
  },
  {
    label: "Northwest Territories",
    value: "NT"
  },
  {
    label: "Nova Scotia",
    value: "NS"
  },
  {
    label: "Nunavut",
    value: "NU"
  },
  {
    label: "Ontario",
    value: "ON"
  },
  {
    label: "Prince Edward Island",
    value: "PE"
  },
  {
    label: "Quebec",
    value: "QC"
  },
  {
    label: "Saskatchewan",
    value: "SK"
  },
  {
    label: "Yukon Territory",
    value: "YT"
  }
]

export const INDUSTRIES = [
  {
    label: "Food",
    value: "Food"
  },
  {
    label: "Bev",
    value: "Bev"
  },
  {
    label: "21+",
    value: "21+"
  },
  {
    label: "Art/Education",
    value: "Art/Education"
  },
  {
    label: "Finance",
    value: "Finance"
  },
  {
    label: "Non-profit",
    value: "Non-profit"
  },
  {
    label: "Tech/Online",
    value: "Tech/Online"
  },
  {
    label: "Health & Wellness",
    value: "Health & Wellness"
  },
  {
    label: "Fashion",
    value: "Fashion"
  },
  {
    label: "Sports & Recreation",
    value: "Sports & Recreation"
  },
  {
    label: "Other Services",
    value: "Other Services"
  }
]

export const BUSINESS_STRUCTURE_CHOICES = [
  { value: "multi_member_llc", label: "Multi Member LLC" },
  { value: "private_corporation", label: "Private Corporation" },
  { value: "private_partnership", label: "Private Partnership" },
  { value: "public_corporation", label: "Public Corporation" },
  { value: "public_partnership", label: "Public Partnership" },
  { value: "single_member_llc", label: "Single Member LLC" },
  { value: "sole_proprietorship", label: "Sole Proprietorship" },
  { value: "unincorporated_association", label: "Unincorporated Association" },
  { value: "private_company", label: "Private Company" },
  { value: "public_company", label: "Public Company" },
  {
    value: "limited_liability_partnership",
    label: "Limited Liability Partnership"
  },
  { value: "free_zone_llc", label: "Free Zone LLC" },
  { value: "sole_establishment", label: "Sole Establishment" },
  { value: "free_zone_establishment", label: "Free zone Establishment" },
  { value: "llc", label: "LLC" }
]
