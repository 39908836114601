/* eslint-disable prettier/prettier */
import styled from "styled-components/macro"

export const WalletMainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 90vh;
  width: 100%;
  overflow: auto;
  @media screen and (min-width: 280px) and (max-width: 600px) {
    flex-direction: column;
    overflow: auto;
    height: 90vh;
  }
`
export const WalletCol1 = styled.div`
  height: 100%;
  width: 50%;
  padding: 1.5rem;
  overflow: auto;
  @media screen and (min-width: 280px) and (max-width: 600px) {
    padding: 1rem 0.5rem;
    width: 100%;
    height: fit-content;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`
