/* eslint-disable prettier/prettier */
import { sharedService } from "@src/_services"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const useHook = () => {
  const user = useSelector(state => state.authentication)
  const { notificationsCount } = useSelector(state => state.sharedReducer)
  const { user_type } = user.user
  const isCompany = user_type === "company"

  const dispatch = useDispatch()
  const [notificationsList, setNotificationsList] = useState()
  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }
  const updateNotificationCount = count => {
    dispatch({ type: "NOTIFICATION", payload: count })
  }

  const getNotifications = async () => {
    try {
      isLoading(true)
      const res = await sharedService.getNotifications()
      isLoading(false)

      if (res.status >= 400) {
        let message = ""
        for (const [key, value] of Object.entries(res)) {
          message = message + `${key} : ${JSON.stringify(value)} `
        }
        errorSwal("oops", message)
      } else {
        setNotificationsList(res.data?.results)
        const unreadList = res.data?.results?.filter(item => !item.is_read)
        updateNotificationCount(unreadList.length)
      }
    } catch (error) {
      isLoading(false)
    }
  }

  const deleteNotifications = async id => {
    try {
      isLoading(true)
      const res = await sharedService.deleteNotifications(id)
      isLoading(false)

      if (res.status >= 400) {
        let message = ""
        for (const [key, value] of Object.entries(res)) {
          message = message + `${key} : ${JSON.stringify(value)} `
        }
        errorSwal("oops", message)
      } else {
        const list = notificationsList.filter(item => item.id !== id)
        setNotificationsList(list)
      }
    } catch (error) {
      isLoading(false)
    }
  }

  const readNotifications = async (id, index) => {
    try {
      isLoading(true)
      const res = await sharedService.readNotifications(id, { is_read: true })
      isLoading(false)
      if (res.status >= 400) {
        let message = ""
        for (const [key, value] of Object.entries(res)) {
          message = message + `${key} : ${JSON.stringify(value)} `
        }
        errorSwal("oops", message)
      } else {
        const obj = notificationsList
        obj[index].is_read = true
        updateNotificationCount(notificationsCount - 1)
        setNotificationsList([...obj])
      }
    } catch (error) {
      isLoading(false)
    }
  }

  const readAllNotifications = async () => {
    try {
      isLoading(true)
      notificationsList.forEach((notification, index) => {
        if (!notification.is_read) {
          readNotifications(notification?.id, index)
        }
      })
    } catch (error) {
      isLoading(false)
    }
  }
  return {
    getNotifications,
    notificationsList,
    isCompany,
    deleteNotifications,
    readNotifications,
    readAllNotifications
  }
}
export default useHook
