/* eslint-disable prettier/prettier */
export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  SETUP_COMPLETE: "USER_SETUP_COMPLETE",
  SET_IG_CONNECT: "SET_IG_CONNECT",
  HEADER: "USER_HEADER",
  LOGOUT: "USERS_LOGOUT"
}
