import { swalConstants } from "../../_constants"
import swal from "sweetalert"

export const succesSwal = text => {
  swal({
    title: swalConstants.SUCCESS_MESSAGE,
    text: text,
    icon: "success",
    button: "Close",
    className: "swal-custom",
    timer: 5000
  })
}

export const errorSwal = (title, text) => {
  swal({
    title: title,
    text: text,
    icon: "error",
    button: "Close",
    timer: 5000
  })
}
