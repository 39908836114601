import profile1 from "../../assets/images/icons/profil1.png"
import profile2 from "../../assets/images/icons/profil2.png"
import logo_pic from "../../assets/svgs/logo_pic.svg"
import { errorSwal } from "../../sharedComponents/swal/Swal"
import "./style.css"
import React, { Component } from "react"

class FileUpload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      file: this.props.fileName,
      token: localStorage.getItem("token"),
      isAthlete: this.props.isAthlete
    }
  }

  /**
   * This function call when any attachment selected
   * @param event   this parameter get file event object
   */

  selectFile = event => {
    const acceptedImageTypes = ["image/jpeg", "image/png", "image/jpg"]
    if (event.target.files) {
      if (acceptedImageTypes.includes(event.target.files[0].type)) {
        if (event.target.files[0].size <= 26214400) {
          const fileLoaded = URL.createObjectURL(event.target.files[0])
          this.setState({ file: fileLoaded })
          this.props.onselectImage(event.target.files[0])
        } else {
          errorSwal("error", "File size must be less than or equal to 25MB")
        }
      } else {
        errorSwal("error", "Only .PNG, .JPEG, .JPG")
      }
    }
  }

  render() {
    const { token } = this.state
    const file = this.state.file || this.props.fileName
    return (
      <div>
        {!file && (
          <div className={`image-upload-wrap ${token ? "h-272" : ""}`}>
            <input
              className="file-upload-input"
              type="file"
              id="fileControl"
              onChange={this.selectFile}
              accept="image/*"
            />
            {this.state.isAthlete ? (
              <label htmlFor="fileControl" className="drag-text">
                <img src={profile2} alt=" " />
                <img className="mb-2 img-margin" src={profile1} alt=" " />
                Upload picture
              </label>
            ) : (
              <label htmlFor="fileControl" className="drag-text">
                <img src={logo_pic} className="img-margin" alt=" " />
                Upload Logo
              </label>
            )}
          </div>
        )}

        {file && (
          <div className="file-upload-content">
            <img
              className={`file-upload-image ${token ? "h-272" : ""}`}
              src={file}
              alt=""
            ></img>
            <input
              className="file-upload-input"
              type="file"
              id="fileControl"
              onChange={this.selectFile}
              accept="image/*"
            />
            {/* <label htmlFor="fileControl" className="drag-text"> */}
            <label htmlFor="fileControl" className="change-text">
              {this.state.isAthlete ? "Change picture" : "Change Logo"}
            </label>
          </div>
        )}
        {this.props.error && <span className="error">{this.props.error}</span>}
      </div>
    )
  }
}

export default FileUpload
