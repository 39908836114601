import "./style.css"
import React from "react"

const UserInput = ({ ...props }) => {
  // const [isFocused, setIsFocused] = useState(false);

  return (
    <span>
      <div className="form-floating">
        <input
          name={props.name}
          className={
            "form-control " +
            (props.error ? "is-invalid" : "") +
            (props.readOnly ? "deselect_input" : "")
          }
          id={props.id}
          placeholder=" "
          // onFocus={() => setIsFocused(true)}
          // onBlur={() => setIsFocused(false)}
          {...props}
          readOnly={props.readOnly}
        />
        <label htmlFor={props.id}>{props.label}</label>
        {props.icon}
      </div>
      {props.error && <span className="error">{props.error}</span>}
    </span>
  )
}

export default UserInput
