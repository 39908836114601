/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import useHook from "../useHook"
import ReportModal from "@components/report"
import "@containers/Layout/Dashboard/Home.css"
import {
  MapSvg,
  LikeSvg,
  DislikeSvg,
  FacebookSvg,
  InstagramSvg,
  LinkedinSvg,
  TiktokSvg,
  TwitterSvg,
  youtubeSvg,
  WorldSvg
} from "@svgs"
import report from "@svgs/report.svg"
import { socialDataParser } from "@utils/helpers"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const Bank = ({ props }) => {
  const { getCompaniesList, companyLikeDislike, reportCompany, reset } =
    useHook()
  const { companyList, companyNo } = useSelector(state => state.athleteReducer)

  const [showReportModal, setShowReportModal] = useState(false)
  const [reportInfo, setReportInfo] = useState({})

  const submitReport = () => {
    reportCompany(reportInfo, res => {
      if (res) {
        setShowReportModal(false)
        setReportInfo({})
      }
    })
  }

  useEffect(() => {
    getCompaniesList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (companyNo === companyList?.results?.length - 3) {
      getCompaniesList()
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyNo])

  const Social_Icon = {
    facebook: FacebookSvg,
    google: youtubeSvg,
    linkedin_oauth2: LinkedinSvg,
    twitter: TwitterSvg,
    tiktok: TiktokSvg,
    instagram: InstagramSvg
  }

  if (!companyList.results || !companyList.results[companyNo])
    return <div>No data</div>

  const {
    id,
    industry,
    company_logo,
    name,
    headquarter,
    about,
    state,
    social_accounts
  } = companyList.results[companyNo]
  const insta = social_accounts?.find(a => a.provider === "instagram")
  const instaLink = insta && socialDataParser(insta)
  return (
    <div className={"bank_card_div"}>
      <div className={"bank_card_head"}>
        <div className="nike_logo_div">
          {company_logo && (
            <img src={company_logo} />
          )}
        </div>
        <div className="head_content_div">
          <span className="nike_head">{name}</span>
          <span className="sub_head">{industry}</span>
        </div>
      </div>
      <div className="back_card_body">
        <p className="bank_para_div">{about}</p>
        <div className="location_div">
          <MapSvg />
          <div className="map_div">
            <span className="headquart_div">{headquarter}</span>
            <span className="location_name">{state}</span>
          </div>
        </div>
        <div
          className="location_div"
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <div className="location_div">
            {social_accounts?.map((val, index) => {
              const Icon = Social_Icon[val.provider] || WorldSvg
              const item = socialDataParser(val)
              return (
                <span
                  onClick={
                    item.profileLink
                      ? () => window.open(item.profileLink)
                      : null
                  }
                  className="social_icon"
                  key={index}
                >
                  <Icon />
                </span>
              )
            })}
          </div>
          <div
            className="location_div"
            style={{ marginTop: "15px" }}
            onClick={() => {
              setReportInfo({ ...reportInfo, company: id })
              setShowReportModal(true)
            }}
          >
            <img
              src={report}
              style={{ width: "17px", height: "17px", marginRight: "10px" }}
            />
            <span className="location_name">Report</span>
          </div>
        </div>
        {insta && (
          <div
            className="insta_div"
            onClick={() => {
              window.open(instaLink.profileLink)
            }}
          >
            <InstagramSvg />
            <div className="insta_content">
              <span className="nike_head">{name}</span>
            </div>
          </div>
        )}
        <div className={"like_dislike_btns_div"}>
          <button
            className="like_btn"
            onClick={() => companyLikeDislike(id, false)}
          >
            <DislikeSvg style={{ marginRight: "10px" }} />
            Dislike
          </button>
          <button
            className="like_btn"
            onClick={() => companyLikeDislike(id, true)}
          >
            <LikeSvg style={{ marginRight: "10px" }} />
            Like
          </button>
        </div>
      </div>

      <ReportModal
        show={showReportModal}
        handleClose={() => setShowReportModal(false)}
        submitReport={submitReport}
        onChange={e =>
          setReportInfo({ ...reportInfo, description: e.target.value })
        }
      />
    </div>
  )
}

export default Bank
