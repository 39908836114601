/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import { CardBody, CardHead, Wrapper } from "./style"
import useHook from "./useHook"
import { formatCurreny } from "@src/utils/helpers"
import React, { useEffect } from "react"

const WalletCard = ({ setTransactionsList }) => {
  const { user, balance, getBalance } = useHook()

  useEffect(() => {
    getBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { company } = user

  const availableBalance = balance?.available?.[0]?.amount
  const pendingBalance = balance?.pending?.[0]?.amount
  return (
    <Wrapper onClick={setTransactionsList}>
      <CardHead>
        <img
          src={company?.company_logo}
          style={{ width: "50px", height: "50px", borderRadius: "100%" }}
        />
        <div className="wallet_head_heading">Hello, {company.name}</div>
      </CardHead>
      <CardBody>
        <span className="wallet_card_blnc">Available Balance</span>
        <div>
          <span className="wallet_amount">
            {formatCurreny(availableBalance / 100 || 0)}
          </span>
          <span className="wallet_card_blnc">USD</span>
        </div>
        <span className="wallet_card_blnc">Pending Balance</span>
        <div>
          <span className="wallet_amount">
            {formatCurreny(pendingBalance / 100 || 0)}
          </span>
          <span className="wallet_card_blnc">USD</span>
        </div>
      </CardBody>
    </Wrapper>
  )
}

export default WalletCard
