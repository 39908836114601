/* eslint-disable prettier/prettier */
import useAthlete from "../../athelete/useHook"
import Title from "../title"
import useHook from "../useHook"
import { SearchContainer } from "./styles"
import "./styles.css"
import search from "@svgs/search.svg"
import search_format from "@svgs/search_format.svg"
import { US_STATES, INDUSTRIES } from "@utils/data"
import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"

const HeaderPage = () => {
  const { page_position_store, updatePagePosition } = useHook()
  const { setFiltersList } = useAthlete()
  const user = JSON.parse(localStorage.getItem("user"))
  console.log("user", user)

  const buttonsList = ["Bank", "Campaigns"]
  const [filter, setFilter] = useState({})
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [timer, setTimer] = useState(null)

  const onclickOption = () => {
    handleShow(true)
  }
  const onChange = async event => {
    const { name, value } = event.target
    if (name === "search") {
      clearTimeout(timer)

      const newTimer = setTimeout(() => {
        applyFilter({ ...filter, [name]: value })
      }, 500)

      setTimer(newTimer)
    } else {
      setFilter({ ...filter, [name]: value })
    }
  }

  const applyFilter = data => {
    data ? setFiltersList(data) : setFiltersList(filter)
  }

  React.useEffect(() => {
    updatePagePosition("Bank")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Title headerTitle="Home" />
      <div className="header_btns_div">
        {buttonsList.map((item, index) => {
          return (
            <button
              className={page_position_store === item ? "darkbtn" : "lightbtn"}
              key={index}
              onClick={() => {
                updatePagePosition(item)
              }}
            >
              {item}
            </button>
          )
        })}
      </div>
      <SearchContainer>
        <div>
          <img src={search} style={{ margin: "0px 5px" }} alt="" />
          <input
            className="search_input"
            name="search"
            placeholder="Search Company ..."
            onChange={onChange}
          />
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body style={{ paddingTop: "0px" }}>
            <div className="modal__body1">
              <div className="modal_head_heading">
                <div className="filtershead">Filters</div>
                <div className="resetfilters">Reset Filters</div>
              </div>
              <div className="selectdiv">
                <select
                  className="form-select"
                  name="state"
                  id="stateType"
                  onChange={onChange}
                  style={{ marginBottom: "1rem" }}
                >
                  <option value="default" disabled hidden>
                    State
                  </option>
                  {US_STATES.map(({ value }) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <select
                  className="form-select"
                  name="industry"
                  id="industryType"
                  onChange={onChange}
                  style={{ marginBottom: "1rem" }}
                >
                  <option value="default" disabled hidden>
                    Industry
                  </option>
                  {INDUSTRIES.map(({ value }) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <Button
                className="modal_btn_style"
                type="submit"
                onClick={() => {
                  applyFilter()
                  handleClose()
                }}
              >
                Apply Filter
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <div className="reportdiv" onClick={onclickOption}>
          <img src={search_format} style={{ marginRight: "5px" }} alt="" />
        </div>
      </SearchContainer>
    </>
  )
}

export default HeaderPage
