/* eslint-disable prettier/prettier */
import { userActions } from "../../../_actions"
import { emailValidator } from "../../../_helpers/validators/Validators"
import { HiddenSvg } from "../../../assets/svgs"
import UserInput from "../../../sharedComponents/userInput"
import Footer from "../../Layout/Footer"
import LeftSideBar from "../../Layout/guestSideBar"
import "./style.css"
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

class LoginPage extends React.Component {
  constructor(props) {
    super(props)

    // reset login status
    // this.props.logout();

    this.state = {
      user: {
        username: "",
        password: "",
        "active_location.latitude": "",
        "active_location.longitude": "",
        isRememberMe: false
      },
      show_hide: false
    }

    if (localStorage.getItem("token")) props.history.push("/home")
  }
  componentDidMount() {
    const { user } = this.state

    if ("geolocation" in navigator) {
      navigator.geolocation.watchPosition(
        function (position) {
          user["active_location.latitude"] = position.coords?.latitude
          user["active_location.longitude"] = position.coords?.longitude
        },
        function (error) {
          if (error.code === error.PERMISSION_DENIED)
            console.log("Location Denied :-(")
        //   errorSwal(
        //     "oops",
        //     "Please enable the location service for this website"
        //   )
        }
      )
    } else {
      console.log("Not Available")
    }
  }
  changeShowHide = () => {
    const hideShow = this.state.show_hide
    if (hideShow) {
      this.setState({ show_hide: false })
    } else {
      this.setState({ show_hide: true })
    }
  }
  handleChange = e => {
    const { name, value } = e.target
    const { user } = this.state
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    })
  }

  handleRememberMeChange = async event => {
    let isRememberMe = event.target.checked
    await this.setState({ isRememberMe })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { user } = this.state
    let emailvalidator = await emailValidator(user.username)
    if (emailvalidator) this.setState({ emailError: emailvalidator })
    else {
      delete user.isRememberMe
      await this.props.login(user)
      this.props.authentication.setupComplete
        ? this.props.history.push("/home")
        : this.props.history.push("/set-up-account")
    }
  }

  render() {
    const { user, show_hide } = this.state
    return (
      <div className="mainDiv">
        <LeftSideBar />
        <div className="sign-in">
          <h1>Sign in</h1>
          <form onSubmit={this.handleSubmit}>
            <UserInput
              label={"Email"}
              id="emailInput"
              name="username"
              error={this.state.emailError}
              value={user.username}
              onChange={e => {
                this.handleChange(e)
                this.setState({ emailError: "" })
              }}
            />

            <UserInput
              label={"Password"}
              id="passInput"
              name="password"
              maxLength="30"
              type={show_hide ? "text" : "password"}
              error={this.state.passwordError}
              value={user.password}
              onChange={e => {
                this.handleChange(e)
                this.setState({ passwordError: "" })
              }}
              icon={
                show_hide ? (
                  <i
                    className={"hide-show fa fa-eye"}
                    onClick={() => this.setState({ show_hide: !show_hide })}
                  />
                ) : (
                  <HiddenSvg
                    onClick={() => this.setState({ show_hide: !show_hide })}
                    className=" hide-show"
                  />
                )
              }
            />

            <div className="input-wrapper more-action">
              {/* <label>
                                    <input id="remember-me" type="checkbox" name="remember_me"/>Remember Me
                                </label> */}
              <span>
                <input
                  type="checkbox"
                  name="lsRememberMe"
                  id="rememberMe"
                  onChange={this.handleRememberMeChange}
                />
                <label className="ml-3 remember_label" htmlFor="rememberMe">
                  Remember me
                </label>
              </span>
              <Link className="form-link" from="/login" to="/rest-password">
                Forgot password?
              </Link>
            </div>
            <input type="submit" value="Login" />

            <div className="login_text">
              Don’t have an account?
              <Link from="/login" to="/signup">
                Sign up
              </Link>
            </div>
          </form>
          <Footer />
        </div>
      </div>
    )
  }
}

function mapState(state) {
  const { authentication } = state
  return { authentication }
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
}

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage)
export { connectedLoginPage as LoginPage }
