/* eslint-disable prettier/prettier */
import { max_devices } from "@utils/constants"
import styled from "styled-components"

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-color: #101217;
  height: 10vh;
  border-bottom: 2px solid black;
  padding: 22px 25px 22px 40px;
  flex-direction: row;
  position: static;

  @media ${max_devices.tablet} {
    flex-direction: column;
    padding: 15px;
    height: max-content;
  }
`
