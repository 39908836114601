import { userActions } from "../../../_actions"
import { emailValidator } from "../../../_helpers/validators/Validators"
import arrow_left from "../../../assets/images/icons/arrow left.png"
import UserInput from "../../../sharedComponents/userInput"
import Footer from "../../Layout/Footer"
import LeftSideBar from "../../Layout/guestSideBar"
import "../LoginPage/style.css"
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: ""
    }
    if (localStorage.getItem("token")) props.history.push("/home")
  }

  ComponentDidMount = () => {
    document.getElementById("root").scrollIntoView()
    this.checkToken()
  }

  checkToken = () => {
    if (localStorage.getItem("token")) {
      this.props.history.push("/dashboard")
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmitForm = async e => {
    e.preventDefault()
    let emailvalidator = await emailValidator(this.state.email)
    if (emailvalidator) {
      this.setState({ emailError: emailvalidator })
    } else {
      // errorSwal('oops', 'No api found for reset password.')
      this.props.resetPassword({ email: this.state.email })
    }
  }

  render() {
    return (
      <div className="mainDiv">
        <LeftSideBar />
        <div className="sign-in">
          <span style={{ display: "flex" }}>
            <Link to="/login" from="/rest-password">
              <img
                style={{ marginRight: "13px" }}
                width={40}
                height={40}
                src={arrow_left}
                alt=" "
              />
            </Link>
            <h1>Reset Password</h1>
          </span>
          <form onSubmit={this.onSubmitForm}>
            <div className="mute-text">
              Enter the email associated with your account and we will send you
              a link to reset your password.
            </div>
            <UserInput
              label={"Enter your email"}
              id="resetEmail"
              name="email"
              error={this.state.emailError}
              value={this.state.email}
              onChange={e => {
                this.handleChange(e)
                this.setState({ emailError: "" })
              }}
            />
            <input className="my-3" type="submit" value="Reset" />
          </form>
          <Footer />
        </div>
      </div>
    )
  }
}

function mapState(state) {
  const { authentication } = state
  return { authentication }
}

const actionCreators = {
  resetPassword: userActions.resetPassword
}
const connectedRegisterPage = connect(mapState, actionCreators)(ForgotPassword)
export { connectedRegisterPage as ForgotPassword }
