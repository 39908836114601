/* eslint-disable prettier/prettier */
import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  height: 90vh;
  overflow: auto;
  .wallet_card {
    width: 40%;
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    padding: 0.5rem;
    .wallet_card {
      width: 100%;
    }
  }
`
export const BankWrapper = styled.div`
  width: 40%;
  background: rgba(28, 32, 40, 1);
  border-radius: 15px;
  padding: 10px;
  margin-top: 10px;

  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    padding: 0.5rem;
    width: 100%;
  }
`

export const SelectBank = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-size: 20px;
  font-weight: 500;
  padding: 1rem 0;
  img {
    height: 30px;
    width: 80px;
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 16px;
    img {
      height: 25px;
      width: 60px;
    }
  }
`
export const Search = styled.div`
  width: 100%;
  padding: 0px 15px;
  background: transparent;
  border: 1px solid grey;
  border-radius: 20px;
  margin-bottom: 1rem;
  input {
    background: transparent;
    width: 90%;
    color: white;
    border: none;
    outline: none;
  }
`
export const Banks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  border-radius: 10px;

  border: ${({ selected }) => (selected ? "1px solid #fff" : "none")};
  .title_websit_div {
    border-bottom: ${({ selected }) => (!selected ? "1px solid #ffffff10" : "none")};
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color: white;
    align-items: center;
  }
  .bank_desc_div {
    display: flex;
    flex-direction: column;
  }
  .bank_title_div {
    font-size: 14px;
    font-weight: 400;
  }
  .website_bank_div {
    color: #b1b1b1;
    font-size: 12px;
    font-weight: 400;
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    .bank_title_div {
      font-size: 12px;
    }
    .website_bank_div {
      font-size: 10px;
    }
  }
`
export const Button = styled.button`
  background: white;
  outline: none;
  border: none;
  border-radius: 20px;
  width: 100%;
  color: black;
  padding: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  margin-top: 1rem;
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    width: 45%;
    font-size: 14px;
  }
`

export const MyBanks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 500;
  padding: 0.5rem 0;
  color: #fff;

  .add_new {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
  }
  @media screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 16px;
    .add_new {
      font-size: 12px;
    }
  }
`

export const PayAmount = styled.div`
  color: #b1b1b1;
  font-size: 12px;
  font-weight: 400;
  margin-top: 1rem;
  @media screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 10px;
  }
`
export const AmountDiv = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: white;
  border-bottom: 1px solid grey;
  margin-bottom: 1rem;

  input {
    background: transparent;
    border: none;
    width: 100%;
    color: white;
  }
  @media screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 12px;
  }
`
export const ContinueBtn = styled.button`
  background: white;
  padding: 0.5rem;
  width: 100%;
  color: black;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
  outline: none;
  border: none;
  @media screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 12px;
  }
`
