/* eslint-disable prettier/prettier */
import { profileActions } from "@src/_actions"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

const useHook = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { companyList } = useSelector(state => state.athleteReducer)
  const profile = useSelector(state => state.profile)
  const user = useSelector(state => state.authentication)

  const { chatGroups } = useSelector(state => state.sharedReducer)

  const { user_type } = user.user
  const isAthlete = user_type === "athlete"

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const apiErrorHandler = res => {
    let message = ""
    for (const [key, value] of Object.entries(res)) {
      message = message + `${key} : ${JSON.stringify(value)} `
    }
    errorSwal("oops", message)
  }

  const updateHeader = value => {
    dispatch(profileActions.header(value))
  }

  return {
    isLoading,
    profile,
    history,
    isAthlete,
    chatGroups,
    user,
    companyList,
    updateHeader,
    apiErrorHandler
  }
}
export default useHook
