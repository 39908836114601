import { store } from "./_helpers"
import "./index.css"
import { App } from "./route"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import PubNub from "pubnub"
import { PubNubProvider } from "pubnub-react"
import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"

const pubnub = new PubNub({
  subscribeKey: "sub-c-33a44e2e-8b0b-11eb-8ea8-a61585f8230a",
  publishKey: "pub-c-315b46af-5f06-4bb2-91bc-068319708236",
  uuid: "6ec0bd7f-11c0-43da-975e-2a8ad9ebae0b"
})

const stripeKey =
  "pk_test_51LVHLRFqKjHXclCibj8kaGRIiXPqlGdVT28Apqh3L3x7XG6xAoEMG2DS4HX8x4F6LaUU59ZIDYHWeHAc7fgYLAL600zULQfQTg"

const stripePromise = loadStripe(stripeKey)

render(
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <PubNubProvider client={pubnub}>
        <App />
      </PubNubProvider>
    </Provider>
  </Elements>,
  document.getElementById("app")
)
