export const position_page = props => {
  return {
    type: "position_page",
    payload: props
  }
}
export const company_profile_page = props => {
  return {
    type: "company_profile_page",
    payload: props
  }
}
