/* eslint-disable no-sequences */
// @collapse
import { userConstants } from "../_constants"
import { history } from "../_helpers"
import { userService } from "../_services"
import { errorSwal, succesSwal } from "../sharedComponents/swal/Swal"
import { alertActions } from "./"

export const userActions = {
  login,
  logout,
  signup,
  resetPassword,
  setIGConnectedAction
}

function login(payload) {
  return async dispatch => {
    dispatch(alertActions.loading(true))
    await userService
      .login(payload)
      .then(async res => {
        dispatch(alertActions.loading(false))
        if (res.status >= 400) {
          res = await res.json()
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else if (res.status === 200) {
          res = await res.json()
          const setupComplete =
            res.user?.user_type === "athlete"
              ? res.user?.athlete?.country
              : res.user?.company?.country
          dispatch(
            success({
              ...res,
              ...{ setupComplete: setupComplete ? true : false }
            })
          )
          localStorage.setItem("user", JSON.stringify(res.user))
          localStorage.setItem("token", res.token)
        }
      })
      .catch(function (error) {
        console.log("error", error)
        throw error
      })
  }
  function success(data) {
    return { type: userConstants.LOGIN_SUCCESS, payload: data }
  }
}

function logout() {
  userService.logout()
  return (
    { type: userConstants.HEADER, payload: "" }, { type: userConstants.LOGOUT }
  )
}

function setIGConnectedAction() {
  return { type: userConstants.SET_IG_CONNECT }
}

function signup(payload) {
  return async dispatch => {
    dispatch(alertActions.loading(true))
    await userService
      .signup(payload)
      .then(async res => {
        dispatch(alertActions.loading(false))
        if (res.status >= 400) {
          res = await res.json()
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else if (res.status === 200) {
          res = await res.json()
          const setupComplete =
            res.user?.user_type === "athlete"
              ? res.user?.athlete?.country
              : res.user?.company?.country
          dispatch(success({ ...res, ...{ setupComplete: setupComplete } }))
          localStorage.setItem("user", JSON.stringify(res.user))
          localStorage.setItem("token", res.token)
        }
      })
      .catch(function (error) {
        console.log("error", error)
        throw error
      })
  }

  function success(data) {
    return { type: userConstants.LOGIN_SUCCESS, payload: data }
  }
}

function resetPassword(payload) {
  return async dispatch => {
    dispatch(alertActions.loading(true))
    await userService
      .resetPassword(payload)
      .then(async res => {
        dispatch(alertActions.loading(false))
        if (res.status >= 400) {
          res = await res.json()
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else if (res.status === 200) {
          succesSwal("Password reset successfully;")
          history.push("/")
        }
      })
      .catch(function (error) {
        console.log("error", error)
        throw error
      })
  }
}
