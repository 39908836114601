/* eslint-disable prettier/prettier */
import { sharedService } from "@src/_services"
import { errorSwal, succesSwal } from "@src/sharedComponents/swal/Swal"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

const useHook = () => {
  const { balance } = useSelector(state => state.sharedReducer)
  const [cardsList, setCardsList] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    getBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const getBalance = async () => {
    isLoading(true)
    await sharedService
      .getBalance()
      .then(async res => {
        isLoading(false)
        dispatch({ type: "BALANCE", payload: res.data })
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })
  }

  const ErrorHandler = res => {
    let message = ""
    for (const [key, value] of Object.entries(res)) {
      message = message + `${key} : ${JSON.stringify(value)} `
    }
    console.log(res, message)
    errorSwal("oops", message)
  }

  const saveCard = async payload => {
    isLoading(true)
    await sharedService
      .saveCard(payload)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          ErrorHandler(res)
        } else {
          setCardsList([...cardsList, res.data])
          succesSwal("card added successfully")
        }
      })
      .catch(function (error) {
        isLoading(false)
        ErrorHandler(error.response.data)
        console.log("error", error)
        throw error
      })
  }

  const addBalance = async payload => {
    isLoading(true)
    await sharedService
      .addBalance(payload)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          ErrorHandler(res)
        } else {
          const amount =
            balance?.pending?.[0]?.amount + res.data.payment_intent.amount

          const obj = balance
          obj.pending[0].amount = amount

          const bal = {
            ...balance
          }
          dispatch({
            type: "BALANCE",
            payload: bal
          })

          succesSwal("Amount added successfully")
        }
      })
      .catch(function (error) {
        isLoading(false)
        ErrorHandler(error.response.data)
        throw error
      })
  }

  const getPaymentMethods = async payload => {
    isLoading(true)
    return await sharedService
      .getPaymentMethods(payload)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          ErrorHandler(res)
        } else {
          setCardsList(res.data.results)
          dispatch({ type: "CARDS_LIST", payload: res.data.results })
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })
  }

  const deletePaymentMethod = async djstripe_id => {
    isLoading(true)
    return await sharedService
      .deletePaymentMethod(djstripe_id)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          ErrorHandler(res)
        } else {
          const list = cardsList.filter(
            item => item.djstripe_id !== djstripe_id
          )
          setCardsList(list)
        }
      })
      .catch(function (error) {
        isLoading(false)
        ErrorHandler(error.response.data)
        console.log("error", error)
        throw error
      })
  }

  const setplaidStripeToken = async payload => {
    isLoading(true)
    return await sharedService
      .setplaidStripeToken(payload)
      .then(async res => {
        isLoading(false)
        return res.data
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }

        return false
      })
  }

  const setplaidStripeBank = async payload => {
    isLoading(true)
    return await sharedService
      .setplaidStripeBank(payload)
      .then(async res => {
        isLoading(false)
        succesSwal("Account Connected successfully")
        return res.data
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }

        return false
      })
  }

  const getBanksList = async () => {
    isLoading(true)
    return await sharedService
      .getBanksList()
      .then(async res => {
        isLoading(false)
        return res.data
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }

        return false
      })
  }

  const payoutToBank = async payload => {
    isLoading(true)
    return await sharedService
      .payoutToBank(payload)
      .then(async res => {
        isLoading(false)
        getBalance()
        succesSwal("Withdrawal Successful ")

        return res.data
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }

        return false
      })
  }

  return {
    saveCard,
    getPaymentMethods,
    cardsList,
    deletePaymentMethod,
    addBalance,

    // plaid
    setplaidStripeToken,
    setplaidStripeBank,
    getBanksList,
    payoutToBank
  }
}
export default useHook
