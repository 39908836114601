import "./style.css"
import React from "react"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <div className="footer d-flex flex-column mb-3 align-items-center">
      <div className="copyrights">
        Copyright 2022 College Nil. All Rights Reserved
      </div>
      <div className="footer-links-login-page d-flex text-center">
        <Link className="" to={{ pathname: "/terms-and-conditions" }}>
          Terms & Conditions
        </Link>
        <Link className="" to={{ pathname: "/privacy-policy" }}>
          Privacy Policy
        </Link>
      </div>
    </div>
  )
}

export default Footer
