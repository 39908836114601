/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import Notifications from "../notifications"
import useNotificationHook from "../notifications/useHooks"
import {
  AthleteHomeHeader,
  AthleteMatchHeader,
  AthleteProfileHeader,
  AthleteSubscriptionHeader,
  CompanyProfileDetail
} from "./athelete_headers"
import {
  CompanyHomeHeader,
  CompanyProfileHeader,
  AthletetProfileDetail,
  MatchHeader
} from "./company_headers"
import SharedHeader from "./sharedHeader"
import { HeaderWrapper } from "./styles"
import useHook from "./useHook"
import "@containers/Layout/Dashboard/header.css"
import bell from "@svgs/bell.svg"
import React, { useEffect } from "react"
import { useState } from "react"
import { useSelector } from "react-redux"

const COMPANY_COMPONENT = {
  home: CompanyHomeHeader,
  profile: CompanyProfileHeader,
  profileDetail: AthletetProfileDetail,
  match: MatchHeader,
}

const COMPONENT = {
  home: AthleteHomeHeader,
  match: AthleteMatchHeader,
  profile: AthleteProfileHeader,
  subscriptions: AthleteSubscriptionHeader,
  profileDetail: CompanyProfileDetail
}

const HeaderPage = () => {
  const { getNotifications } = useNotificationHook()

  const { notificationsCount } = useSelector(state => state.sharedReducer)
  const { profile, isCompany } = useHook()
  const { headerName } = profile
  const Component = isCompany
    ? COMPANY_COMPONENT[headerName]
    : COMPONENT[headerName]
  const [showNoti, setShowNoti] = useState(false)

  useEffect(() => {
    getNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HeaderWrapper>
      {Component ? <Component /> : <SharedHeader />}
      <div className="header-right" onClick={() => setShowNoti(!showNoti)}>
        <img src={bell} alt=" " />
        <span className="notifications">
          <span className="not-count">{notificationsCount}</span>
        </span>
      </div>
      {showNoti && <Notifications setShowNoti={setShowNoti} />}
    </HeaderWrapper>
  )
}

export default HeaderPage
