/* eslint-disable prettier/prettier */
import {
  Wrapper,
  SubscriptionInfo,
  CardsWrapper,
  Card,
  ImageContainer,
  Details,
  Buttons,
  MorePlans,
  SubscribedCard,
  SvgContainer,
  SubscribedDetails,
  SubDetails
} from "./style"
import { ProSvg, UnselectSvg, SelectedSvg } from "@svgs"
import React from "react"
import { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import { useLocation } from "react-router-dom"

const Subscribed = () => {
  const location = useLocation()
  const subscribedOffer = location?.state?.subscribedPlan
  const [radio, setRadio] = useState({ show: false })
  const [showModal, setShowModal] = useState(false)
  return (
    <Wrapper>
      <SubscriptionInfo>
        You are {subscribedOffer?.plan_period_title} now!
      </SubscriptionInfo>
      <SubscribedCard>
        <SubscribedDetails>
          <div>
            <SvgContainer>
              <ProSvg />
            </SvgContainer>
            <span className="type">{subscribedOffer?.plan_period_title}</span>
          </div>
          <span className="cancelplan">Cancel Plan</span>
        </SubscribedDetails>
        <SubDetails>
          <span className="type">{subscribedOffer?.price}</span>
          <span className="description">Next billing Jul 23, 2023</span>
        </SubDetails>
      </SubscribedCard>
      <SubscriptionInfo>Upgrade plan</SubscriptionInfo>
      <MorePlans>Get more benefits with Super Pro and Mega pro plans</MorePlans>
      <CardsWrapper>
        {location?.state?.subscriptionPlan.map((value, index) => {
          return (
            subscribedOffer?.type !== value?.type && (
              <Card key={index}>
                <ImageContainer>
                  <ProSvg />
                </ImageContainer>
                <Details>
                  <span className="type">{value.type}</span>
                  <span className="description">{value.description}</span>
                  <span className="type">${value.amount}</span>
                </Details>
                {radio.show && radio.id === index ? (
                  <SelectedSvg
                    onClick={() =>
                      setRadio({ show: false, id: index, offer: value })
                    }
                  />
                ) : (
                  <UnselectSvg
                    onClick={() => {
                      setRadio({ show: true, id: index, offer: value })
                    }}
                  />
                )}
              </Card>
            )
          )
        })}
      </CardsWrapper>
      {radio.show && (
        <Buttons
          onClick={() => {
            setShowModal(true)
          }}
        >
          Change Plan
        </Buttons>
      )}

      <Modal
        show={showModal && radio.show}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div className="modal__body1">
            <div className="modal-description">Please confirm</div>
            <div className="inputdivs"></div>
            <Button
              className="modal_btn_style"
              type="submit"
              onClick={() => {
                setShowModal(false)
              }}
            >
              Subscribe
            </Button>
            <Button className="modalcancel">Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}

export default Subscribed
