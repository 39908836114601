/* eslint-disable prettier/prettier */
// import { max_devices } from "@utils/constants"
import styled, { css } from "styled-components/macro"

export const Wrapper = styled.div`
  background: rgba(28, 32, 40, 1);
  border-radius: 15px;
  margin-top: 10px;
`

export const TitleWrapper = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  color: white;
  font-weight: 500;
  font-size: 20px;
  align-items: center;
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    padding: 0.5rem;
    font-size: 16px;
  }
`

export const ButtonWrapper = styled.div`
  background: rgba(118, 118, 128, 0.24);
  border-radius: 8px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SelectionButton = styled.div`
  background: rgba(118, 118, 128, 0);
  height: 28px;
  width: 50%;
  border-radius: 7px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ active }) =>
    active
      ? css`
          font-weight: 600;
          background: #636366;
          border: 0.5px solid rgba(0, 0, 0, 0.04);
        `
      : css`
          border: none;
          font-weight: 500;
          background: rgba(118, 118, 128, 0);
        `}
`

export const MainContainer = styled.div`
  padding: 1rem 2rem;
  color: white;
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    padding: 0.5rem;
  }
`

export const ButtonMain = styled.div`
  background: white;
  padding: 0.5rem;
  width: 100%;
  color: black;
  font-weight: 500;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 12px;
  }
`

export const CardsContainer = styled.div``

export const CardsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  align-items: center;
  .card-actions {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .title {
    font-size: 20px;
    font-family: "Roboto";
  }

  div {
    .left {
      margin-right: 20px;
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    margin: 10px 0;
    .card-actions {
      font-size: 12px;
      font-weight: 400;
    }
    .title {
      font-size: 18px;
      font-family: "Roboto";
    }

    div {
      .left {
        margin-right: 10px;
      }
    }
  }
`

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  background: #383f4e;
  border-radius: 16px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 10px;
  .default {
    font-size: 12px;
    font-weight: 400;
    margin-right: 15px;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    svg {
      margin-right: 1rem;
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    .title {
      font-size: 12px;
    }
  }
`

export const PaymentWrapper = styled.div`
  margin: 10px 0;
  .payment-amount {
    color: gray;
    font-size: 12px;
    font-family: arial;
    font-weight: 400;
    line-height: 18px;
    padding: 5px 10px;
  }

  .payment-row {
    display: flex;
    margin-left: 10px;
    border-bottom: 1px solid #626262;
  }

  input[type="number"] {
    background: none;
    border: none;
    outline: none;
    color: white;
    width: 100%;
    height: 25px;
    font-size: 15px;
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    .payment-amount {
      padding: 5px 0px;
    }
    input[type="number"] {
      margin-left: 0px;
    }
  }
`
export const ModalBody = styled.div`
  display: flex;
  align-itens: center;
  flex-direction: column;
  .modal-heading {
    font-size: 34px;
    font-weight: 600;
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    .modal-heading {
      font-size: 24px;
    }
  }
  .inputdivs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1.5rem 0;
    input {
      outline: none;
      border: none !important;
      border-bottom: 1px solid rgba(98, 98, 98, 1) !important;
      font-weight: 400;
      font-size: 16px;
      width: 100%;
      background: transparent;
      margin-bottom: 1rem;
    }
    .modalbtnstyle {
      background: #b1b1b1;
      border-radius: 100px;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 500;
      font-size: 16px;
    }
  }
`
export const PayCard = styled.div`
  width: 100%;
  border-radius: 15px;
  background: rgba(56, 63, 78, 1);
  padding: 1.5rem 2rem;
  margin: 1.5rem 0;
  .cardlogo {
    margin-right: 0.5rem;
    height: 30px;
    width: 45px;
  }
  .top-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .cardno {
    display: flex;
    flex-direction: column;
  }
  .card-nmbr {
    color: white;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
  }
  input.number {
    font-family: arial;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #ffffff;
    background: none;
    border: none;
    outline: none;
    margin-top: 0.5rem;
  }
  .mid-element-card {
    display: flex;
    margin-bottom: 1rem;
  }
  .card-date {
    font-family: arial;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    display: flex;
    flex-direction: row;
  }
  input[type="tel"] {
    width: 100px;
    border: none;
    outline: none;
    font-family: arial;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    background: transparent;
  }
  .cvv-text {
    font-family: arial;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
  }
  .cvv-nmbr-input {
    background: none;
    color: white;
    border: none;
    outline: none;
    width: 100px;
    font-size: 16px;
  }
  .holder-name {
    font-family: arial;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
  }
  .input-text {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    padding: 0.5rem;
    input.number {
      font-size: 12px;
    }
    input[type="tel"] {
      font-size: 12px;
    }
    .cvv-nmbr-input {
      font-size: 12px;
    }
    .input-text {
      font-size: 12px;
    }
    .cardlogo {
      margin-right: 0.5rem;
      height: 20px;
      width: 35px;
    }
  }
`
export const WalletBtns = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`
