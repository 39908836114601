/* eslint-disable prettier/prettier */
import {
  Wrapper,
  SubscriptionInfo,
  CardsWrapper,
  Card,
  ImageContainer,
  Details,
  Buttons,
  SubscribedCard,
  SvgContainer,
  SubscribedDetails,
  SubDetails,
  Heading
} from "./style"
import useHook from "./useHook"
import { ProSvg, UnselectSvg, SelectedSvg } from "@svgs"
import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { Modal, Button } from "react-bootstrap"

const Subscribed = () => {
  const [radio, setRadio] = useState({ show: false })
  const [showModal, setShowModal] = useState(false)
  const {
    activePlan,
    getPlans,
    plansList,
    getActivePlan,
    upgradeSubsciption,
    activateSubscription
  } = useHook()

  useEffect(() => {
    getPlans()
    getActivePlan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const RemainingPlans = plansList?.filter(
    item => item?.id !== activePlan?.plan?.id
  )
  const customArr = RemainingPlans?.filter(
    item => item.plan_period_type === "custom"
  )
  const monthlyArr = RemainingPlans?.filter(
    item => item.plan_period_type === "monthly"
  )
  const yearlyArr = RemainingPlans?.filter(
    item => item.plan_period_type === "yearly"
  )
  const Arr = [
    { arr: monthlyArr, title: "Monthly" },
    { arr: customArr, title: "6 Months" },
    { arr: yearlyArr, title: "Yearly" }
  ]
  const subscribe = () => {
    if (activePlan.id) {
      upgradeSubsciption(radio?.offer?.id)
    } else {
      activateSubscription(radio?.offer?.id)
    }
    setShowModal(false)
  }

  return (
    <Wrapper>
      {activePlan?.id && (
        <>
          <SubscriptionInfo>
            You are the {activePlan?.plan_period_title} {activePlan?.plan_title}{" "}
            plan now!
          </SubscriptionInfo>
          <SubscribedCard>
            <SubscribedDetails>
              <div>
                <SvgContainer>
                  <ProSvg />
                </SvgContainer>
                <span className="type">{activePlan?.plan_title}</span>
              </div>
              <span className="cancelplan">Cancel Plan</span>
            </SubscribedDetails>
            <SubDetails>
              <span className="type">${activePlan?.price}</span>
              <span className="description">{`${activePlan.campaign_limit} campaign ${activePlan.campaign_period_type}`}</span>
              <span className="description">
                Next billing {new Date(activePlan?.ended_at).toDateString()}
              </span>
            </SubDetails>
          </SubscribedCard>
          <SubscriptionInfo>Upgrade plan</SubscriptionInfo>
        </>
      )}
      {Arr?.map((item, index) => {
        return (
          <div key={index}>
            <Heading>{item.title}</Heading>
            <CardsWrapper>
              {item?.arr?.map((value, index) => {
                return (
                  <Card key={index}>
                    <ImageContainer>
                      <ProSvg />
                    </ImageContainer>
                    <Details>
                      <span className="type">{value.title}</span>
                      <span className="description">{`${value.campaign_limit} campaign ${value.campaign_period_type}`}</span>
                      <span className="type">${value.price}</span>
                    </Details>
                    {radio.show &&
                    radio.id === index &&
                    radio.plan === item.title ? (
                      <SelectedSvg
                        className="selectbox"
                        onClick={() =>
                          setRadio({
                            show: false,
                            id: index,
                            offer: value,
                            plan: item.title
                          })
                        }
                      />
                    ) : (
                      <UnselectSvg
                        className="selectbox"
                        onClick={() => {
                          setRadio({
                            show: true,
                            id: index,
                            offer: value,
                            plan: item.title
                          })
                        }}
                      />
                    )}
                  </Card>
                )
              })}
            </CardsWrapper>
          </div>
        )
      })}

      {radio.show && (
        <Buttons
          onClick={() => {
            setShowModal(true)
          }}
        >
          {activePlan?.id ? "Change Plan" : "Pay"}
        </Buttons>
      )}

      <Modal
        show={showModal && radio.show}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div className="modal__body1">
            <div className="modal-description">Please confirm</div>
            <div className="inputdivs"></div>
            <Button
              className="modal_btn_style"
              type="submit"
              onClick={subscribe}
            >
              Subscribe
            </Button>
            <Button className="modalcancel" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}

export default Subscribed
