/* eslint-disable prettier/prettier */
import { authHeader, authHeaderAxios } from "../_helpers"
import { siteUrls } from "../_helpers"
import axios from "axios"

export const request = axios.create({
  baseURL: siteUrls.BASE_URL
})

export const profileServices = {
  updateProfile,
  getProfile,

  connectInstragram,
  checkInstagramConnect,

  connectFacebook,
  connectTiktok,
  connectLinkedin,
  connectTwitter,
  connectYoutube,
  checkSocialsConnect,
  unlinkSocial
}

function updateProfile(payload) {
  return request.patch("accounts/profile/", payload.formData, authHeaderAxios())
  // return fetch(`${siteUrls.BASE_URL}accounts/profile/`, authHeaderAxios());
}

function connectInstragram(payload) {
  return request.post(
    "accounts/social/instagram/connect/web/",
    payload,
    authHeaderAxios()
  )
}

function checkInstagramConnect() {
  return request
    .get("accounts/social/instagram/", authHeaderAxios())
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      console.log("error=>", error)
      throw error
    })
}

function connectFacebook(payload) {
  return request.post(
    "accounts/social/facebook/connect/web/",
    payload,
    authHeaderAxios()
  )
}

function connectLinkedin(payload) {
  return request.post(
    "accounts/social/linkedin/connect/web/",
    payload,
    authHeaderAxios()
  )
}

function connectTwitter(payload) {
  return request.post(
    "accounts/social/twitter/connect/",
    payload,
    authHeaderAxios()
  )
}

function connectYoutube(payload) {
  return request.post(
    "accounts/social/google/connect/web/",
    payload,
    authHeaderAxios()
  )
}

function connectTiktok(payload) {
  return request.post(
    "accounts/social/tiktok/connect/web/",
    payload,
    authHeaderAxios()
  )
}

function checkSocialsConnect() {
  return request
    .get("accounts/social/profiles/", authHeaderAxios())
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      console.log("error=>", error)
      throw error
    })
}

function unlinkSocial(payload) {
  return request
    .post("accounts/social/profiles/unlink/", payload, authHeaderAxios())
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      console.log("error=>", error)
      throw error
    })
}

function getProfile() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  }

  return fetch(`${siteUrls.BASE_URL}accounts/profile/`, requestOptions)
}

// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 logou        body: payload
//                 window.location.reload(true);
//             }

//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }

//         return data;
//     });
// }
