/* eslint-disable prettier/prettier */
import { history } from "../../../_helpers"
import lefthand from "../../../assets/images/lefthand.png"
import righthand from "../../../assets/images/righthand.png"
import Footer from "../../Layout/Footer"
import LeftSideBar from "../../Layout/guestSideBar"
import "./style.css"
import React, { useEffect } from "react"

// import { useSelector } from 'react-redux';

const InstructionPage = () => {
  // const { authentication } = useSelector(state => state.authentication);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("stage") !== "instructions") {
        history.push("/home")
      }
    } else {
      history.push("/home")
    }
  }, [])

  const onSubmitForm = async e => {
    e.preventDefault()

    await localStorage.removeItem("stage")
    history.push("/")
  }

  return (
    <div className="mainDiv">
      <LeftSideBar />
      <div className="sign-in">
        <div className="main_instruct_div">
          <div className="layer1"></div>
          <div className="layer2"></div>

          <div className="layer3">
            <div className="layer3-main">
              <div className="layer3-section1">
                <span className="circle-inst"></span>
                <div className="d-flex flex-column">
                  <span
                    className="line-inst mb-2"
                    style={{ width: "160px" }}
                  ></span>
                  <span className="line-inst"></span>
                </div>
              </div>
              <div className="layer3-box"></div>
              <div className="d-flex justify-content-between">
                <span className="layer3-buttons"></span>
                <span className="layer3-buttons"></span>
              </div>
            </div>

            <div className="inst-actions">
              <span className="text-center text-white">
                <img style={{}} src={lefthand} alt=" " />
                <p className="mt-1" style={{ fontSize: "10px" }}>
                  Click left to like
                </p>
              </span>
              <span className="text-center text-white">
                <img style={{}} src={righthand} alt=" " />
                <p className="mt-1" style={{ fontSize: "10px" }}>
                  Click right to like
                </p>
              </span>
            </div>

            <span className="inst-actions" style={{ bottom: "-200px" }}>
              <button
                className="my-3"
                type="submit"
                onClick={onSubmitForm}
                style={{ width: "100%" }}
              >
                Got it
              </button>
            </span>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
export default InstructionPage
