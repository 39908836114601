import { siteUrls, host } from "../_helpers"

const REDIRECT_URL = {
  youtube: `${siteUrls.BASE_SOCIAL}/google/`,
  facebook: `${siteUrls.BASE_SOCIAL}/facebook/`,
  instagram: `${siteUrls.BASE_SOCIAL}/instagram/`,
  linkedin: `${siteUrls.BASE_SOCIAL}/linkedin/`,
  tiktok: `${siteUrls.BASE_SOCIAL}/tiktok/`
}

export const INSTAGRAM_OAUTH = data => {
  const client_id = "348960884114110"
  const Oauth = `https://www.${data}.com/oauth/authorize?client_id=${client_id}&redirect_uri=${REDIRECT_URL[data]}&response_type=code&scope=user_profile,user_media`

  return Oauth
}

export const FACEBOOK_OAUTH = data => {
  const client_id = "735841814141090"
  const redirectUrl = `${siteUrls.BASE_SOCIAL}/facebook/`
  const scopes = "user_link"
  const Oauth = `https://www.facebook.com/v14.0/dialog/oauth?client_id=${client_id}&scope=${scopes}&redirect_uri=${redirectUrl}`
  return Oauth
}

export const LINKEDIN_OAUTH = data => {
  const client_id = "86crv4k8yaclic"
  const scopes = "r_liteprofile,r_emailaddress"
  const redirectUrl = `${siteUrls.BASE_SOCIAL}/linkedin/`
  const Oauth = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&scope=${scopes}&redirect_uri=${redirectUrl}`
  return Oauth
}

export const TWITTER_OAUTH = () => {
  const callback_url = `https://${host}/accounts/social/twitter/web-authenticate/`
  const Oauth = `https://${host}/accounts/social/twitter/web-authorize/?callback_url=${callback_url}`
  return Oauth
}

export const TIKTOK_OAUTH = async () => {
  const token = await localStorage.getItem("token")
  const Oauth = `https://${host}/accounts/social/tiktok/web-connect/?token=${token}`
  return Oauth
}

export const YOUTUBE_OAUTH = () => {
  const callback_url = `${siteUrls.BASE_SOCIAL}/google/`
  const Oauth = `https://${host}/accounts/social/google/authorize/?callback_url=${callback_url}`
  return Oauth
}
