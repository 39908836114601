import "./style.css"
import React from "react"

const SwitchTabs = ({ TABS, activeTab, setActiveTab, ...props }) => {
  return (
    <ul className="nav" {...props}>
      {TABS.map(item => {
        return (
          <li
            className={activeTab === item ? "active" : "lt-btn"}
            onClick={() => setActiveTab(item)}
          >
            {item}
          </li>
        )
      })}
    </ul>
  )
}

export default SwitchTabs
