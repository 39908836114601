import FacebookCallBack from "@containers/screens/social_integration/facebookCallback"
import InstagramCallBack from "@containers/screens/social_integration/instagramCallback"
import LinkedinCallBack from "@containers/screens/social_integration/linkedinCallback"
import TikCallBack from "@containers/screens/social_integration/tiktokCallback"
import TwitterCallback from "@containers/screens/social_integration/twitterCallback"
import YoutubeCallBack from "@containers/screens/social_integration/youtubeCallback"

export {
  FacebookCallBack,
  InstagramCallBack,
  LinkedinCallBack,
  TikCallBack,
  TwitterCallback,
  YoutubeCallBack
}

export const SOCIAL_HANDLER = [
  { path: "/connect/instagram/", component: InstagramCallBack },
  { path: "/connect/facebook/", component: FacebookCallBack },
  { path: "/connect/linkedin/", component: LinkedinCallBack },
  { path: "/connect/google/", component: YoutubeCallBack },
  { path: "/connect/tiktok/", component: TikCallBack },
  { path: "/connect/twitter/", component: TwitterCallback }
]
