/* eslint-disable prettier/prettier */
import styled from "styled-components/macro"

export const Wrapper = styled.div`
  width: 350px;
  height: 90vh;
  position: absolute;
  z-index: 20000;
  background: rgba(0, 0, 0, 0.92);
  padding: 1rem;
  right: 0;
  top: 10vh;
  overflow: scroll;

  @media screen and (min-width: 280px) and (max-width: 320px) {
    width: 260px;
  }
`
export const Head = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 28px;
  font-weight: 600;
  @media screen and (min-width: 280px) and (max-width: 500px) {
    font-size: 20px;
    font-weight: 500;
  }
`
export const NotiDate = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 28px;
  font-weight: 600;
  padding: 1rem 0;
  .day {
    font-size: 20px;
    font-weight: 500;
  }
  .readall {
    font-size: 16px;
    font-weight: 400;
  }
  @media screen and (min-width: 280px) and (max-width: 500px) {
    font-size: 20px;
    font-weight: 500;
  }
  .day {
    font-size: 16px;
  }
  .readall {
    font-size: 12px;
  }
`
export const NotiContainer = styled.div`
  background: ${({ isRead }) => (isRead ? "#1C2028" : "#363E4E")};
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  margin: 10px 0;

  .rowclass {
    display: flex;
    flex-direction: row;
  }
  .columnclass {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .name {
    font-weight: 500;
    font-size: 12px;
    margin-right: auto;
  }
  .time {
    font-weight: 400;
    font-size: 10px;
    color: rgba(177, 177, 177, 1);
  }
  .action {
    font-weight: 500;
    font-size: 10px;
    padding: 4px 7px;
  }
  .notiactions {
    display: flex;
    flex-direction: column;
    border-left: 1px solid rgba(74, 81, 96, 1);

    align-items: center;
    justify-content: center;
    width: 80px;
  }
  .notirowclass {
    padding: 8px;
    display: flex;
    flex-direction: row;
    flex: 1;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 5px;
      background: #fff;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 500px) {
    .name {
      font-weight: 400;
      font-size: 10px;
    }
    .time {
      font-weight: 400;
      font-size: 8px;
      color: rgba(177, 177, 177, 1);
    }
    .action {
      font-weight: 400;
      font-size: 8px;
    }
  }
`
