import useHook from "./useHook"
import LeftSideBar from "@containers/Layout/guestSideBar"
import InputFields from "@src/sharedComponents/InputFields"
import FileUpload from "@src/sharedComponents/fileupload/FileUpload"
import { COUNTRIES, US_STATES, CAN_STATES, GENDER } from "@src/utils/data"
import React from "react"

const AthleteSetup = () => {
  const { errorValue, onSubmit, inputValue, setInputValue, setErrorValue } =
    useHook()

  const inputHandler = e => {
    setErrorValue({ ...errorValue, [e.target.name]: "" })
    setInputValue({ ...inputValue, [e.target.name]: e.target.value })
  }

  const onChange = (name, value) => {
    setErrorValue({ ...errorValue, [name]: "" })
    setInputValue({ ...inputValue, [name]: value })
  }

  const FIELDS_LIST = [
    {
      label: "First Name",
      id: "first_name",
      name: "first_name"
    },
    {
      label: "Last Name",
      name: "last_name",
      id: "last_name"
    },
    {
      label: "date of birth",
      id: "date_of_birth",
      name: "date_of_birth",
      type: "date"
    },
    {
      label: "Gender",
      id: "gender",
      name: "gender",
      list: GENDER,
      type: "dropdown"
    },
    {
      label: "Country",
      name: "country",
      type: "dropdown",
      id: "countryType",
      list: COUNTRIES
    },
    {
      label: "State",
      name: "state",
      type: "dropdown",
      id: "stateType",
      list: inputValue?.country !== "US" ? CAN_STATES : US_STATES
    },
    // {
    //   label: "City",
    //   name: "city",
    //   id: "city"
    // },
    {
      label: "school",
      id: "school",
      name: "school"
    },
    {
      label: "Phone Number",
      id: "phone_number",
      name: "phone_number"
    },
    {
      label: "Bio",
      id: "bio",
      name: "bio"
    }
  ]

  return (
    <div className="mainDiv">
      <LeftSideBar />
      <div className="sign-in">
        <h1>Set up account</h1>

        <FileUpload
          isAthlete
          error={errorValue.profile_picture}
          onselectImage={file => onChange("profile_picture", file)}
        />
        <form className="mb-5" onSubmit={onSubmit}>
          <InputFields
            FIELDS_LIST={FIELDS_LIST}
            inputHandler={inputHandler}
            setErrorList={setErrorValue}
            errorList={errorValue}
            setUserInput={setInputValue}
            userInput={inputValue}
          />

          <input className="mt-3 mb-5" type="submit" value="Continue" />
        </form>
      </div>
    </div>
  )
}
export default AthleteSetup
