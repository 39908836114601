/* eslint-disable prettier/prettier */
import { authHeaderAxios } from "../_helpers"
import { siteUrls } from "../_helpers"
import axios from "axios"

export const request = axios.create({
  baseURL: siteUrls.BASE_URL
})

const chatGroups = () => {
  return request.get(`chats/rooms/`, authHeaderAxios())
}

const startChat = payload => {
  return request.post(`chats/rooms/`, payload, authHeaderAxios())
}

const updateLastMessage = (id, payload) => {
  return request.patch(`chats/rooms/${id}/`, payload, authHeaderAxios())
}

const uploadChatMedia = (id, payload) => {
  return request.post(`chats/rooms/${id}/files/`, payload, authHeaderAxios())
}
const paymentSend = payload => {
  return request.post(`payments/send/`, payload, authHeaderAxios())
}

const getNotifications = () => {
  return request.get(`notifications/`, authHeaderAxios())
}

const deleteNotifications = id => {
  return request.delete(`notifications/${id}/`, authHeaderAxios())
}

const readNotifications = (id, payload) => {
  return request.patch(`notifications/${id}/`, payload, authHeaderAxios())
}

const readAllNotifications = () => {
  return request.post(`notifications/`, { is_read: true }, authHeaderAxios())
}

const getPaymentMethods = () => {
  return request.get(`stripe/me/payment-methods/`, authHeaderAxios())
}

const deletePaymentMethod = djstripe_id => {
  return request.delete(
    `stripe/me/payment-methods/${djstripe_id}/`,
    authHeaderAxios()
  )
}

const saveCard = payload => {
  return request.post(`stripe/save-card/`, payload, authHeaderAxios())
}

const getBalance = payload => {
  return request.get(`stripe/account-balance/`, authHeaderAxios())
}

const addBalance = payload => {
  return request.post(`wallets/balance/add/`, payload, authHeaderAxios())
}

const getAthleteTransactionsList = payload => {
  return request.get(
    `/stripe/me/account/balance-transactions/`,
    authHeaderAxios()
  )
}

const apiCall = api => {
  return request.get(api, authHeaderAxios())
}

const getMccList = () => {
  return request.get(`/stripe/mcc-list/`, authHeaderAxios())
}

const fetchPaymentIntentClientSecret = payload => {
  return request.post(
    `stripe/account-balance/payment-intent-secret/`,
    payload,
    authHeaderAxios()
  )
}

const getPlaidLinkToken = () => {
  return request.get(`/plaid/generate-link-token/`, authHeaderAxios())
}

const setplaidStripeToken = payload => {
  return request.post(
    `/plaid/get-stripe-bank-token/`,
    payload,
    authHeaderAxios()
  )
}

const setplaidStripeBank = payload => {
  return request.post(
    `/stripe/external-accounts/banks/`,
    payload,
    authHeaderAxios()
  )
}

const getBanksList = () => {
  return request.get(`/stripe/external-accounts/banks/`, authHeaderAxios())
}

const payoutToBank = payload => {
  return request.post(`/stripe/bank-payouts/`, payload, authHeaderAxios())
}

const SharedService = {
  chatGroups,
  startChat,
  updateLastMessage,
  uploadChatMedia,
  paymentSend,
  getNotifications,
  deleteNotifications,
  readNotifications,
  readAllNotifications,
  getPaymentMethods,
  saveCard,
  deletePaymentMethod,
  getBalance,
  getAthleteTransactionsList,
  apiCall,
  getMccList,
  addBalance,
  fetchPaymentIntentClientSecret,
  // Plaid
  getPlaidLinkToken,
  setplaidStripeToken,
  setplaidStripeBank,
  getBanksList,
  payoutToBank
}

export default SharedService
