import { history } from "../../../_helpers"
import { succesSwal, errorSwal } from "../../../sharedComponents/swal/Swal"
import React, { useEffect } from "react"

const TiktokCallBack = () => {
  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })

    if (params.success) {
      succesSwal("success", "connected successfully")
      history.push("/home/profile")
    } else {
      errorSwal("oops", "something went wrong")
    }
  }, [])

  return <div />
}
export default TiktokCallBack
