/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import plaidLogo from "../../../../../../assets/images/Plaid_logo.png"
import angleright from "../../../../../../assets/images/angle-right.png"
import profile from "../../../../../../assets/images/profile.png"
import WithdrawCard from "../pay-card"
import Transactions from "../transactions"
import WalletCard from "../wallet-card"
import {
  AmountDiv,
  BankArrDiv,
  Col1,
  ContinueBtn,
  MyBanks,
  PayAmount,
  TitleWebsite,
  WalletCards,
  WithdrawlCardBody,
  WithdrawlHead,
  Wrapper
} from "./style"
import React from "react"

const BankWallet = () => {
  return (
    <Wrapper>
      <Col1>
        <WalletCard />
        <WithdrawCard />
        <div style={{ paddingTop: "1rem" }}>
          <WalletCards>
            <WithdrawlHead>
              <div>Withdraw</div>
              <img src={plaidLogo} className="plaidlogo" />
            </WithdrawlHead>
            <WithdrawlCardBody>
              <MyBanks>
                <span>My Banks</span>
                <div className="add_new">
                  <span style={{ paddingRight: "3rem" }}>Add new</span>
                  <span>Edit</span>
                </div>
              </MyBanks>
              <BankArrDiv>
                <img
                  src={profile}
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "100%"
                  }}
                />
                <TitleWebsite>
                  <div className="bank_desc_div">
                    <div className="bank_title_div">
                      Enterprise Bank-Personal
                    </div>
                    <div className="website_bank_div">
                      www.enterprisebanking.com
                    </div>
                  </div>
                  <img
                    src={angleright}
                    style={{ height: "10px", width: "10px" }}
                    alt=""
                  />
                </TitleWebsite>
              </BankArrDiv>
              <PayAmount>Payment Amount</PayAmount>
              <AmountDiv>$0.00</AmountDiv>
              <ContinueBtn>Pay</ContinueBtn>
            </WithdrawlCardBody>
          </WalletCards>
        </div>
      </Col1>
      <Transactions />
    </Wrapper>
  )
}

export default BankWallet
