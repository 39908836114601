/* eslint-disable prettier/prettier */
import styled from "styled-components/macro"

export const ButtonConainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  .bnt {
    background-color: #fff;
    color: #000;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease;
    border-radius: 100px;
    width: 40%;
    text-align: center;
  }
`
