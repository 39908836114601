/* eslint-disable prettier/prettier */
import { userConstants } from "../_constants"

const user = JSON.parse(localStorage.getItem("user"))
const token = localStorage.getItem("token")
const setupComplete = user
  ? user?.user_type === "athlete"
    ? user.athlete?.country
    : user.company?.country
  : false

const initialState = {
  user: user ? user : {},
  company: {},
  token: token ? token : null,
  setupComplete: setupComplete ? true : false,
  isIGConnected: false,
  savedUser: {}
}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        user: action.payload.user,
        setupComplete: action.payload.setupComplete
      }
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        company: action.payload.company,
        user: action.payload.user,
        setupComplete: action.payload.setupComplete
      }
    case userConstants.SETUP_COMPLETE:
      return {
        ...state,
        user: action.payload,
        setupComplete: true
      }
    case userConstants.LOGOUT:
      return {
        ...state,
        token: null,
        user: {},
        company: {},
        setupComplete: false,
        isIGConnected: false
      }
    case userConstants.SET_IG_CONNECT:
      return {
        ...state,
        isIGConnected: true
      }
    default:
      return state
  }
}
