/* eslint-disable prettier/prettier */
import { MatchNotification } from "./matchNotification"
import { Wrapper, Head, NotiContainer, NotiDate } from "./style"
import useHook from "./useHooks"
import { getLocalTimeFromNow } from "@src/utils/helpers"
import { CrossSvg } from "@svgs"
import React from "react"

const Notifications = props => {
  const {
    getNotifications,
    notificationsList,
    deleteNotifications,
    readNotifications,
    readAllNotifications,
    isCompany
  } = useHook()

  React.useEffect(() => {
    getNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <Head>
        <div>Notification</div>
        <CrossSvg
          onClick={() => {
            props.setShowNoti(false)
          }}
        />
      </Head>

      <NotiDate>
        <div className="day"></div>
        <div className="readall" onClick={() => readAllNotifications()}>
          Read All s
        </div>
      </NotiDate>
      {notificationsList?.map((item, index) => {
        const img = isCompany ? item.data?.athlete_profile_picture: item.data?.company_logo
        return (
          <div key={index}>
            {item.title === "New match" ? (
              <MatchNotification
                data={item}
                onDelete={() => deleteNotifications(item.id)}
                onRead={() => readNotifications(item.id, index)}
              />
            ) : (
              <NotiContainer isRead={item.is_read}>
                <div className="notirowclass">
                  <img src={img} alt="" />
                  <div className="columnclass">
                    <div className="rowclass">
                      <div className="name">{item.title}</div>
                      <div className="time">
                        {getLocalTimeFromNow(item.created)}
                      </div>
                    </div>
                    <div className="time">{item.body}</div>
                  </div>
                </div>
                <div className="notiactions">
                  {!item.is_read && (
                    <div
                      className="action"
                      style={{ borderBottom: "1px solid rgba(74, 81, 96, 1)" }}
                      onClick={() => readNotifications(item.id, index)}
                    >
                      Mark as read
                    </div>
                  )}
                  <div
                    className="action"
                    onClick={() => deleteNotifications(item.id)}
                  >
                    Delete
                  </div>
                </div>
              </NotiContainer>
            )}
          </div>
        )
      })}
    </Wrapper>
  )
}

export default Notifications
