/* eslint-disable prettier/prettier */

/* eslint-disable react-hooks/exhaustive-deps */
import { userActions } from "../../../_actions"
import { history, siteUrls } from "../../../_helpers"
import { profileServices } from "../../../_services"
import { errorSwal, succesSwal } from "../../../sharedComponents/swal/Swal"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

const InstagramCallBack = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })

    const payload = {
      code: params.code,
      callback_url: `${siteUrls.BASE_SOCIAL}/instagram/`
    }

    profileServices
      .connectInstragram(payload)
      .then(async res => {
        succesSwal("success", "connected successfully")
        dispatch(userActions.setIGConnectedAction())

        const stage = localStorage.getItem("stage")
        if (stage) {
          await localStorage.setItem("stage", "instructions")
          history.push("/instructions")
        } else {
          history.push("/home/profile")
        }
      })
      .catch(function (error) {
        history.push("/")
        errorSwal(
          "oops",
          JSON.stringify(error.response ? error.response.data : error)
        )
        throw error
      })
  }, [])

  return <div />
}
export default InstagramCallBack
