/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import Inbox from "./inbox"
import useHook from "./useHook"
import "@containers/Layout/Dashboard/chat.css"
import search from "@src/assets/svgs/search.svg"
import dateFormat from "dateformat"
import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

const Chat = () => {
  const dispatch = useDispatch()
  const { chatGroups, getChatGroups, ChatProfile, setChatProfile, isAthlete } =
    useHook()
  const { chatUser } = useSelector(state => state.sharedReducer)

  const [visibleChatbox, setVisibleChatbox] = useState(false)

  React.useEffect(() => {
    if (chatUser.name) {
      setChatProfile(chatUser)
      setVisibleChatbox(true)
      dispatch({ type: "START_CHAT", payload: [] })
    }
    getChatGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatUser])

  React.useEffect(() => {
    // setChatsList(chatGroups)
    console.log({ chatGroups })
  }, [chatGroups])

  const openChatbox = user => {
    setChatProfile(user)
    setVisibleChatbox(true)
  }
  const now = new Date()
  const todaydate = dateFormat(now, "mm dS")
  return (
    <div
      className="sidebar-wapper"
      style={{ backgroundColor: "#101217", height: "90vh" }}
    >
      <div className={`chat-sidebar ${visibleChatbox ? "display_none" : ""}`}>
        <div className="search_main_div">
          <div className="chat-search">
            <img src={search} style={{ margin: "0px 5px" }} />
            <input
              className="search_input"
              placeholder={isAthlete ? "Search Company" : "Search Athlete"}
            />
          </div>
        </div>
        {chatGroups?.map((item, index) => {
          const { image, name, last_message, last_message_datetime } = item
          const isActive = ChatProfile.name === name
          return (
            <div
              key={index}
              className={`chat-profile ${isActive ? "profile-selected" : ""}`}
              onClick={() => openChatbox(item)}
            >
              <div
                className="nike_logo_div"
                style={{ height: "45px", width: "45px" }}
              >
                <img src={image} />
              </div>
              <div className="media-body1">
                <div className="lastmsgtime">
                  <h6
                    className="my-0 text-white"
                    style={{ fontSize: "14px", fontWeight: 400 }}
                  >
                    {name}
                  </h6>
                  {todaydate === dateFormat(last_message_datetime, "mm dS") ? (
                    <div className="msgtime">
                      {dateFormat(last_message_datetime, "hh:MM:ss TT")}
                    </div>
                  ) : (
                    <div className="msgtime">
                      {dateFormat(last_message_datetime, "mmmm dS hh:mm TT")}
                    </div>
                  )}
                </div>

                <p className="mb-0 text-muted small_font"> {last_message}</p>
              </div>
            </div>
          )
        })}
      </div>

      {/* Chat Conatainer */}

      {visibleChatbox && (
        <Inbox
          ChatProfile={ChatProfile}
          setVisibleChatbox={setVisibleChatbox}
        />
      )}
    </div>
  )
}

export default Chat
