const initialstate = {
  page_position: "Bank",
  profile_position: "Personal details"
}
export const homeReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "position_page":
      return {
        ...state,
        page_position: action.payload
      }
    case "company_profile_page":
      return {
        ...state,
        profile_position: action.payload
      }
    default:
      return state
  }
}
