/* eslint-disable prettier/prettier */
import styled from "styled-components/macro"

export const Wrapper = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  color: white;
  height: 90vh;
  overflow: auto;
  @media screen and (min-width: 280px) and (max-width: 600px) {
    padding: 1rem;
  }
`
export const SubscriptionInfo = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-left: 5px;
  @media screen and (min-width: 280px) and (max-width: 600px) {
    font-size: 16px;
  }
`
export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (min-width: 500px) and (max-width: 1100px) {
    justify-content: space-between;
  }
`

export const SubscribedCard = styled.div`
  width: 32%;
  background: rgba(255, 255, 255, 0.04);
  color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 22px;
  margin-top: 10px;
  margin-bottom: 2rem;
  align-items: center;
  @media screen and (min-width: 500px) and (max-width: 1100px) {
    width: 58%;
  }
  @media screen and (min-width: 280px) and (max-width: 499px) {
    width: 100%;
  }
`
export const MorePlans = styled.div`
  font-size: 14px;
  font-weight: 400;
  @media screen and (min-width: 280px) and (max-width: 600px) {
    font-size: 10px;
  }
`
export const SvgContainer = styled.div`
  border-radius: 12px;
  background: white;
  padding: 7px 12px;
  height: fit-content;
  color: black;
  width: fit-content;
`
export const SubscribedDetails = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .type {
    font-size: 20px;
    font-weight: 500;
    margin-left: 7px;
  }
  .cancelplan {
    font-size: 16px;
    font-weight: 400;
  }
  @media screen and (min-width: 280px) and (max-width: 600px) {
    .type {
      font-size: 16px;
    }
    .cancelplan {
      font-size: 12px;
    }
  }
`

export const Card = styled.div`
  width: 32%;
  background: rgba(255, 255, 255, 0.04);
  color: white;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  border-radius: 22px;
  margin: 10px 20px 20px 0px;
  @media screen and (min-width: 500px) and (max-width: 1100px) {
    width: 48%;
    margin: 0px;
    margin-bottom: 1rem;
  }
  @media screen and (min-width: 280px) and (max-width: 499px) {
    width: 100%;
    margin: 0px;
    margin-bottom: 1rem;
  }
`
export const ImageContainer = styled.div`
  border-radius: 12px;
  background: #211c2b;
  padding: 7px 12px;
  height: fit-content;
  color: white;
`
export const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  .type {
    font-size: 20px;
    font-weight: 500;
  }
  .description {
    font-size: 12px;
    font-weight: 400;
  }
  @media screen and (min-width: 280px) and (max-width: 600px) {
    .type {
      font-size: 16px;
    }
    .description {
      font-size: 10px;
    }
  }
`
export const SubDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  width: 70%;
  .type {
    font-size: 20px;
    font-weight: 500;
  }
  .description {
    font-size: 12px;
    font-weight: 400;
  }
  @media screen and (min-width: 280px) and (max-width: 600px) {
    width: 75%;
    .type {
      font-size: 16px;
    }
    .description {
      font-size: 10px;
    }
  }
`
export const Buttons = styled.div`
  width: 33%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background: white;
  border-radius: 100px;
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 500;
  @media screen and (min-width: 500px) and (max-width: 1100px) {
    width: 48%;
  }
  @media screen and (min-width: 280px) and (max-width: 499px) {
    width: 100%;
  }
`
