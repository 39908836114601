/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import useHook from "../useHook"
import { Wrapper } from "./styles"
import leftarrow from "@src/assets/images/icons/arrow left.png"
import React from "react"

const Title = ({ headerTitle, isBack, subTitle }) => {
  const { menuClick, history } = useHook()

  return (
    <Wrapper>
      <i className="fa fa-bars menu-icon" onClick={menuClick} />

      {isBack && (
        <img
          style={{ marginRight: "10px" }}
          width={40}
          height={40}
          onClick={() => {
            history.push(`/${isBack}`)
          }}
          src={leftarrow}
          alt=""
        />
      )}

      <span className="header_head">
        {headerTitle}
        {subTitle && <span className="subtitle">{subTitle}</span>}
      </span>
    </Wrapper>
  )
}

export default Title
