import styled from "styled-components"

export const Item = styled.div`
  text-align: center;
  display: flex;
  cursor: pointer;
  width: 170px;
  padding: 1rem;

  .text {
    color: ${({ active }) => (active ? "#fff" : "#717273")};
    margin-left: 2.5rem;
    font-size: 20px;
  }
`
