/* eslint-disable prettier/prettier */
import { athleteService } from "@src/_services"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useState } from "react"
import { useDispatch } from "react-redux"

const useHook = () => {
  const [inputValue, setInputValue] = useState({
    gender: "male",
    country: "US",
    state: 'AL'

  })
  const [errorValue, setErrorValue] = useState({})

  const dispatch = useDispatch()

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const onSubmit = async event => {
    event.preventDefault()

    const requiredFields = {
      profile_picture: "Upload profile picture",
      first_name: "first name required",
      last_name: "Last Name required",
      date_of_birth: "Invalid date of birth",
      gender: "Geneder required",
      country: "Invalid country",
      state: "Invalid state",
      phone_number: "Invalid Phone Number",
      school: "Invalid School",
      bio: "Invalid bio"
    }

    let errors = {}
    let errorStatus = false
    for (const [key, value] of Object.entries(requiredFields)) {
      if (!!!inputValue[key]) {
        errors[key] = value
        errorStatus = true
      }
    }
    setErrorValue(errors)
    if (!errorStatus) {
      isLoading(true)
      profileUpdate()
    }
  }

  const profileUpdate = async () => {
    let formData = new FormData()

    for (const [key, value] of Object.entries(inputValue)) {
      formData.append(`${key}`, value)
    }

    isLoading(true)
    await athleteService
      .AccountSetup(formData)
      .then(async res => {
        isLoading(false)

        let user = await localStorage.getItem("user")
        user = JSON.parse(user)
        user.athlete = res.data
        let redirectLink = "instructions"
        await localStorage.setItem("stage", redirectLink)
        window.location.href = `/${redirectLink}`

        dispatch({ type: "USER_SETUP_COMPLETE", payload: user })
        localStorage.setItem("user", JSON.stringify(user))
      })
      .catch(function (error) {
        isLoading(false)
        catchErrorHandler(error)
        throw error
      })
  }

  const catchErrorHandler = error => {
    if (error.response) {
      const res = error.response.data
      let message = ""
      for (const [key, value] of Object.entries(res)) {
        message = message + `${key} : ${JSON.stringify(value)} `
      }
      errorSwal("oops", message)
    } else {
      errorSwal("oops", error)
    }
  }

  return {
    inputValue,
    setInputValue,
    errorValue,
    setErrorValue,
    onSubmit
  }
}
export default useHook
