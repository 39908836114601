import React, { useEffect, useRef } from "react"
import { Button, Modal } from "react-bootstrap"

const Report = ({ show, handleClose, onChange, submitReport }) => {
  const wrapperRef = useRef()

  useEffect(() => {
    if (show) {
      const handleClickOutside = event => {
        if (!wrapperRef.current.contains(event.target)) {
          handleClose(false)
        }
      }

      document.addEventListener("click", handleClickOutside)
      return () => {
        document.removeEventListener("click", handleClickOutside)
      }
    }
  }, [show, handleClose])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ paddingTop: "0px" }}>
        <div className="modal__body1">
          <div className="m-log" style={{ marginBottom: "1.5rem" }}>
            Report
          </div>
          <textarea
            placeholder="Type report message here"
            className="teaxtarea_style"
            onChange={onChange}
          />
          <Button
            className="modal_btn_style"
            type="submit"
            onClick={submitReport}
          >
            Send
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Report
