/* eslint-disable prettier/prettier */
import Title from "../title"
import useHook from "../useHook"
import React from "react"

const MatchHeader = () => {
  const { page_position_store, updatePagePosition } = useHook()

  const buttonsList = ["Bank", "Campaigns"]

  return (
    <>
      <Title headerTitle={"Match"} />
      <div className="header_btns_div">
        {buttonsList.map((item, index) => {
          return (
            <button
              className={page_position_store === item ? "darkbtn" : "lightbtn"}
              key={index}
              onClick={() => {
                updatePagePosition(item)
              }}
            >
              {item}
            </button>
          )
        })}
      </div>
    </>
  )
}

export default MatchHeader
