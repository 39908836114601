import { history } from "../../../_helpers"
import { profileServices } from "../../../_services"
import { errorSwal, succesSwal } from "../../../sharedComponents/swal/Swal"
import React, { useEffect } from "react"

const TwitterCallBack = () => {
  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    })

    const payload = {
      access_token: params.oauth_token,
      token_secret: params.oauth_token_secret
    }

    profileServices
      .connectTwitter(payload)
      .then(res => {
        succesSwal("success", "connected successfully")
        history.push("/home/profile")
      })
      .catch(function (error) {
        history.push("/home/profile")
        errorSwal(
          "oops",
          JSON.stringify(error.response ? error.response.data : error)
        )
        throw error
      })
  }, [])

  return <div />
}
export default TwitterCallBack
