/* eslint-disable prettier/prettier */
import { min_devices, max_devices } from "@utils/constants"
import styled from "styled-components"

export const HomeWrapper = styled.div`
  color: white;
  text-align: center;
  font-size: 34px;
  font-weight: 600;
  text-transform: capitalize;
  align-items: center;
`

export const SearchContainer = styled.div`
  width: 200px;
  height: 33px;
  border: 1px solid rgba(209, 242, 255, 0.2);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  margin: 0px 20px;

  .search_input {
    width: 120px;
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(177, 177, 177, 1);
    background-color: #101217;
    border: none;
    outline: none;
  }

  @media ${max_devices.laptop} {
    width: 100%;
    margin: 10px 0;
  }

  @media ${min_devices.laptop} {
    position: absolute;
    right: 50px;
  }
`
