import React, { useState } from "react"
import { Link } from "react-router-dom"

const UserType = props => {
  const usersList = [
    { name: "Athlete", value: "athlete" },
    { name: "Company", value: "company" }
  ]
  const [userType, setUserType] = useState("athlete")

  const onCLickContinue = e => {
    e.preventDefault()
    props.userType("signup", userType)
  }

  const handleChange = event => {
    const { value } = event.target
    setUserType(value)
  }

  return (
    <form onSubmit={onCLickContinue}>
      <div className="form-floating mb-3">
        <select
          className="form-select"
          name="usertype"
          id="users"
          value={userType}
          onChange={e => handleChange(e)}
        >
          {usersList.map(({ name, value }, index) => (
            <option key={index} value={value}>
              {name}
            </option>
          ))}
        </select>
        <label htmlFor="users">Choose User Type</label>
      </div>
      <input type="submit" value="Continue" />
      <div className="login_text">
        Already have an account?
        <Link from="/login" to="/login">
          Sign in
        </Link>
      </div>
    </form>
  )
}
export default UserType
