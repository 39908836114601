/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import Title from "../title"
import React from "react"

const HeaderPage = () => {
  return (
    <>
      <Title
        headerTitle="Subscriptions"
        isBack="/settings"
        subTitle="You can only subscribe to one package at a time."
      />
    </>
  )
}

export default HeaderPage
