import { alertConstants } from "../_constants"

export const alertActions = {
  sidebar_show,
  sidebar_hide,
  loading
}

function sidebar_show() {
  return { type: alertConstants.SIDEBAR_SHOW }
}

function sidebar_hide() {
  return { type: alertConstants.SIDEBAR_HIDE }
}

function loading(message) {
  return { type: alertConstants.LOADING, message }
}
