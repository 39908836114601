/* eslint-disable prettier/prettier */
import MyDeals from "./my-deals"
import PersonalDetails from "./personal-details"
import useHome from "@containers/screens/home/useHook"
import React from "react"
import { useSelector } from "react-redux"

const Profile = () => {
  const { page_position } = useSelector(state => state.homeReducer)
  const isDeal = page_position === "deals"

  const homeHook = useHome()

  React.useEffect(() => {
    homeHook.updateHeader("profile")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="inner_comp_div">
      {!isDeal ? <PersonalDetails /> : <MyDeals />}
    </div>
  )
}

export default Profile
