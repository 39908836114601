import { userActions } from "../../../_actions"
import {
  emailValidator,
  passwordValidator,
  confirmPasswordValidator
} from "../../../_helpers/validators/Validators"
import arrowleft from "../../../assets/images/icons/arrow left.png"
import hidden from "../../../assets/svgs/Hidden.svg"
// import { authentication } from '../../../_reducers/authentication.reducer';
import UserInput from "../../../sharedComponents/userInput"
import Footer from "../../Layout/Footer"
import LeftSideBar from "../../Layout/guestSideBar"
import UserType from "./userType"
import React from "react"
import { connect } from "react-redux"

class RegisterPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {
        email: "",
        password: "",
        confirmPassword: "",
        user_type: "athlete"
      },
      show_hide: false,
      show_hide2: false,
      screenType: "user"
    }
    if (localStorage.getItem("token")) props.history.push("/home")
  }

  changeShowHide = () => {
    const hideShow = this.state.show_hide
    if (hideShow) {
      this.setState({ show_hide: false })
    } else {
      this.setState({ show_hide: true })
    }
  }

  changeShowHide2 = () => {
    const hideShow = this.state.show_hide2
    if (hideShow) {
      this.setState({ show_hide2: false })
    } else {
      this.setState({ show_hide2: true })
    }
  }

  handleChange = event => {
    const { name, value } = event.target
    const { user } = this.state
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    })
  }

  handleUserType = (type, value) => {
    const { user } = this.state
    this.setState({
      ...this.state,
      screenType: type,
      user: {
        ...user,
        user_type: value
      }
    })
  }

  onSubmitSignup = async event => {
    event.preventDefault()
    const { user } = this.state
    let errorStatus = false

    let emailvalidator = await emailValidator(user.email)
    let passwordvalidator = await passwordValidator(user.password)
    let confirmPassValidator = await confirmPasswordValidator(
      user.password,
      user.confirmPassword
    )

    if (emailvalidator) {
      this.setState({ emailError: emailvalidator })
      errorStatus = true
    }
    if (passwordvalidator) {
      this.setState({ passwordError: passwordvalidator })
      errorStatus = true
    }
    if (confirmPassValidator) {
      this.setState({ confirmPasswordError: confirmPassValidator })
      errorStatus = true
    }

    if (!errorStatus) {
      await this.props.signup(user)
      await localStorage.setItem("stage", "set-up-account")
      this.props.history.push("/set-up-account")
    }
  }

  render() {
    // const { registering  } = this.props;
    const { user, screenType } = this.state
    return (
      <div className="mainDiv">
        <LeftSideBar />
        <div className="sign-in">
          <span style={{ display: "flex" }}>
            {screenType !== "user" && (
              <img
                className="back_arrow"
                src={arrowleft}
                alt=" "
                onClick={() => this.setState({ screenType: "user" })}
              />
            )}
            <h1>Sign up</h1>
          </span>

          {screenType === "user" ? (
            <UserType userType={this.handleUserType} />
          ) : (
            <form onSubmit={this.onSubmitSignup}>
              <UserInput
                label={"Email"}
                id="emailInput"
                name="email"
                error={this.state.emailError}
                value={user.email}
                onChange={e => {
                  this.handleChange(e)
                  this.setState({ emailError: "" })
                }}
              />

              <UserInput
                label={"Password"}
                id="passInput"
                name="password"
                maxLength="30"
                onBlur={() =>
                  this.setState({
                    passwordError: passwordValidator(user.password)
                  })
                }
                type={this.state.show_hide ? "text" : "password"}
                error={this.state.passwordError}
                value={user.password}
                onChange={e => {
                  this.handleChange(e)
                  this.setState({ passwordError: "" })
                }}
                icon={
                  this.state.show_hide ? (
                    <i
                      className={"hide-show fa fa-eye"}
                      onClick={() =>
                        this.setState({ show_hide: !this.state.show_hide })
                      }
                    />
                  ) : (
                    <img
                      onClick={() =>
                        this.setState({ show_hide: !this.state.show_hide })
                      }
                      src={hidden}
                      className=" hide-show"
                      alt=" "
                    />
                  )
                }
              />

              <UserInput
                label={"Confirm Password"}
                id="confirmPassInput"
                name="confirmPassword"
                maxLength="30"
                type={this.state.show_hide2 ? "text" : "password"}
                error={this.state.confirmPasswordError}
                value={this.state.user.confirmPassword}
                onChange={e => {
                  this.handleChange(e)
                  this.setState({ confirmPasswordError: "" })
                }}
                icon={
                  this.state.show_hide2 ? (
                    <i
                      className={"hide-show fa fa-eye"}
                      onClick={() =>
                        this.setState({ show_hide2: !this.state.show_hide2 })
                      }
                    />
                  ) : (
                    <img
                      onClick={() =>
                        this.setState({ show_hide2: !this.state.show_hide2 })
                      }
                      src={hidden}
                      className=" hide-show"
                      alt=" "
                    />
                  )
                }
              />
              <input className="my-3" type="submit" value="Sign up" />
            </form>
          )}
          <Footer />
        </div>
      </div>
    )
  }
}

function mapState(state) {
  const { authentication } = state
  return { authentication }
}

const actionCreators = {
  signup: userActions.signup
}

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage)
export { connectedRegisterPage as RegisterPage }
