/* eslint-disable prettier/prettier */
import { athleteService, sharedService } from "@src/_services"
import { errorSwal, succesSwal } from "@src/sharedComponents/swal/Swal"
import { useDispatch, useSelector } from "react-redux"

const useHook = () => {
  const dispatch = useDispatch()

  const { balance } = useSelector(state => state.sharedReducer)
  //   const { companyList } = useSelector(state => state.athleteReducer)

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const getDealsList = async () => {
    isLoading(true)
    await athleteService
      .getDealsList()
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch(success(res.data))
        }
      })
      .catch(function (error) {
        isLoading(false)
        throw error
      })

    function success(data) {
      return { type: "DEALS", payload: data }
    }
  }

  const addDeal = async (payload, callback) => {
    isLoading(true)
    await athleteService
      .addDeal(payload)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          console.log(message)
          errorSwal("oops", message)
        } else {
          succesSwal("Thanks you", "Campaign added successfully")
          getDealsList()
          callback()
        }
      })
      .catch(function (error) {
        isLoading(false)

        errorSwal("oops", JSON.stringify(error))
        console.log("error", error)
        throw error
      })
  }

  const updateDeal = async data => {
    isLoading(true)

    const payload = {
      title: data.title,
      description: data.description
    }
    await athleteService
      .updateDeal(payload, data.id)
      .then(async () => {
        isLoading(false)
        getDealsList()
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })
  }

  const deleteDeal = async payload => {
    isLoading(true)
    await athleteService
      .deleteDeal(payload)
      .then(async res => {
        isLoading(false)
        getDealsList()
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
        // throw error
      })
  }

  const searchCompany = async query => {
    isLoading(true)
    return await sharedService
      .apiCall(query)
      .then(async res => {
        isLoading(false)
        return res.data
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })
  }

  return {
    getDealsList,
    addDeal,
    updateDeal,
    deleteDeal,
    searchCompany,
    balance
  }
}
export default useHook
