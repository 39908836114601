/* eslint-disable prettier/prettier */
import AtheleteBank from "./bank"
import AtheleteCompaigns from "./compaigns"
import useHome from "@containers/screens/home/useHook"
import React from "react"
import { useSelector } from "react-redux"

const Profile = () => {
  const page_position_store = useSelector(
    state => state.homeReducer.page_position
  )

  const isBank = page_position_store === "Bank"

  const homeHook = useHome()

  React.useEffect(() => {
    homeHook.updateHeader("home")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const props = {
    pagename: "home"
  }

  return (
    <div className="inner_comp_div">
      {isBank ? (
        <AtheleteBank props={props} />
      ) : (
        <AtheleteCompaigns props={props} />
      )}
    </div>
  )
}

export default Profile
