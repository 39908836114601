import "./style.css"
import React from "react"

const SwitShButton = () => {
  return (
    <label className="switch">
      <input type="checkbox" />
      <span className="slider round"></span>
    </label>
  )
}

export default SwitShButton
