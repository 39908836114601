// import { userActions } from '../../../_actions';
import { history } from "../../../_helpers"
import IG_small from "../../../assets/images/social-icons/IG-small.png"
import instagramimg from "../../../assets/svgs/instagram.svg"
import { INSTAGRAM_OAUTH } from "../../../utils/social_data"
import Footer from "../../Layout/Footer"
import LeftSideBar from "../../Layout/guestSideBar"
import React, { useEffect } from "react"

// import { useSelector } from 'react-redux';

const SocialPage = props => {
  // const { authentication } = useSelector(state => state.authentication);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("stage") !== "social-setup") {
        history.push("/home")
      }
    } else {
      history.push("/home")
    }
  }, [])

  const onSubmitForm = async e => {
    e.preventDefault()
    window.location.href = INSTAGRAM_OAUTH("instagram")
  }

  return (
    <div className="mainDiv">
      <LeftSideBar />
      <div className="sign-in">
        <h1>Set up account</h1>
        <form onSubmit={onSubmitForm}>
          <div className="mute-text">
            In order for companies to offer you contracts, connect your
            Instagram account
          </div>
          <span className="d-flex justify-content-center">
            <img width={124} height={124} src={instagramimg} alt=" " />
          </span>
          <button className="my-3" type="submit">
            <img style={{ paddingRight: "10px" }} src={IG_small} alt=" " />
            Connect your Instagram
          </button>
        </form>
        <Footer />
      </div>
    </div>
  )
}
export default SocialPage
