import { authHeader } from "../_helpers"
import { siteUrls } from "../_helpers"
import { history } from "../_helpers"
import axios from "axios"

export const request = axios.create({
  baseURL: siteUrls.BASE_URL
})
export const userService = {
  login,
  logout,
  signup,
  resetPassword,
  delete: _delete
}

function login(payload) {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  }

  return fetch(`${siteUrls.BASE_URL}accounts/login/token/`, requestOptions)
}

function logout() {
  localStorage.clear()
  history.push("/")
}

function signup(payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload)
  }

  return fetch(`${siteUrls.BASE_URL}accounts/signup/`, requestOptions)
}

function resetPassword(payload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload)
  }

  return fetch(`${siteUrls.BASE_URL}accounts/password/reset/`, requestOptions)
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  }

  return fetch(`/users/${id}`, requestOptions)
}

// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 logout();
//                 window.location.reload(true);
//             }

//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }

//         return data;
//     });
// }
