/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import useHook from "../../useHook"
import { Wrapper, CardsWrapper, LikesWrapper, CardContainer } from "./styles"
import rightArrow from "@src/assets/images/angle-right.png"
import imgImg from "@src/assets/images/icons/imgImg.png"
import del from "@svgs/del.svg"
import edit from "@svgs/edit.svg"
import like from "@svgs/like_white.svg"
import { getAge } from "@utils/helpers"
import { validateURL } from "@utils/helpers"
import React, { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { FiPlus } from "react-icons/fi"
import { useSelector } from "react-redux"

const CompanyCompaign = () => {
  const {
    getMyCampaignsList,
    getCampaignLikesList,
    likesList,
    getAthleteDetail,
    history,
    newCampaigns,
    deleteCompaign,
    reset,
    updateCampaign
  } = useHook()

  const [userInfo, setUserInfo] = useState()
  const [show, setShow] = useState(false)
  const [showDelete, setShowDelete] = useState({ show: false })
  const [showEdit, setShowEdit] = useState({ show: false })
  const [likesUsers, setLikesUsers] = useState()
  const [showLikes, setShowLikes] = useState(false)

  const { campaignNo, campaingsList } = useSelector(
    state => state.companyReducer
  )

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  
  useEffect(() => {
    getMyCampaignsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (campaignNo - campaingsList?.lenght?.lenght - 2) {
      reset()
      getMyCampaignsList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignNo])

  useEffect(() => {
    setLikesUsers(likesList)
    setLikesUsers({ ...likesUsers, likesList: likesList.results })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likesList])
  const updateCampaing = async () => {
    updateCampaign(showEdit)
    setShowEdit({ show: false })
  }

  const Compaign = () => {
    const data = campaingsList.results
    if (!data) return <div>No Data</div>

    const showLikesList = campaign => {
      getCampaignLikesList(campaign.id)
      setLikesUsers({ ...likesUsers, campaign })
      setShowLikes(true)
    }

    return (
      <>
        {data?.map((item, index) => {
          const { title, company, image, description, total_athlete_likes } =
            item
          const { company_logo } = company
          return (
            <CardContainer key={index}>
              <div
                className="card-header"
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ display: "flex" }}>
                  <img src={company_logo} className="logo-img" />

                  <div className="card-title">
                    <strong>{title}</strong>
                    <span
                      onClick={() =>
                        total_athlete_likes > 0 ? showLikesList(item) : null
                      }
                    >
                      <img src={like} style={{ marginRight: "10px" }} />
                      {total_athlete_likes} likes
                    </span>
                  </div>
                </div>
                <div>
                  <img
                    src={edit}
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setShowEdit({ ...showEdit, show: true, ...item })
                    }}
                  />
                  <img
                    src={del}
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setShowDelete({ ...showDelete, show: true, ...item })
                    }}
                  />
                </div>
              </div>
              <img src={image} className="main-image" />
              <div className="back_card_body">
                <p className="bank_para_div" style={{ textAlign: "center" }}>
                  {description}
                </p>
              </div>
            </CardContainer>
          )
        })}
      </>
    )
  }

  const submitForm = async () => {
    let formData = new FormData()

    for (const [key, value] of Object.entries(userInfo)) {
      if (key === "link" && !validateURL(value)) {
        formData.append(key, `https://${value}`)
      } else {
        formData.append(key, value)
      }
    }
    newCampaigns(formData, handleClose)
  }

  const data = campaingsList.results
  if (!data) return <div>No Data</div>
  return (
    <Wrapper>
      <CardsWrapper show={showLikes}>
        <div className="add-button" onClick={handleShow}>
          <FiPlus />
        </div>
        <Compaign data={campaingsList} />
      </CardsWrapper>
      <LikesWrapper show={showLikes}>
        <div className="compaign_chat_outer">
          <div className="comaign_likes">
            <span className="nike_head">{likesUsers?.campaign?.title}</span>
            <span
              className="sub_head"
              style={{ color: "rgba(255, 255, 255, 0.5)", marginRight: "15px" }}
            >
              {likesUsers?.campaign?.total_athlete_likes} likes
            </span>
          </div>
          {likesUsers?.likesList?.map((item, index) => {
            const { id, date_of_birth, gender } = item?.athlete
            const age = getAge(date_of_birth)
            const { profile_picture, first_name, last_name } =
              item?.athlete.user
            return (
              <div
                className="compaign_main_div"
                key={index}
                onClick={() => {
                  history.push("home/profileDetail")
                  getAthleteDetail(id)
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="comp_chat_logo_div">
                    <img src={profile_picture} className="img-user" />
                  </div>
                  <div className="head_content_div">
                    <span
                      style={{ fontWeight: 400, fontSize: "14px" }}
                    >{`${first_name} ${last_name}`}</span>
                    <span
                      style={{ fontWeight: 400, fontSize: "12px" }}
                    >{`${gender}, ${age} years`}</span>
                  </div>
                </div>
                <div>
                  <img src={rightArrow} alt="" />
                </div>
              </div>
            )
          })}
        </div>
      </LikesWrapper>

      {/* Add Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div className="modal__body1">
            <div className="addcompaignhead">Add new Campaign</div>
            <div className="inputdivs">
              <input
                style={{ color: "white" }}
                placeholder="Title"
                className="modalinput"
                name="title"
                onChange={event => {
                  const { name, value } = event.target
                  setUserInfo({ ...userInfo, [name]: value })
                }}
              />
              <input
                style={{ color: "white" }}
                placeholder="Description"
                className="modalinput"
                name="description"
                onChange={event => {
                  const { name, value } = event.target
                  setUserInfo({ ...userInfo, [name]: value })
                }}
              />
              <input
                placeholder="Link"
                className="modalinput"
                name="link"
                onChange={event => {
                  const { name, value } = event.target
                  setUserInfo({ ...userInfo, [name]: value })
                }}
                style={{ color: "#fff" }}
              />
            </div>
            <div className="image-upload-wrap" style={{ marginBottom: "2rem" }}>
              <input
                className="file-upload-input"
                type="file"
                id="fileControl"
                accept="image/*"
                name="image"
                onChange={event => {
                  // const { name, value } = event.target.files[0];
                  setUserInfo({ ...userInfo, image: event.target.files[0] })
                }}
              />
              {userInfo && userInfo?.image ? (
                <img
                  src={URL.createObjectURL(userInfo.image)}
                  style={{
                    height: "162px",
                    width: "100%",
                    borderRadius: "24px"
                  }}
                />
              ) : (
                <>
                  <img src={imgImg} style={{ margin: "1rem 0" }} />
                  <label htmlFor="fileControl" className="drag-text">
                    Upload Image
                  </label>
                </>
              )}
            </div>
            <Button
              className="modal_btn_style"
              type="submit"
              onClick={submitForm}
            >
              Save
            </Button>
            <Button className="modalcancel" onClick={() => handleClose()}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={showDelete.show}
        onHide={() => setShowDelete({ ...showDelete, show: false })}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div className="modal__body1">
            <div className="addcompaignhead">Delete Campaign</div>
            <div className="inputdivs">
              Are you sure you want to delete this "Campaign"?
            </div>
            <Button
              className="modalcancel"
              onClick={() => setShowDelete({ ...showDelete, show: false })}
            >
              No
            </Button>
            <Button
              className="modal_btn_style"
              type="submit"
              onClick={() => {
                deleteCompaign(showDelete.id)
                setShowDelete({ ...showDelete, show: false })
              }}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Edit Modal */}
      <Modal
        show={showEdit && showEdit.show}
        onHide={() => setShowEdit({ show: false })}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div className="modal__body1">
            <div className="addcompaignhead">Edit Campaign</div>
            <div className="inputdivs">
              <input
                style={{ color: "white" }}
                placeholder="Tile"
                className="modalinput"
                value={showEdit.title}
                name="title"
                onChange={event => {
                  const { name, value } = event.target
                  setShowEdit({ ...showEdit, [name]: value })
                }}
              />

              <input
                style={{ color: "white" }}
                placeholder="Description"
                className="modalinput"
                value={showEdit.description}
                name="description"
                onChange={event => {
                  const { name, value } = event.target
                  setShowEdit({ ...showEdit, [name]: value })
                }}
              />
            </div>
            <Button
              className="modalcancel"
              onClick={() => setShowEdit({ show: false })}
            >
              No
            </Button>
            <Button
              className="modal_btn_style"
              type="submit"
              onClick={() => updateCampaing()}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}

export default CompanyCompaign
