/* eslint-disable prettier/prettier */

/* eslint-disable no-unused-expressions */

/* eslint-disable jsx-a11y/alt-text */
import useHook from "../../useHook"
import profileHook from "../useHook"
import { Wrapper } from "./styles"
import { profileServices } from "@src/_services"
import rightArrow from "@src/assets/images/angle-right.png"
import "@src/container/Layout/Dashboard/profile.css"
import InputFiels from "@src/sharedComponents/InputFields"
import FileUpload from "@src/sharedComponents/fileupload/FileUpload"
import { COUNTRIES, US_STATES, CAN_STATES, GENDER } from "@src/utils/data"
import { formatCurreny } from "@src/utils/helpers"
// import dislike from '@svgs/dislike.svg';
import instagram from "@svgs/instagram-small.svg"
// import like from '@svgs/like.svg';
import React, { useState, useEffect, Fragment } from "react"
import { Link } from "react-router-dom"

const USER_DEFAULT = {
  first_name: "",
  last_name: "",
  date_of_birth: "",
  gender: "male",
  phone_number: "",
  country: "US",
  state: "Alabama",
  school: "",
  bio: "",
  profile_picture: ""
}

const Profile = () => {
  const { isAthlete, user, history, profileUpdate } = useHook()
  const { getBalance, balance } = profileHook()

  const [userInfo, setUserInfo] = useState(USER_DEFAULT)
  const [errorList, setErrorList] = useState({})

  const [editable, setEditable] = useState(false)
  const [IGdetails, setIGdetails] = useState()

  useEffect(() => {
    getSocialDetails()
    getBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const FIELDS_LIST = [
    {
      label: "First Name",
      id: "first_name",
      name: "first_name",
      type: "input"
    },
    {
      label: "Last Name",
      name: "last_name",
      id: "last_name",
      type: "input"
    },
    {
      label: "date of birth",
      id: "date_of_birth",
      name: "date_of_birth",
      type: "date"
    },
    {
      label: "Gender",
      id: "gender",
      name: "gender",
      list: GENDER,
      type: "dropdown"
    },
    {
      label: "Country",
      name: "country",
      type: "dropdown",
      id: "countryType",
      list: COUNTRIES
    },
    {
      label: "State",
      name: "state",
      type: "dropdown",
      id: "stateType",
      list: userInfo?.country !== "US" ? CAN_STATES : US_STATES
    },
    {
      label: "City",
      name: "city",
      id: "city"
    },
    {
      label: "school",
      id: "school",
      name: "school",
      type: "input"
    },
    {
      label: "Business URL",
      id: "business_url",
      name: "business_url",
      type: "input"
    },
    {
      label: "Address",
      id: "address_line1",
      name: "address_line1",
      type: "input"
    },
    {
      label: "SSN Last 4 digits",
      id: "ssn_last_4",
      name: "ssn_last_4",
      type: "input"
    },
    {
      label: "Postal Code",
      id: "postal_code",
      name: "postal_code",
      type: "input"
    },

    {
      label: "Phone Number",
      id: "phone_number",
      name: "phone_number",
      type: "input"
    },
    {
      label: "Bio",
      id: "bio",
      name: "bio",
      type: "input"
    }
  ]

  useEffect(() => {
    const {
      athlete,
      first_name,
      last_name,
      profile_picture,
      gender,
      country,
      state
    } = user

    const {
      business_url,
      first_name: at_first_name,
      last_name: at_last_name,
      profile_picture: at_profile_picture,
      gender: at_gender,
      country: at_country,

      state: at_state,
      date_of_birth,
      phone_number,
      bio,
      city,
      postal_code,
      address_line1,
      ssn_last_4,
      school
    } = athlete || {}

    const userValues = {
      first_name: first_name || at_first_name,
      profile_picture: profile_picture || at_profile_picture,
      last_name: last_name || at_last_name,
      gender: gender || at_gender,
      country: country || at_country,
      state: state || at_state,

      school: school || "",
      date_of_birth,
      phone_number,
      business_url,
      address_line1,
      postal_code,
      ssn_last_4,
      city,
      bio
    }

    setUserInfo(userValues)
  }, [user])

  const getSocialDetails = async () => {
    const IGdetails = await profileServices.checkInstagramConnect()

    if (IGdetails) {
      if (typeof IGdetails.extra_data === "string") {
        let details
        try {
          const detailsReplace = IGdetails.extra_data.replace(/[']+/g, '"')
          details = JSON.parse(detailsReplace)
        } catch (error) {
          details = {}
        }
        setIGdetails(details)
      }
    }
  }

  const inputHandler = e => {
    const { name, value } = e.target
    setErrorList({ ...errorList, [name]: "" })
    setUserInfo({ ...userInfo, [name]: value })
  }

  const onSubmitSignup = async event => {
    event.preventDefault()

    const requiredFields = {
      profile_picture: "Upload profile picture",
      first_name: "first name required",
      last_name: "Last Name required",
      date_of_birth: "Invalid date of birth",
      gender: "Geneder required",
      country: "Invalid country",
      state: "Invalid state",
      city: "Invalid city",
      school: "Invalid School",
      business_url: "Business Url is required",
      address_line1: "Invalid Address",
      ssn_last_4: "Please provide last four digits or you SSN",
      postal_code: "Invalid postal code",
      phone_number: "Invalid Phone Number",
      bio: "Invalid bio"
    }

    let errors = {}
    let errorStatus = false
    for (const [key, value] of Object.entries(requiredFields)) {
      if (!!!userInfo[key]) {
        errors[key] = value
        errorStatus = true
      }
    }
    setErrorList(errors)
    if (!errorStatus) {
      callSetupApi()
    }
  }

  const callSetupApi = async () => {
    const { user_type } = user
    const userType = user_type
    let formData = new FormData()

    for (const [key, value] of Object.entries(userInfo)) {
      if (
        key === "first_name" ||
        key === "last_name" ||
        key === "profile_picture"
      ) {
        if (key === "profile_picture") {
          if (typeof value === "object") {
            formData.append(key, value)
          }
        } else {
          formData.append(key, value)
        }
      } else if (key === "business_url") {
        const url = value.replace("https://", "")
        formData.append(`${userType}.${key}`, `https://${url}`)
      } else formData.append(`${userType}.${key}`, value)
    }
    profileUpdate({ formData, userType })
    setEditable(false)
  }

  const balanceAmount =
    balance?.pending?.[0]?.amount + balance?.available?.[0]?.amount

  return (
    <Wrapper>
      <div className="profile-card">
        <form className="profile_form" onSubmit={onSubmitSignup}>
          <FileUpload
            isAthlete={isAthlete}
            onselectImage={img => {
              setUserInfo({ ...userInfo, profile_picture: img })
            }}
            fileName={userInfo.profile_picture}
          />
          {/* profile buttons */}
          <div className="profile_social text-white mt-2">
            <div className="social-left">
              <img className="mr-3" src={instagram} alt=" " />
              <div className="media-body">
                <h5 className="my-0">
                  {IGdetails ? IGdetails.username : "Instagram"}
                </h5>
                {IGdetails ? <p className="mb-0">Instagram</p> : ""}
              </div>
            </div>
            <h6
              className="my-0"
              onClick={() =>
                history.push("socials", {
                  from: "/home/profile",
                  show: true
                })
              }
            >
              {IGdetails ? "Unlink" : "Link"}
            </h6>
          </div>
          <div
            className="social-button mt-2 mb-4"
            onClick={() =>
              history.push("/home/socials", {
                from: "profile",
                show: true
              })
            }
          >
            Link Social Media Platforms
          </div>
          <h5 className="text-white ">My Wallet</h5>
          <Link
            to="/home/wallet"
            style={{ width: "100%", textDecoration: "none" }}
          >
            <div className="profile_social">
              <div className="wallet-left text-white">
                <p className="text-muted">Balance</p>
                <h5 className="my-0">
                  {formatCurreny(balanceAmount / 100 || 0)}
                  <span
                    style={{ fontSize: "16px", paddingLeft: "5px" }}
                    className="text-muted my-0 ml-1"
                  >
                    USD
                  </span>
                </h5>
              </div>
              <span className="option_icon">
                <img src={rightArrow} alt="" className="option_img" />
              </span>
            </div>
          </Link>
          {/* profile edit row */}
          <div className="d-flex justify-content-between text-white mt-3">
            <h5 className="mb-0">Personal details {!editable}</h5>
            <p
              className="my-0 cursor_default"
              onClick={() => setEditable(!editable)}
            >
              Edit
            </p>
          </div>
          <Fragment>
            <InputFiels
              FIELDS_LIST={FIELDS_LIST}
              inputHandler={inputHandler}
              setErrorList={setErrorList}
              errorList={errorList}
              setUserInput={setUserInfo}
              userInput={userInfo}
              editable={editable}
            />
          </Fragment>
          <input
            className={`mt-3 ${!editable ? "d-none" : ""}`}
            type="submit"
            value="Save"
          />
        </form>
      </div>
    </Wrapper>
  )
}

export default Profile
