/* eslint-disable prettier/prettier */
import Title from "../title"
import useHook from "../useHook"
import React from "react"

const HeaderPage = () => {
  const { page_position_store, updatePagePosition, profile, athleteDetail } =
    useHook()
  const { headerName } = profile

  React.useEffect(() => {
    updatePagePosition("personalDetails")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!athleteDetail.user) {
    return <div />
  }
  const { first_name, last_name } = athleteDetail?.user

  const atheleteName = `${first_name} ${last_name}`

  const buttonsList = [
    { value: "Personal details", key: "personalDetails" },
    { value: "Athlete deals", key: "deals" }
  ]

  return (
    <>
      <Title
        headerTitle={atheleteName || headerName}
        isBack="home/chat"
        subTitle="Athlete"
      />
      <div className="header_btns_div">
        {buttonsList.map((item, index) => {
          return (
            <button
              className={
                page_position_store === item.key ? "darkbtn" : "lightbtn"
              }
              key={index}
              onClick={() => {
                updatePagePosition(item.key)
              }}
            >
              {item.value}
            </button>
          )
        })}
      </div>
    </>
  )
}

export default HeaderPage
