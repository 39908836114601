import { SaveButton } from "./style"
import useHook from "./useHook"
import InputFields from "@src/sharedComponents/InputFields"
import {
  BUSSINESS_TYPES,
  BUSINESS_STRUCTURE_CHOICES,
  INDUSTRIES,
  COUNTRIES,
  US_STATES,
  CAN_STATES
} from "@src/utils/data"
import React from "react"

const Company = ({ companyLogo, editable, setEditable }) => {
  const {
    errorValue,
    setErrorValue,
    inputHandler,
    onSubmitCompany,
    inputValue,
    setValue,
    mccList
  } = useHook("company", setEditable, companyLogo)

  const FIELDS_LIST = [
    {
      label: "Company Name *",
      id: "companyName",
      name: "name"
    },
    {
      label: "Company Email *",
      id: "companyEmail",
      name: "company_email"
    },
    {
      label: "Website URL *",
      id: "website",
      name: "website_url"
    },
    {
      label: "Business Type *",
      name: "business_type",
      id: "business_type",
      list: BUSSINESS_TYPES,
      type: "dropdown"
    },
    {
      label: "Business Structure *",
      name: "business_structure",
      id: "business_structure",
      list: BUSINESS_STRUCTURE_CHOICES,
      type: "dropdown"
    },
    {
      label: "Phone Number *",
      id: "phoneNum",
      name: "phone_number"
    },
    {
      label: "Country *",
      name: "country",
      type: "dropdown",
      id: "countryType",
      list: COUNTRIES
    },
    {
      label: "State *",
      name: "state",
      type: "dropdown",
      id: "stateType",
      list: inputValue?.country === "US" ? US_STATES : CAN_STATES
    },
    {
      label: "City *",
      id: "city",
      name: "city"
    },
    {
      label: "Street *",
      id: "address_line1",
      name: "address_line1"
    },
    {
      label: "Address *",
      id: "address_line2",
      name: "address_line2"
    },
    {
      label: "Postal Code *",
      id: "postal_code",
      name: "postal_code",
      type: "number"
    },
    {
      label: "Merchant Category Code *",
      name: "business_mcc",
      id: "business_mcc",
      list: mccList,
      type: "dropdown"
    },
    {
      label: "Headquarter *",
      id: "headquarter",
      name: "headquarter"
    },
    {
      label: "Industry *",
      name: "industry",
      id: "industryType",
      list: INDUSTRIES,
      type: "dropdown"
    },
    {
      label: "About Company *",
      id: "about",
      name: "about"
    },
    {
      label: "Company Tax ID *",
      id: "tax_id",
      name: "tax_id",
      type: "number"
    }
  ]

  return (
    <>
      <InputFields
        FIELDS_LIST={FIELDS_LIST}
        inputHandler={inputHandler}
        setErrorList={setErrorValue}
        errorList={errorValue}
        setUserInput={setValue}
        userInput={inputValue}
        editable={!editable}
      />
      {!editable && <SaveButton onClick={onSubmitCompany}>Save</SaveButton>}
    </>
  )
}
export default Company
