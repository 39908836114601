/* eslint-disable prettier/prettier */
import styled from "styled-components/macro"

export const Col2 = styled.div`
  width: 50%;
  padding: 1.5rem 0rem 0rem 1rem;
  border-right: 1px solid black;
  max-height: 90vh;
  overflow: auto;
  @media screen and (min-width: 280px) and (max-width: 600px) {
    width: 100%;
    max-height: auto;
  }
`
export const Heading = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 0.5rem;
  color: white;
`
export const MainWrapper = styled.div`
  ${
    "" /* max-height: 80vh;
  overflow-y: auto;
  @media screen and (min-width: 280px) and (max-width: 600px){
    max-height: fit-content;
    overflow-y: hidden;
  } */
  }
`
export const Transaction = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  color: white;
  padding: 10px;

  .info {
    flex: 1;
    flex-direction: column;
    display: flex;
    padding-left: 10px;

    span {
      height: 50%;
    }
  }

  .amount {
    flex-direction: column;
    display: flex;
    text-align: right;

    span {
      height: 50%;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 400;
  }

  .sub-title {
    font-size: 12px;
    font-weight: 400;
    color: #b1b1b1;
  }
`

export const Image = styled.div`
  background: #313131;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonsWrapper = styled.div`
  width: 75%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 3px solid #373738;
  background-color: #373738;
  color: #fff;
  border-radius: 8px;

  li {
    width: 50%;
    padding: 0.2rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-radius: 8px;
  }
  li:nth-child(2) {
    border-radius: 0;
    border-radius: 8px;
  }
  li.active {
    background: #636366;
  }
`
