/* eslint-disable prettier/prettier */
import Title from "./title"
import useHook from "./useHook"
import React from "react"

const HeaderPage = () => {
  const { profile } = useHook()
  const { headerName } = profile

  return (
    <>
      <Title headerTitle={headerName} />
      <div className="header_btns_div"></div>
    </>
  )
}

export default HeaderPage
