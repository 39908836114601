/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import useHook from "../../useHook"
import {
  Wrapper,
  ButtonMain,
  CardsContainer,
  CardsHeader,
  Card,
  PaymentWrapper,
  ModalBody,
  PayCard
} from "../styles"
import {
  ArrowDownSvg,
  CardLogoSvg,
  DeleteSvg,
  SelectedSvg,
  UnselectSvg
} from "@svgs"
import React, { useEffect } from "react"
import { useState } from "react"
import { Modal, Button } from "react-bootstrap"

const WithdrawCard = () => {
  const {
    saveCard,
    getPaymentMethods,
    cardsList,
    deletePaymentMethod,
    addBalance
  } = useHook()

  const [selectDefault, setSelectDefault] = useState({})
  const [newCardInfo, setNewCardInfo] = useState({})

  const [edit, setEdit] = useState(false)
  const [payAmount, setPayAmount] = useState(false)
  const [addNew, setAddNew] = useState(false)

  const onChangeNewCard = e => {
    const { name, value } = e.target
    setNewCardInfo({ ...newCardInfo, [name]: value })
  }

  useEffect(() => {
    getPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (cardsList) {
      async function fetch() {
        const defaultCard = await localStorage.getItem("defaultCard")
        if (defaultCard && defaultCard !== "undefined") {
          setSelectDefault(JSON.parse(defaultCard))
        } else {
          selectCard(cardsList[0])
        }
      }
      fetch()
    }
  }, [cardsList])

  const saveCardInfo = () => {
    saveCard(newCardInfo)
    setAddNew(!addNew)
  }

  const selectCard = card => {
    setSelectDefault(card)
    localStorage.setItem("defaultCard", JSON.stringify(card))
  }

  const addAmount = () => {
    const payload = { amount: payAmount, payment_method: selectDefault?.id }
    addBalance(payload)
  }

  return (
    <Wrapper>
      <CardsContainer>
        <CardsHeader>
          <span className="title">My Cards</span>
          <div className="card-actions">
            <span className="left" onClick={() => setAddNew(!addNew)}>
              Add New
            </span>
            <span onClick={() => setEdit(!edit)}>{edit ? "Done" : "Edit"}</span>
          </div>
        </CardsHeader>

        {cardsList?.map((card, cardIndex) => {
          return (
            <Card key={cardIndex}>
              <div className="title">
                <CardLogoSvg />
                <div>**** {card.card?.last4}</div>
              </div>
              <div>
                {selectDefault?.id === card.id && (
                  <span className="default">Default</span>
                )}

                {edit ? (
                  <DeleteSvg
                    onClick={() => deletePaymentMethod(card.djstripe_id)}
                  />
                ) : selectDefault?.id === card.id ? (
                  <SelectedSvg style={{ width: "18px", height: "18px" }} />
                ) : (
                  <UnselectSvg
                    style={{ width: "18px", height: "18px" }}
                    onClick={() => selectCard(card)}
                  />
                )}
              </div>
            </Card>
          )
        })}
        <PaymentWrapper>
          <div class="payment-amount">Payment Amount</div>
          <div className="payment-row ">
            $
            <input
              type="number"
              name="number"
              placeholder="0.00"
              min="0"
              onChange={e => setPayAmount(e.target.value)}
            />
          </div>
        </PaymentWrapper>
      </CardsContainer>
      <ButtonMain onClick={addAmount}>Deposit</ButtonMain>

      <Modal
        show={addNew}
        onHide={() => setAddNew(!addNew)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <ModalBody>
            <div className="modal-heading">Add New Crad</div>
            <PayCard>
              <div class="top-element">
                <div className="cardno">
                  <div class="card-nmbr">CARD NUMBER</div>
                  <input
                    type="number"
                    name="number"
                    placeholder="4950 4545 9870 4683"
                    class="number"
                    onChange={onChangeNewCard}
                  />
                </div>
                <div style={{ marginTop: "-10px" }}>
                  <CardLogoSvg className="cardlogo" />
                  <ArrowDownSvg />
                </div>
              </div>
              <div class="mid-element-card">
                <div>
                  <div class="card-date">MONTH/YEAR</div>
                  <input
                    type="tel"
                    name="exp_month"
                    maxlength="2"
                    placeholder="12"
                    onChange={onChangeNewCard}
                  />
                  /
                  <input
                    type="tel"
                    name="exp_year"
                    maxlength="4"
                    placeholder="2028"
                    onChange={onChangeNewCard}
                  />
                </div>
                <div class="cvv-div">
                  <div class="cvv-text">CVV</div>
                  <input
                    type="number"
                    name="cvc"
                    placeholder="342"
                    class="cvv-nmbr-input"
                    onChange={onChangeNewCard}
                  />
                </div>
              </div>
              <div class="holder-name-div">
                <div class="holder-name">CARD HOLDER NAME</div>
                <input
                  type="text"
                  name="text"
                  class="input-text"
                  placeholder="JANE DOE"
                  maxlength="15"
                />
              </div>
            </PayCard>
            <Button
              className="modalbtnstyle"
              type="submit"
              style={{ marginBottom: "1rem" }}
              onClick={saveCardInfo}
            >
              Save Card
            </Button>
          </ModalBody>
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}

export default WithdrawCard
