/* eslint-disable prettier/prettier */
import { authHeaderAxios } from "../_helpers"
import { siteUrls } from "../_helpers"
import axios from "axios"

export const request = axios.create({
  baseURL: siteUrls.BASE_URL
})

const AccountSetup = payload => {
  return request.patch("accounts/profile/athlete/setup/", payload, authHeaderAxios())
}

const campainsList = payload => {
  let params = "?"
  if (payload) {
    for (const [key, value] of Object.entries(payload)) {
      params = `${params}${key}=${value}&`
    }
  }
  params = params.slice(0, -1)

  return request.get(`campaigns/all/${params}`, authHeaderAxios())
}

const campaignLikeDislike = (id, payload) => {
  return request.post(`campaigns/all/${id}/like/`, payload, authHeaderAxios())
}

const reportCampaing = payload => {
  return request.post(
    `/reports/campaign-user-reports/`,
    payload,
    authHeaderAxios()
  )
}

const reportCompany = payload => {
  return request.post(
    `/reports/company-user-reports/`,
    payload,
    authHeaderAxios()
  )
}

const companiesList = payload => {
  let api = "companies/all/"
  if (payload) {
    let params = ""
    if (typeof payload === "object") {
      params = "?"
      for (const [key, value] of Object.entries(payload)) {
        params = `${params}${key}=${value}&`
      }
      params = params.slice(0, -1)
    } else {
      params = payload
    }
    api = `companies/all/${params}/`
  }

  return request.get(api, authHeaderAxios())
}

const companyLikeDislike = (id, payload) => {
  return request.post(`companies/all/${id}/like/`, payload, authHeaderAxios())
}

const matchesList = (api) => {
  return request.get(api, authHeaderAxios())
}

const getPlans = () => {
  return request.get(`/subscriptions/plans/`, authHeaderAxios())
}

const getDealsList = () => {
  return request.get(`/deals/my-deal-posts/`, authHeaderAxios())
}

const getUserDeals = query => {
  return request.get(`/deals/deal-posts/?${query}`, authHeaderAxios())
}

const addDeal = payload => {
  return request.post(`/deals/my-deal-posts/`, payload, authHeaderAxios())
}

function updateDeal(payload, id) {
  return request.patch(`/deals/deal-posts/${id}/`, payload, authHeaderAxios())
}

function deleteDeal(id) {
  return request.delete(`/deals/deal-posts/${id}/`, authHeaderAxios())
}

function searchCompany(payload) {
  let api = "matches/matched-companies/"
  if (payload) {
    let params = ""
    if (typeof payload === "object") {
      params = "?"
      for (const [key, value] of Object.entries(payload)) {
        params = `${params}${key}=${value}&`
      }
      params = params.slice(0, -1)
    } else {
      params = payload
    }
    api = `matches/matched-companies/${params}/`
  }

  return request.get(api, authHeaderAxios())
}

const AthleteService = {
  AccountSetup,
  campainsList,
  campaignLikeDislike,
  companiesList,
  companyLikeDislike,
  reportCampaing,
  reportCompany,
  matchesList,
  getPlans,
  // Deals
  addDeal,
  getDealsList,
  getUserDeals,
  updateDeal,
  deleteDeal,
  searchCompany
}

export default AthleteService
