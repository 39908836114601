import { LoginPage } from "@containers/Auth/LoginPage"
import { RegisterPage } from "@containers/Auth/RegisterPage"
import SetupAccount from "@containers/Auth/RegisterPage/SetupAccount"
import { ForgotPassword } from "@containers/Auth/forgot"
import InstructionPage from "@containers/Auth/instruction"
import SocialPage from "@containers/Auth/socialPage"

export {
  LoginPage,
  ForgotPassword,
  RegisterPage,
  SocialPage,
  SetupAccount,
  InstructionPage
}

export const AUTH_ROUTES_LIST = [
  { path: "/login", component: LoginPage },
  { path: "/rest-password", component: ForgotPassword },
  { path: "/signup", component: RegisterPage },
  { path: "/social-setup", component: SocialPage, protected: true },
  { path: "/set-up-account", component: SetupAccount, protected: true },
  { path: "/instructions", component: InstructionPage, protected: true }
]
