/* eslint-disable prettier/prettier */
import { alert } from "./alert.reducer"
import { athleteReducer } from "./athelete.reducer"
import { authentication } from "./authentication.reducer"
import { companyReducer } from "./company.reducer"
import { homeReducer } from "./home.reducer"
import { profile } from "./profile.reducer"
import { registration } from "./registration.reducer"
import { sharedReducer } from "./shared.reducer"
import { combineReducers } from "redux"

const rootReducer = combineReducers({
  authentication,
  athleteReducer,
  registration,
  profile,
  alert,
  homeReducer,
  companyReducer,
  sharedReducer
})

export default rootReducer
