const initialstate = {
  chatGroups: [],
  cardsList: [],
  chatUser: {},
  balance: null,
  notificationsCount: null
}
export const sharedReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "START_CHAT":
      return {
        ...state,
        chatUser: action.payload
      }

    case "CHAT_GROUPS":
      return {
        ...state,
        chatGroups: action.payload
      }
    case "CARDS_LIST":
      return {
        ...state,
        cardsList: action.payload
      }

    case "BALANCE":
      return {
        ...state,
        balance: action.payload
      }
    case "NOTIFICATION":
      return {
        ...state,
        notificationsCount: action.payload
      }
    default:
      return state
  }
}
