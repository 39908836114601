import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  height: calc(100% - 10vh);
  overflow-y: scroll;
`
