/* eslint-disable prettier/prettier */
import athleteUseHook from "../../../athelete/useHook"
import { validURL } from "@src/_helpers/validators/Validators"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const useHook = () => {
  const { user } = useSelector(state => state.authentication)
  const [inputValue, setValue] = useState(user.athlete)
  const [errorValue, setErrorValue] = useState({})
  const { profileUpdate } = athleteUseHook()
  const dispatch = useDispatch()

  const inputHandler = e => {
    setErrorValue({ ...errorValue, [e.target.name]: "" })
    setValue({ ...inputValue, [e.target.name]: e.target.value })
  }

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }
  const handleResponse = data => {
    isLoading(false)
    let message = ""
    if (data.status >= 400) {
      for (const [key, value] of Object.entries(data)) {
        message = message + `${key} : ${JSON.stringify(value)} `
      }
    } else if (data.response) {
      for (const [key, value] of Object.entries(data.response.data)) {
        message = message + `${key} : ${JSON.stringify(value)} `
      }
    }
    message && errorSwal("oops", message)
  }

  const onSubmitButton = async event => {
    event.preventDefault()

    if (!inputValue.city)
      setErrorValue({ ...errorValue, city: "City Name Required" })
    else if (!inputValue.address_line1)
      setErrorValue({
        ...errorValue,
        address_line1: "Street Name Required"
      })
    else if (!inputValue.postal_code)
      setErrorValue({ ...errorValue, postal_code: "Postal Code Required" })
    else if (!validURL(inputValue.business_url))
      setErrorValue({ ...errorValue, business_url: "Invalid URL Link" })
    else if (!inputValue.ssn_last_4)
      setErrorValue({ ...errorValue, ssn_last_4: "Tax Info Required" })
    else {
      isLoading(true)
      delete user.profile_picture
      profileUpdate({ formData: { ...user, athlete: inputValue } })
        .then(res => {
          handleResponse(res)
        })
        .catch(function (error) {
          handleResponse(error)
        })
    }
  }

  return {
    inputValue,
    setValue,
    errorValue,
    setErrorValue,
    onSubmitButton,
    inputHandler
  }
}
export default useHook
