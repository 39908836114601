/* eslint-disable prettier/prettier */
const initialstate = {
  campaingsList: {},
  campaignNo: 0,
  companyNo: 0,
  companyList: {},
  matchesList: {},
  plansList: [],
  dealsList: {},
  companyData: {}
}
export const athleteReducer = (state = initialstate, action) => {
  switch (action.type) {
    case "CAMPAIGN":
      return {
        ...state,
        campaingsList: action.payload
      }

    case "CAMPAING_NO":
      return {
        ...state,
        campaignNo: action.payload
      }
    case "COMPANY_LIST":
      return {
        ...state,
        companyList: action.payload
      }

    case "COMPANY_NO":
      return {
        ...state,
        companyNo: action.payload
      }

    case "MATCHES_LIST":
      return {
        ...state,
        matchesList: action.payload
      }
    case "PLANS_LIST":
      return {
        ...state,
        plansList: action.payload
      }

    case "DEALS":
      return {
        ...state,
        dealsList: action.payload
      }

    case "COMPANY_DETAILS":
      return {
        ...state,
        companyData: action.payload
      }
    default:
      return state
  }
}
