import rootReducer from "../_reducers"
import { legacy_createStore as createStore, applyMiddleware } from "redux"
// import { createLogger } from "redux-logger"
import thunkMiddleware from "redux-thunk"

// const loggerMiddleware = createLogger()

// export const store = createStore(
//   rootReducer,
//   applyMiddleware(thunkMiddleware, loggerMiddleware)
// )

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware))
