/* eslint-disable prettier/prettier */

/* eslint-disable no-unused-expressions */

/* eslint-disable jsx-a11y/alt-text */
import useHook from "../../useHook"
import InfoTabs from "./information"
import { Wrapper } from "./styles"
import useProfile from "./useHook"
import { profileServices } from "@src/_services"
import "@src/container/Layout/Dashboard/profile.css"
import FileUpload from "@src/sharedComponents/fileupload/FileUpload"
import instagram from "@svgs/instagram-small.svg"
import React, { useState, useEffect, Fragment } from "react"

const Profile = () => {
  const { isAthlete, user, history } = useHook()
  const { getBalance } = useProfile()

  const [editable, setEditable] = useState(true)
  const [IGdetails, setIGdetails] = useState()
  const [companyLogo, setCompanyLogo] = useState(user.company?.company_logo)

  useEffect(() => {
    getSocialDetails()
    getBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSocialDetails = async () => {
    const IGdetails = await profileServices.checkInstagramConnect()

    if (IGdetails) {
      if (typeof IGdetails.extra_data === "string") {
        let details
        try {
          const detailsReplace = IGdetails.extra_data.replace(/[']+/g, '"')
          details = JSON.parse(detailsReplace)
        } catch (error) {
          details = {}
        }
        setIGdetails(details)
      }
    }
  }

  return (
    <Wrapper>
      <div className="profile-card">
        <form className="profile_form">
          <FileUpload
            isAthlete={isAthlete}
            onselectImage={setCompanyLogo}
            fileName={companyLogo}
          />
          {/* profile buttons */}
          <div className="profile_social text-white mt-2">
            <div className="social-left">
              <img className="mr-3" src={instagram} alt=" " />
              <div className="media-body">
                <h5 className="my-0">
                  {IGdetails ? IGdetails.username : "Instagram"}
                </h5>
                {IGdetails ? <p className="mb-0">Instagram</p> : ""}
              </div>
            </div>
            <h6
              className="my-0"
              onClick={() =>
                history.push("/home/socials", {
                  from: "/profile",
                  show: true
                })
              }
            >
              {IGdetails ? "Unlink" : "Link"}
            </h6>
          </div>
          <div
            className="social-button mt-2 mb-4"
            onClick={() =>
              history.push("/home/socials", {
                from: "/profile",
                show: true
              })
            }
          >
            Link Social Media Platforms
          </div>

          {/* profile edit row */}
          <div className="d-flex justify-content-between text-white mt-3">
            <h5 className="mb-0">Personal details {editable}</h5>
            <p
              className="my-0 cursor_default"
              onClick={() => setEditable(!editable)}
            >
              {editable ? "Edit" : "Disable Edit"}
            </p>
          </div>

          <Fragment>
            <InfoTabs
              editable={editable}
              setEditable={setEditable}
              companyLogo={companyLogo}
            />
          </Fragment>
        </form>
      </div>
    </Wrapper>
  )
}

export default Profile
