/* eslint-disable prettier/prettier */
//  Inbox Styles
import styled, { css } from "styled-components"

export const ChatRow = styled.div`
  border-radius: 16px 16px 0px 16px;
  max-width: 100%;
  padding: 10px;
  margin: 3px;
  font-size: 14px;
  font-weight: 500;
  width: max-content;

  ${({ sender }) =>
    sender
      ? css`
          background-color: rgba(56, 63, 78, 1);
        `
      : css`
          background-color: rgba(28, 32, 40, 1);
          align-self: flex-end;
        `}

  img {
    max-width: 400px;
    height: 200px;
  }
`

export const MessageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1.5rem;
  color: white;
  overflow-y: auto;
`

export const SendChatContainer = styled.div`
  background-color: rgba(28, 32, 40, 1);
  padding: 1rem;
  display: flex;
  width: 100%;
  bottom: 0;

  .input-box {
    background-color: rgba(28, 32, 40, 1);
    outline: none;
    border: none;
    margin-left: 1rem;
    width: 100%;
    color: #fff;

    img,
    svg {
      width: 20px;
      height: 20px;
    }
  }
`
export const PayBtn = styled.div`
  background: white;
  color: black;
  padding: 0.3rem 2rem;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 1rem;
`
export const ModalBody = styled.div`
  display: flex;
  align-itens: center;
  flex-direction: column;
  .modal-heading {
    font-size: 34px;
    font-weight: 600;
  }
  .inputdivs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1.5rem 0;
    color: white;

    .input-row {
      display: flex;
      width: 100%;
    }

    input {
      outline: none;
      border: none !important;
      border-bottom: 1px solid rgba(98, 98, 98, 1) !important;
      font-weight: 400;
      font-size: 16px;
      width: 100%;
      background: transparent;
      margin-bottom: 1rem;
      color: white;
    }
    .modalbtnstyle {
      background: #b1b1b1;
      border-radius: 100px;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 500;
      font-size: 16px;
    }
  }
`

export const CardsContainer = styled.div``

export const CardsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  align-items: center;
  .card-actions {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .title {
    font-size: 20px;
    font-family: "Roboto";
  }

  div {
    .left {
      margin-right: 20px;
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    margin: 10px 0;
    .card-actions {
      font-size: 12px;
      font-weight: 400;
    }
    .title {
      font-size: 18px;
      font-family: "Roboto";
    }

    div {
      .left {
        margin-right: 10px;
      }
    }
  }
`

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  background: #383f4e;
  border-radius: 16px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 10px;
  .default {
    font-size: 12px;
    font-weight: 400;
    margin-right: 15px;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    svg,
    img {
      margin-right: 1rem;
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 1150px) {
    .title {
      font-size: 12px;
    }
  }
`
