/* eslint-disable react-hooks/exhaustive-deps */
import { Wrapper } from "./styles"
import { profileActions } from "@src/_actions"
import React, { useEffect } from "react"
import { connect } from "react-redux"

const TermsPage = ({ header }) => {
  useEffect(() => {
    header("Terms And Conditions")
  }, [])

  return (
    <Wrapper>
      <pre className="custom-pre">
        Also see: Terms and Conditions
        <br />
        <br />
        Effective Date: 22nd August 2022
        <br />
        Site Covered:<i>www.conxts.io</i>
        <br />
        <br />
        THE AGREEMENT: The use of this website and services on this website
        provided by Conxts.io (hereinafter referred to as "Website") are subject
        to the following Terms & Conditions (hereinafter the "Agreement"), all
        parts and sub-parts of which are specifically incorporated by reference
        here. This Agreement shall govern the use of all pages on this website
        (hereinafter collectively referred to as "Website") and any services
        provided by or on this Website ("Services").
        <br />
        <br />
        <strong> • DEFINITIONS</strong>
        <br />• “<b>Agreement</b>” refers to this Terms and Conditions and the
        Privacy Policy and other documents provided to you by the Website; 
        <br />
        <br />• “<b>We</b>”, “<b>us</b>”, “<b>Our</b>” and “<b>Company</b>” are
        references to Heroku Cloud Service;
        <br />
        <br />• “<b>User</b>”, “<b>You</b>” and “<b>your</b>” refers to the
        person who is accessing or visiting our website for taking or availing
        of any service from us. User shall include the company, partnership,
        sole trader, person, body corporate or association taking services of
        this Website;
        <br />
        <br />• ”<b>Website</b>” shall mean and include https://conxts.io/; and
        any successor Website of the Company or any of its affiliates;
        <br />
        <br />• <b>Parties</b>: Collectively, the parties to this Agreement (We
        and You) will be referred to as Parties.
        <br />
        <br />• <strong> ASSENT & ACCEPTANCE </strong>
        <br />
        By using the Website, you warrant that you have read and reviewed this
        Agreement and that you agree to be bound by it. If you do not agree to
        be bound by this Agreement, please leave the Website immediately. We
        only agree to provide the users of this Website and Services to you if
        you assent to this Agreement.
        <br />
        <br />• <strong> SERVICE</strong>
        <br />
        At conxts.io, we offer you a meticulously designed website where we
        provide all one platform for college athletes and companies to match,
        chat, and create deals in the NIL space.
        <br />
        <br />• <strong> AGE RESTRICTION</strong>
        <br />
        You must be at least 13 (Thirteen) years of age to use this Website or
        any Services contained herein. By using this Website, you represent and
        warrant that you are at least 13 years of age and may legally agree to
        this Agreement. We assume no responsibility or liability for any
        misrepresentation of your age.
        <br />
        <br />• <strong> SUBSCRIPTION PAYMENT</strong>
        <br />
        • All the purchases for any service available on the website shall be
        governed by our terms and conditions.
        <br />
        <br />• While providing your details you must be careful and warrant
        that the information provided is true and accurate.
        <br />
        <br />• Payment mode shall be:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
        Online: Credit Cards and Debit Cards
        <br />•{" "}
        <b>
          We may ask you to supply additional information relevant to your
          Transaction, including your credit card number (or other payment
          information), the expiration date of your credit card, and your email
          and postal addresses for billing and notification (such information,
          “Payment Information”).
          <br />• You represent and warrant that you have the legal right to use
          all payment method(s) represented by any such Payment Information.
          <br />• When you initiate a Transaction, you authorize us to provide
          your Payment Information to third parties so we can complete your
          Transaction and charge your payment method for the type of Transaction
          you have selected (plus any applicable taxes and other charges).
          <br />• Subscription Payment: If you purchase our subscription, you
          will be charged the one-time usage, monthly and annual (as applicable)
          subscription fee, plus any applicable taxes, and other charges
          (“Subscription Fee”), at the beginning of your subscription and each
          month or year (as applicable) thereafter, at the then-current
          Subscription Fee. If you purchase a Subscription, we (or our
          third-party payment processor) will automatically charge you each
          month or year on the anniversary of the commencement of your
          Subscription, using the Payment Information you have provided until
          you cancel your Subscription. By agreeing to these Terms and electing
          to purchase a Subscription, you acknowledge that your Subscription has
          recurring payment features and you accept responsibility for all
          recurring payment obligations before cancellation of your Subscription
          by you or us.
        </b>
        <br />
        <br />• Any order to purchase a product that you place with us is
        subject to acceptance by us.
        <br />
        <br />• If there is an error in the order confirmation, please contact
        us immediately by email at info@conxts.io.
        <br />
        <br />• We may refuse or be unable to process your order if:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
        Your card or PayPal account does not give authorization for the payment
        of the purchase price.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
        You do not meet the eligibility to order criteria set out above.
        <br />• The refund shall be allowed as per our refund policy.
        <br />• We are happy to support you if there is any issue you can
        contact our back-office team for any inquiry or problem.
        <br />• We take customer feedback very seriously and use it to
        constantly improve our products and quality of service.
        <br />
        <br />• <strong> REFUND POLICY</strong>
        <br />
        <b>
          OUR GENERAL POLICY IS YOUR PURCHASE WILL BE CONSIDERED AS FINAL AND
          YOU WILL NOT BE ABLE TO RECEIVE A REFUND OF YOUR PAYMENT AT ANY TIME.
          However, in a determination to accomplish customer satisfaction, the
          Customer can contact us through our email: info@conxts.io. We will
          respond within 3 (Three) business days.
        </b>
        <br />
        <br />• <strong> LICENSE TO USE WEBSITE </strong>
        <br /> We may provide you with certain information as a result of your
        use of the Website or Services. Such information may include but is not
        limited to, documentation, data, or information developed by us, and
        other materials that may assist in your use of the Website or Services
        ("Our Materials"). Subject to this Agreement, we grant you a
        non-exclusive, limited, non-transferable, and revocable license to use
        Our Materials solely in connection with your use of the Website and
        Services. Our Materials may not be used for any other purpose, and this
        license terminates upon your cessation of use of the Website or Services
        or at the termination of this Agreement.
        <br />
        <br />• <strong> LICENSE</strong>
        <br /> <b> Grant of license:</b>
        <br />• Subject to the terms and conditions of this Agreement Company
        hereby grants the User a non-exclusive, non-transferable, revocable
        license to use the Software and Service for the Term.
        <br />• The User shall be provided access to the Software and the
        Service, and the User may authorize its employees to access it. User
        shall be responsible for the use or misuse of the Software and Services
        by its Authorized Users.
        <br />• The User understands and acknowledges that the User shall not
        have the right to sublicense or re-distribute the Service to third
        parties.
        <br />• Nothing in this Agreement shall be considered as a grant of
        title or ownership in the Service to the User.
        <br />
        <br />• <strong> GENERAL CONDITIONS</strong>
        <br />• We do not guarantee the accuracy, completeness, validity, or
        timeliness of the information listed by us.
        <br />• We make material changes to these terms and conditions from time
        to time, we may notify you either by prominently posting a notice of
        such changes or via email communication.
        <br />• The website is licensed to you on a limited, non-exclusive,
        non-transferrable, non-sublicensable basis, solely to be used in
        connection with the Service for your private, personal, non-commercial
        use, subject to all the terms and conditions of this Agreement as they
        apply to the Service.
        <br />• We reserve the right to alter prices at any time without notice.
        If a client has commissioned any services from our website before a
        change in prices that commission will not be subject to any increase,
        but any subsequent commission may be subject to an increase.
        <br />• Our Website shall provide the information and plan based on the
        Customer’s requests and specifications provided. The Client shall be
        solely responsible for checking the accuracy and relevance of the
        information provided to the Website.
        <br />
        <br />• <strong> INTELLECTUAL PROPERTY</strong>
        <br />
        You agree that the Website and all Services provided by us are the
        property of the Heroku Cloud Service, including all copyrights,
        trademarks, trade secrets, patents, and other intellectual property
        ("Our IP"). You agree that we own all right, titles, and interests in
        and to Our IP and that you will not use Our IP for any unlawful or
        infringing purpose. You agree not to reproduce or distribute Our IP in
        any way, including electronically or via registration of any new
        trademarks, trade names, service marks, or Uniform Resource Locators
        (URLs), without express written permission from us.
        <br />
        a) To make the Website and Services available to you, you hereby grant
        us a royalty-free, non-exclusive, worldwide license to copy, display,
        use, broadcast, transmit and make derivative works of any content you
        publish, upload, or otherwise make available to the Website ("Your
        Content"). We claim no further proprietary rights in your Content.
        <br />
        b) If you feel that any of your intellectual property rights have been
        infringed or otherwise violated by the posting of information or media
        by another of our users, please contact us and let us know.
        <br />
        <br />•<strong> USER OBLIGATIONS</strong>
        <br /> As a user of the Website or Services, you may be asked to
        register with us. When you do so, you will choose a user identifier,
        which may be your email address or another term, as well as a password.
        You may also provide personal information, including, but not limited
        to, your name. You are responsible for ensuring the accuracy of this
        information. This identifying information will enable you to use the
        Website and Services. You must not share such identifying information
        with any third party, and if you discover that your identifying
        information has been compromised, you agree to notify us immediately in
        writing. An email notification will suffice. You are responsible for
        maintaining the safety and security of your identifying information as
        well as keeping us apprised of any changes to your identifying
        information. Providing false or inaccurate information, or using the
        Website or Services to further fraud or unlawful activity is grounds for
        immediate termination of this Agreement.
        <br />
        <br />• <strong> ACCEPTABLE USE </strong>
        <br />
        You agree not to use the Website or Services for any unlawful purpose or
        any purpose prohibited under this clause. You agree not to use the
        Website or Services in any way that could damage the Website, Services,
        or general business of the Heroku Cloud Service.
        <br />
        a) You further agree not to use the Website or Services:
        <br />
        I) To harass, abuse, or threaten others or otherwise violate any
        person's legal rights;
        <br />
        II) To violate any of our intellectual property rights or any third
        party;
        <br />
        III) To upload or otherwise disseminate any computer viruses or other
        software that may damage the property of another;
        <br />
        IV) To perpetrate any fraud;
        <br />
        V) To engage in or create any unlawful gambling, sweepstakes, or pyramid
        scheme;
        <br />
        VI) To publish or distribute any obscene or defamatory material;
        <br />
        VII) To publish or distribute any material that incites violence, hate,
        or discrimination towards any group;
        <br />
        VIII) To unlawfully gather information about others.
        <br />
        <br />•<strong> ASSUMPTION OF RISK </strong>
        <br />
        The Website and Services are provided for communication purposes only.
        You acknowledge and agree that any information posted on Our Website is
        not intended to be legal advice, medical advice, or financial advice,
        and no fiduciary relationship has been created between you and us. You
        further agree that your purchase of any of the products on the Website
        is at your own risk. We do not assume responsibility or liability for
        any advice or other information given on the Website.
        <br />
        <br />• <strong> REVERSE ENGINEERING & SECURITY</strong> <br /> You
        agree not to undertake any of the following actions:
        <br />
        a) Reverse engineer, or attempt to reverse engineer or disassemble any
        code or software from or on the Website or Services;
        <br />
        b) Violate the security of the Website or Services through any
        unauthorized access, circumvention of encryption or other security
        tools, data mining, or interference with any host, user, or network.
        <br />
        <br />• INDEMNIFICATION
        <br />
        You agree to defend and indemnify us and any of our affiliates (if
        applicable) and hold us harmless against any legal claims and demands,
        including reasonable attorney's fees, which may arise from or relate to
        your use or misuse of the Website or Services, your breach of this
        Agreement, or your conduct or actions. You agree that we shall be able
        to select its legal counsel and may participate in its defense if we
        wish.
        <br />
        <br />• EXCLUSION OF LIABILITY
        <br />
        You understand and agree that we (A) do not guarantee the accuracy,
        completeness, validity, or timeliness of information listed by us or any
        third parties; and (B) shall not be responsible for any materials posted
        by us or any third party. You shall use your judgment, caution, and
        common sense in evaluating any prospective methods or offers and any
        information provided by us or any third party.
        <br />
        Further, we shall not be liable for direct, indirect consequential, or
        any other form of loss or damage that may be suffered by a user through
        the use of the www.conxts.io Website including loss of data or
        information or any kind of financial or physical loss or damage.
        <br />
        In no event shall Heroku Cloud Service, nor its owners, directors,
        employees, partners, agents, suppliers, or affiliates, be accountable
        for any indirect, incidental, special, eventful, or exemplary costs,
        including without limitation, loss of proceeds, figures, usage,
        goodwill, or other intangible losses, consequential from (i) your use or
        access of or failure to access or use the Service; (ii) any conduct or
        content of any third party on the Service; (iii) any content attained
        from the Service; and (iv) unlawful access, use or alteration of your
        transmissions or content, whether or not based on guarantee, agreement,
        domestic wrong (including carelessness) or any other lawful concept,
        whether or not we've been aware of the possibility of such damage, and
        even if a cure set forth herein is originated to have futile of its
        important purpose.
        <br />
        <br />• RELEASE
        <br />
        <b>
          You release the Company and our successors from all losses, damages,
          rights, and demands and actions of any kind, including personal
          injuries, death, and property damage, that are directly or indirectly
          related to or arising from your use of the Services (collectively,
          “Claims”)
        </b>
        .
        <br />
        <br />• NO RESPONSIBILITY
        <br />
        <b>
          We are not responsible to you for:
          <br /> • any reliance that you may place on any material or commentary
          posted on our website. Please note that nothing contained in our
          website or the material published on it is intended to amount to
          advice on which you should rely; or
          <br />• any losses you suffer because you cannot use our website at
          any time; or
          <br />• any errors in or omissions from our website; or
          <br />• any unauthorized access or loss of personal information that
          is beyond our control
        </b>
        .
        <br />
        <br />• SPAM POLICY
        <br />
        You are strictly prohibited from using the Website or any of our's
        Services for illegal spam activities, including gathering email
        addresses and personal information from others or sending any mass
        commercial emails.
        <br />
        <br />• THIRD-PARTY LINKS & CONTENT
        <br />
        We may occasionally post links to third-party websites or other
        services. You agree that we are not responsible or liable for any loss
        or damage caused as a result of your use of any third-party services
        linked to Our Website.
        <br />
        <br />• MODIFICATION & VARIATION
        <br />
        We may, from time to time and at any time without notice to you, modify
        this Agreement. You agree that we have the right to modify this
        Agreement or revise anything contained herein. You further agree that
        all modifications to this Agreement are in full force and effect
        immediately upon posting on the Website and that modifications or
        variations will replace any prior version of this Agreement unless prior
        versions are specifically referred to or incorporated into the latest
        modification or variation of this Agreement.
        <br />
        <br />• ENTIRE AGREEMENT
        <br />
        This Agreement constitutes the entire understanding between the Parties
        concerning any use of this Website. This Agreement supersedes and
        replaces all prior or contemporaneous agreements or understandings,
        written or oral, regarding the use of this Website.
        <br />
        <br />• SERVICE INTERRUPTIONS
        <br />
        We may need to interrupt your access to the Website to perform
        maintenance or emergency services on a scheduled or unscheduled basis.
        You agree that your access to the Website may be affected by
        unanticipated or unscheduled downtime, for any reason, but that we shall
        have no liability for any damage or loss caused as a result of such
        downtime.
        <br />
        <br />• TERM, TERMINATION & SUSPENSION
        <br />
        We may terminate this Agreement with you at any time for any reason,
        with or without cause. We specifically reserve the right to terminate
        this Agreement if you violate any of the terms outlined herein,
        including, but not limited to, violating the intellectual property
        rights of us or a third party, failing to comply with applicable laws or
        other legal obligations, and/or publishing or distributing illegal
        material. If you have registered for an account with Us, You may also
        terminate this Agreement at any time by contacting Us and requesting
        termination. At the termination of this Agreement, any provisions that
        would be expected to survive termination by their nature shall remain in
        full force and effect.
        <br />
        <br />• NO WARRANTIES
        <br />
        You agree that your use of the Website and Services is at your sole and
        exclusive risk and that any Services provided by Us are on an "As Is"
        basis. We hereby expressly disclaim any express or implied warranties of
        any kind, including, but not limited to the implied warranty of fitness
        for a particular purpose and the implied warranty of merchantability. We
        make no warranties that the Website or Services will meet your needs or
        that the Website or Services will be uninterrupted, error-free, or
        secure. We also make no warranties as to the reliability or accuracy of
        any information on the Website or obtained through the Services. You
        agree that any damage that may occur to you, through your computer
        system, or as a result of the loss of your data from your use of the
        Website or Services is your sole responsibility and that we are not
        liable for any such damage or loss.
        <br />
        <br />• LIMITATION ON LIABILITY
        <br />
        We are not liable for any damages that may occur to you as a result of
        your use of the Website or Services, to the fullest extent permitted by
        law. This section applies to any claims by you, including, but not
        limited to, lost profits or revenues, consequential or punitive damages,
        negligence, strict liability, fraud, or torts of any kind.
        <br />
        <br />•<b> GENERAL PROVISIONS:</b>
        <br />
        <b>a) JURISDICTION, VENUE & CHOICE OF LAW:</b>
        The terms herein will be governed by and construed following the law of
        the United States of America ‎ without giving effect to any principles
        of conflicts of law. The Courts of the United States of America shall
        have exclusive jurisdiction over any dispute arising from the use of the
        Website.
        <br />
        b) ASSIGNMENT: This Agreement, or the rights granted hereunder, may not
        be assigned, sold, leased, or otherwise transferred in whole or part by
        you. Should this Agreement, or the rights granted hereunder, be
        assigned, sold, leased, or otherwise transferred by us, the rights and
        liabilities of the Heroku Cloud Service will bind and inure to any
        assignees, administrators, successors, and executors.
        <br />
        c) SEVERABILITY: If any part or sub-part of this Agreement is held
        invalid or unenforceable by a court of law or competent arbitrator, the
        remaining parts and sub-parts will be enforced to the maximum extent
        possible. In such a condition, the remainder of this Agreement shall
        continue in full force.
        <br />
        d) NO WAIVER: If we fail to enforce any provision of this Agreement,
        this shall not constitute a waiver of any future enforcement of that
        provision or any other provision. Waiver of any part or sub-part of this
        Agreement will not constitute a waiver of any other part or sub-part.
        <br />
        e) HEADINGS FOR CONVENIENCE ONLY: Headings of parts and sub-parts under
        this Agreement are for convenience and organization, only. Headings
        shall not affect the meaning of any provisions of this Agreement.
        <br />
        f) NO AGENCY, PARTNERSHIP, OR JOINT VENTURE: No agency, partnership, or
        joint venture has been created between the Parties as a result of this
        Agreement. No Party has any authority to bind the other to third
        parties.
        <br />
        g) FORCE MAJEURE: We are not liable for any failure to perform due to
        causes beyond its reasonable control including, but not limited to, acts
        of God, acts of civil authorities, acts of military authorities, riots,
        embargoes, acts of nature, and natural disasters, and other acts which
        may be due to unforeseen circumstances.
        <br />
        h) ELECTRONIC COMMUNICATIONS PERMITTED: Electronic communications are
        permitted to both Parties under this Agreement, including e-mail or fax.
        For any questions or concerns, please email us at the following address:
        info@conxts.io.
      </pre>
    </Wrapper>
  )
}

function mapState(state) {
  const { authentication } = state
  return { authentication }
}

const actionCreators = {
  header: profileActions.header
}

export default connect(mapState, actionCreators)(TermsPage)
