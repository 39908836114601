/* eslint-disable prettier/prettier */
import { authHeaderAxios } from "../_helpers"
import { siteUrls } from "../_helpers"
import axios from "axios"

export const request = axios.create({
  baseURL: siteUrls.BASE_URL
})

const companyAccountSetup = payload => {
  return request.patch(
    "accounts/profile/company/setup/",
    payload,
    authHeaderAxios()
  )
}

const campainsList = () => {
  return request.get("campaigns/all/", authHeaderAxios())
}

const campaignLikeDislike = (id, payload) => {
  return request.post(`campaigns/all/${id}/like/`, payload, authHeaderAxios())
}

const myCampainsList = () => {
  return request.get("campaigns/my-campaigns/", authHeaderAxios())
}

const campaignLikesList = id => {
  return request.get(`campaigns/all/${id}/likes/`, authHeaderAxios())
}

const reportCampaing = payload => {
  return request.post(
    `/reports/campaign-user-reports/`,
    payload,
    authHeaderAxios()
  )
}

const athletesList = () => {
  return request.get("companies/my-company/banks/", authHeaderAxios())
}

const athletesLikeDislike = (id, payload) => {
  return request.post(`athletes/all/${id}/like/`, payload, authHeaderAxios())
}

const matchesList = () => {
  return request.get(`matches/all/`, authHeaderAxios())
}
const athleteDetail = id => {
  return request.get(`/athletes/all/${id}/`, authHeaderAxios())
}

const newCampaigns = payload => {
  return request.post(`/campaigns/my-campaigns/`, payload, authHeaderAxios())
}
function updateCompaign(payload, id) {
  return request.patch(`/campaigns/all/${id}/`, payload, authHeaderAxios())
}
function deleteCompaign(id) {
  return request.delete(`/compaigns/all/${id}/`, authHeaderAxios())
}
const getPlans = () => {
  return request.get(`/subscriptions/plans/`, authHeaderAxios())
}
const activateSubscription = payload => {
  return request.post(`/subscriptions/create/`, payload, authHeaderAxios())
}
const getActivePlan = () => {
  return request.get(`/subscriptions/active/`, authHeaderAxios())
}
const upgradeSubsciption = payload => {
  return request.post(`/subscriptions/upgrade/`, payload, authHeaderAxios())
}

const getCompanyOwnerInfo = () => {
  return request.get(`/companies/my-company/owner/`, authHeaderAxios())
}

const putCompanyOwnerInfo = payload => {
  return request.put(`/companies/my-company/owner/`, payload, authHeaderAxios())
}

const campaignMatchStatusUpdate = payload => {
  return request.patch(
    `campaigns/campaign-likes/${payload.id}/`,
    payload,
    authHeaderAxios()
  )
}

const AthleteService = {
  companyAccountSetup,
  campainsList,
  campaignLikeDislike,
  reportCampaing,
  athletesList,
  athletesLikeDislike,
  matchesList,
  myCampainsList,
  campaignLikesList,
  athleteDetail,
  newCampaigns,
  updateCompaign,
  deleteCompaign,
  getPlans,
  activateSubscription,
  getActivePlan,
  upgradeSubsciption,
  getCompanyOwnerInfo,
  putCompanyOwnerInfo,
  campaignMatchStatusUpdate
}

export default AthleteService
