/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
// import { AtheleteBank, AtheleteCompaigns } from './athelete';
import useHook from "../useHook"
import { ButtonConainer } from "./styles"
import "@containers/Layout/Dashboard/Home.css"
import "@containers/Layout/Dashboard/match.css"
import useHome from "@containers/screens/home/useHook"
import rightArrow from "@src/assets/images/angle-right.png"
import {
  MapSvg,
  FacebookSvg,
  InstagramSvg,
  LinkedinSvg,
  TiktokSvg,
  TwitterSvg,
  youtubeSvg,
  WorldSvg
} from "@svgs"
import instagram from "@svgs/instagram-small.svg"
import { socialDataParser } from "@utils/helpers"
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useSelector } from "react-redux"

const Match = () => {
  const { matchesList } = useSelector(state => state.athleteReducer)
  const page_position_store = useSelector(
    state => state.homeReducer.page_position
  )
  const { getMatches, campaginStatusUpdate, startChat } = useHook()
  const [modalInfo, setModalInfo] = useState({})

  const isBank = page_position_store === "Bank"

  const homeHook = useHome()

  useEffect(() => {
    homeHook.updateHeader("match")
    getMatches()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page_position_store])

  const DetailsModal = ({ show, handleClose, data }) => {
    if (!data.athlete) {
      return <></>
    }
    const {
      // id,
      country,
      state,
      social_accounts,
      bio,
      user,
      gender,
      age
    } = data.athlete
    const { first_name, last_name, profile_picture } = user
    const insta = social_accounts.find(a => a.provider === "instagram")
    const instaLink = insta && socialDataParser(insta)
    const name = `${first_name} ${last_name}`

    const Social_Icon = {
      facebook: FacebookSvg,
      google: youtubeSvg,
      linkedin_oauth2: LinkedinSvg,
      twitter: TwitterSvg,
      tiktok: TiktokSvg,
      instagram: InstagramSvg
    }
    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{ paddingBottom: "0px" }}
          ></Modal.Header>
          <Modal.Body style={{ padding: "0px" }}>
            <div className="modal__body1" style={{ margin: "0px" }}>
              <div
                className="m-log"
                style={{ marginBottom: "1rem", paddingLeft: "1rem" }}
              >
                Match Detail
              </div>
              <div className="bank_card_head">
                <div className="nike_logo_div">
                  {profile_picture && <img src={profile_picture} />}
                </div>
                <div className="head_content_div">
                  <span className="nike_head">{name}</span>
                  <span className="sub_head">
                    {gender}, {age} years
                  </span>
                </div>
              </div>
              <div className="back_card_body">
                <p className="bank_para_div">{bio}</p>
                <div className="location_div">
                  <MapSvg />
                  <div className="map_div">
                    <span className="headquart_div">{country}</span>
                    <span className="location_name">{state}</span>
                  </div>
                </div>
                <div
                  className="location_div"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div className="location_div">
                    {social_accounts?.map((val, index) => {
                      const Icon = Social_Icon[val.provider] || WorldSvg
                      const item = socialDataParser(val)

                      if (val.provider !== "instagram") {
                        return (
                          <span
                            onClick={
                              item.profileLink
                                ? () => window.open(item.profileLink)
                                : null
                            }
                            className="social_icon"
                            key={index}
                          >
                            <Icon />
                          </span>
                        )
                      } else return <></>
                    })}
                  </div>
                </div>
                {instaLink && (
                  <div
                    className="insta_div"
                    onClick={() => {
                      window.open(instaLink.profileLink)
                    }}
                  >
                    <img src={instagram} />
                    <div className="insta_content">
                      <span className="nike_head">{instaLink?.name}</span>
                    </div>
                  </div>
                )}
              </div>
              {!isBank ? (
                <ButtonConainer>
                  <div
                    className="bnt"
                    type="submit"
                    onClick={() =>
                      campaginStatusUpdate(data, "rejected", setModalInfo)
                    }
                  >
                    Decline
                  </div>
                  <div
                    className="bnt"
                    type="submit"
                    onClick={() =>
                      campaginStatusUpdate(data, "approved", setModalInfo)
                    }
                  >
                    Approve
                  </div>
                </ButtonConainer>
              ) : (
                <ButtonConainer>
                  <div
                    className="bnt"
                    type="submit"
                    onClick={() => startChat(data)}
                  >
                    Start Chat
                  </div>
                </ButtonConainer>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  return (
    <div
      style={{
        paddingTop: "1.5rem",
        overflow: "scroll",
        height: "calc(100% - 10vh)"
      }}
    >
      <div className="match_outer_div">
        {matchesList?.results && matchesList?.results.length > 0 && (
          <div style={{ fontSize: "20px", fontWeight: 500 }}>
            You have a new match!
          </div>
        )}
        {matchesList?.results?.map((item, index) => {
          const { title } = item?.campaign || {}
          const { first_name, last_name, profile_picture } =
            item?.athlete?.user || {}
          return (
            <div
              key={index}
              className="match_main_div"
              onClick={() => setModalInfo(item)}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="nike_logo_div">
                  <img src={profile_picture} alt="profile_picture" />
                </div>
                <div className="head_content_div">
                  <span className="nike_head">{`${first_name} ${last_name}`}</span>
                  <span className="sub_head">{title}</span>
                </div>
              </div>
              <img src={rightArrow} alt="" />
            </div>
          )
        })}
      </div>

      <DetailsModal
        show={modalInfo.athlete}
        data={modalInfo}
        handleClose={() => {
          setModalInfo({})
        }}
      />
    </div>
  )
}

export default Match
