import { profileActions } from "@src/_actions"
import { profileServices } from "@src/_services"
import facebook from "@src/assets/svgs/facebook.svg"
import instagram from "@src/assets/svgs/instagram-small.svg"
import linkedin from "@src/assets/svgs/linkedin.svg"
import tiktok from "@src/assets/svgs/tiktok.svg"
import twitter from "@src/assets/svgs/twitter.svg"
import youtube from "@src/assets/svgs/youtube.svg"
import { socialDataParser } from "@utils/helpers"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

const useHook = () => {
  const dispatch = useDispatch()

  // social Links
  const [socialList, setSocialList] = useState([
    { name: "Instagram", link: "instagram", img: instagram, details: null },
    { name: "Facebook", link: "facebook", img: facebook, details: null },
    { name: "Linkedin", link: "linkedin_oauth2", img: linkedin, details: null },
    { name: "Twitter", link: "twitter", img: twitter, details: null },
    { name: "TikTok", link: "tiktok", img: tiktok, details: null },
    { name: "YouTube", link: "google", img: youtube, details: null }
  ])

  useEffect(() => {
    getSocialList()
    dispatch(profileActions.header("Social Media"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSocialList = async () => {
    const socialAccounts = await profileServices.checkSocialsConnect()

    if (socialAccounts.count > 0) {
      socialAccounts.results.forEach(element => {
        const data = socialDataParser(element)
        setSocialDetails(element.provider, data)
      })
    }
  }

  const setSocialDetails = async (name, detail) => {
    const newList = [...socialList]
    newList.forEach(element => {
      if (element.link === name) element.details = detail
    })
    setSocialList(newList)
  }

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  return {
    // social Links
    socialList,
    setSocialList,
    isLoading
  }
}
export default useHook
