/* eslint-disable prettier/prettier */
import CompanyProfile from "./company-profile"
import AtheleteHome from "./home"
import AtheleteMatches from "./match"
import AtheleteProfile from "./profile"
import Subscribed from "./subscribed"
import Subscription from "./subscription"
import MyWallet from "./wallet"
import BankWallet from "./wallet/bank-wallet"
import Withdraw from "./wallet/withdraw"

export {
  AtheleteHome,
  AtheleteProfile,
  AtheleteMatches,
  Subscription,
  Subscribed
}

export const ATHLETE_ROUTES = [
  { path: "/home", component: AtheleteHome, protected: true },
  { path: "/home/match", component: AtheleteMatches, protected: true },
  { path: "/home/profileDetail", component: CompanyProfile, protected: true },
  { path: "/home/wallet", component: MyWallet, protected: true },
  { path: "/home/walletWithdraw/:key", component: Withdraw, protected: true },
  { path: "/home/bankwallet", component: BankWallet, protected: true },
  { path: "/home/profile", component: AtheleteProfile, protected: true },
  { path: "/home/subscription", component: Subscription, protected: true },
  { path: "/home/subscribed", component: Subscribed, protected: true }
]
