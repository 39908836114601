import DatePickerCustom from "../datepicker"
import UserInput from "../userInput"
import React, { Fragment } from "react"

export default function InputFiels({
  FIELDS_LIST,
  editable = true,
  inputHandler,
  setErrorList,
  errorList,
  setUserInput,
  userInput
}) {
  return FIELDS_LIST.map((item, i) => {
    const { type, name } = item

    switch (type) {
      case "dropdown":
        return (
          <div key={i} className="form-floating">
            <select
              className={`form-select ${!editable ? "deselect_select" : ""}`}
              style={{ marginTop: "20px" }}
              onChange={inputHandler}
              value={userInput[name]}
              {...item}
            >
              <option value="default" disabled hidden>
                {item.label}
              </option>
              {item.list.map(({ value, label }, i) => (
                <option key={i} value={value || undefined}>
                  {label}
                </option>
              ))}
            </select>
            <label htmlFor={item.id}>{item.label}</label>
            <span style={{ color: "red", fontSize: "12px" }}>
              {errorList[item.name]}
            </span>
          </div>
        )
      case "date":
        return (
          <Fragment key={i}>
            <DatePickerCustom
              editable={!editable}
              selectedDOB={date => setUserInput({ ...userInput, [name]: date })}
              error={errorList[name]}
              onChange={() => setErrorList({ [name]: "" })}
              value={userInput[name]}
              {...item}
            />
          </Fragment>
        )

      default:
        return (
          <Fragment key={i}>
            <UserInput
              onChange={inputHandler}
              readOnly={!editable}
              error={errorList[name]}
              value={userInput[name]}
              {...item}
            />
          </Fragment>
        )
    }
  })
}
