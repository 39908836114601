/* eslint-disable no-unused-vars */

/* eslint-disable prettier/prettier */

/* eslint-disable array-callback-return */
import {
  ChatRow,
  MessageContainer,
  SendChatContainer,
  PayBtn,
  CardsContainer,
  CardsHeader,
  Card,
  ModalBody
} from "./styles"
import useHook from "./useHook"
import arrowleft from "@src/assets/images/icons/arrow left.png"
import walletImage from "@src/assets/images/wallet1.png"
import walletImage2 from "@src/assets/images/wallet2.png"
import attachment from "@src/assets/svgs/attachment.svg"
import nike_tick from "@src/assets/svgs/nike_tick.svg"
import send from "@src/assets/svgs/send.svg"
import {
  CloseSvg,
  CardLogoSvg,
  DeleteSvg,
  SelectedSvg,
  UnselectSvg
} from "@svgs"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { usePubNub } from "pubnub-react"
import React, { useEffect } from "react"
import { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

dayjs.extend(utc)

const Inbox = ({ ChatProfile, setVisibleChatbox }) => {
  const {
    user,
    messages,
    userInfo,
    handleMessageSender,
    setMessages,
    setUserInfo,
    setSelectedFile,
    selectedFile,
    getAthleteDetail,
    getCompaniesList,
    paymentSend,
    cardsList,
    getPaymentMethods
  } = useHook()
  const history = useHistory()
  const { balance } = useSelector(state => state.sharedReducer)

  const { id } = user.user
  const attachmentRef = React.useRef(null)

  const lastMessageRef = React.useRef(null)

  const pubnub = usePubNub()
  const { channel_id } = ChatProfile

  const [selectDefault, setSelectDefault] = useState({})
  const [modalShow, setModalShow] = useState({ show: false })
  const [payShow, setPayShow] = useState(false)
  const [paymentInfo, setPaymentInfo] = useState({
    athlete_user: ChatProfile.athlete_user.id
  })

  useEffect(() => {
    async function fetch() {
      if (!cardsList) {
        getPaymentMethods()
      } else {
        const defaultCard = await localStorage.getItem("defaultCard")
        if (defaultCard && defaultCard !== "undefined") {
          setSelectDefault(JSON.parse(defaultCard))
        } else {
          setSelectDefault(cardsList[0])
        }
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardsList])

  useEffect(() => {
    if (pubnub && channel_id) {
      const listener = {
        message: envelope => {
          setMessages(msgs => [
            ...msgs,
            {
              _id: envelope.message._id,
              author: envelope.publisher,
              content: envelope.message.content,
              user: envelope.message.user,
              text: envelope.message.text,
              timetoken: envelope.timetoken,
              createdAt: new Date()
            }
          ])

          if (envelope?.message?.image) {
            getMessages()
          }
        }
      }

      pubnub.addListener(listener)
      pubnub.subscribe({ channels: [channel_id] })

      return () => {
        pubnub.removeListener(listener)
        pubnub.unsubscribeAll()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pubnub, channel_id])

  const checkPageStatus = (message, user) => {
    if (!("Notification" in window)) {
      alert("This browser does not support system notifications!")
    } else if (Notification.permission === "granted") {
      sendNotification(message, user)
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission(permission => {
        if (permission === "granted") {
          sendNotification(message, user)
        }
      })
    }
  }

  useEffect(() => {
    getMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel_id])
  const sendNotification = (message, user) => {
    const notification = new Notification("New message", {
      // icon: "https://cdn-icons-png.flaticon.com/512/733/733585.png",
      body: `${user}: ${message}`
    })
    notification.onclick = () =>
      function () {
        window.open("http://localhost:3000/chat")
      }
  }
  const getMessages = async () => {
    const { channel_id } = ChatProfile

    try {
      const result = await pubnub.fetchMessages({
        channels: [channel_id],
        count: 250
      })

      const messagesList = result.channels[channel_id].map(message => {
        return { ...message.message, ...message }
      })
      setMessages(messagesList)
      scrollToBottom()
    } catch (error) {}
  }

  const sendMessage = async () => {
    const nowDateTime = dayjs().format()

    const message = {
      content: userInfo.typedMessage,
      text: userInfo.typedMessage,
      _id: id,
      user: user.user,
      timetoken: Date.now(),
      createdAt: new Date()
    }

    const payload = {
      last_message_datetime: nowDateTime,
      last_message: message.content,
      id: ChatProfile.id
    }

    if (user.user.user_type === "company") {
      payload.last_read_company = dayjs().format()
    } else {
      payload.last_read_athlete = dayjs().format()
    }

    handleMessageSender(payload, message, msg => {
      pubnub.publish({
        channel: channel_id,
        message: msg,
        timetoken: Date.now()
      })
      checkPageStatus(
        msg.content,
        msg.user.first_name + " " + msg.user.last_name
      )
    })

    setUserInfo({ ...userInfo, typedMessage: "" })
    scrollToBottom()
    setSelectedFile()
  }

  const handleChange = async event => {
    const { name, value } = event.target
    setUserInfo({ ...userInfo, [name]: value })
    if (userInfo.typedMessage) {
    }
  }

  const FilehandleChange = event => {
    const fileUploaded = event.target.files[0]
    setSelectedFile(fileUploaded)
  }

  const scrollToBottom = () => {
    lastMessageRef.current?.scrollIntoView()
  }
  const onTextChange = event => {
    const { name, value } = event.target
    setPaymentInfo({ ...paymentInfo, [name]: value })
  }
  const paymentsSend = () => {
    paymentSend({ ...paymentInfo, payment_method: selectDefault?.id })
  }
  return (
    <div className="open_chat">
      <div className="chat_div">
        <img
          src={arrowleft}
          className="back_screen"
          onClick={() => setVisibleChatbox(false)}
          alt=""
        />
        <div
          className="nike_logo_div"
          style={{ height: "50px", width: "50px" }}
        >
          <img
            src={ChatProfile.image || nike_tick}
            className="img-fluid"
            alt=""
          />
        </div>
        <div
          className="media-body"
          onClick={() => {
            if (user.user.user_type === "company") {
              getAthleteDetail(ChatProfile.athlete_user.athlete_id)
              history.push("/home/profileDetail")
            } else {
              getCompaniesList(ChatProfile.company.id)
              history.push("/home/profileDetail")
            }
          }}
        >
          <h6 className="my-0 text-white"> {ChatProfile.name}</h6>
          <p className="mb-0 text-muted">View more</p>
        </div>
      </div>
      <MessageContainer id="message Container">
        {messages?.map((item, index) => {
          const { text, _id, isImage, image } = item
          const isSender = _id === id
          return (
            <ChatRow sender={isSender} key={`sender-${index}}`}>
              {isImage ? (
                <img
                  src={image}
                  alt={image}
                  onClick={() => setModalShow({ show: true, img: image })}
                />
              ) : (
                text || ""
              )}
            </ChatRow>
          )
        })}
        <div ref={lastMessageRef} />
      </MessageContainer>

      <SendChatContainer>
        <div>
          <button
            onClick={() => attachmentRef.current.click()}
            style={{ background: "transparent", border: "none" }}
          >
            <img src={attachment} alt="" />
          </button>
          <input
            type="file"
            ref={attachmentRef}
            onChange={FilehandleChange}
            style={{ display: "none" }}
            accept="image/*"
          />
        </div>
        {selectedFile ? (
          <div className="input-box">
            {selectedFile?.name}{" "}
            <CloseSvg onClick={() => setSelectedFile(null)} />
          </div>
        ) : (
          <input
            type="text"
            placeholder="Type here..."
            className="input-box"
            name="typedMessage"
            value={userInfo.typedMessage}
            onChange={handleChange}
            onKeyDown={e => {
              if (e.key === "Enter") {
                sendMessage()
              }
            }}
          />
        )}
        <PayBtn onClick={() => setPayShow(!payShow)}>Pay</PayBtn>
        {(userInfo.typedMessage || selectedFile) && (
          <img src={send} onClick={sendMessage} alt="" />
        )}
      </SendChatContainer>
      <Modal
        size="lg"
        show={modalShow.show}
        onHide={() => setModalShow({ ...modalShow, show: false })}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          style={{
            "padding-top": " 0px",
            height: "80vh",
            "justify-content": "center",
            "align-items": "center",
            display: "flex"
          }}
        >
          <img
            src={modalShow.img}
            alt={modalShow.img}
            style={{ paddingTop: "0px", width: "100%" }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={payShow}
        onHide={() => setPayShow(!payShow)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <ModalBody>
            <div className="modal-heading">Pay</div>
            <CardsContainer>
              <>
                <CardsHeader>
                  <span className="title">Default Payment Method</span>
                </CardsHeader>
                <Card key={"wallet"}>
                  <div className="title">
                    <img src={walletImage} alt="" />
                    <div>Wallet</div>
                  </div>
                  <div>
                    {selectDefault?.id === "wallet" && (
                      <span className="default">
                        ${balance?.pending?.[0]?.amount / 100 || 0}
                      </span>
                    )}

                    {selectDefault?.id === "wallet" ? (
                      <SelectedSvg style={{ width: "18px", height: "18px" }} />
                    ) : (
                      <UnselectSvg
                        style={{ width: "18px", height: "18px" }}
                        onClick={() => setSelectDefault({ id: "wallet" })}
                      />
                    )}
                  </div>
                </Card>
                {cardsList ? (
                  cardsList?.map((card, cardIndex) => {
                    return (
                      <Card key={cardIndex}>
                        <div className="title">
                          <CardLogoSvg />
                          <div>**** {card.card?.last4}</div>
                        </div>
                        <div>
                          {selectDefault?.id === card.id && (
                            <span className="default">Selected</span>
                          )}

                          {selectDefault?.id === card.id ? (
                            <SelectedSvg
                              style={{ width: "18px", height: "18px" }}
                            />
                          ) : (
                            <UnselectSvg
                              style={{ width: "18px", height: "18px" }}
                              onClick={() => setSelectDefault(card)}
                            />
                          )}
                        </div>
                      </Card>
                    )
                  })
                ) : (
                  <span>Please Select a default card from wallet</span>
                )}
              </>
            </CardsContainer>
            <div className="inputdivs">
              <div className="input-row">
                $
                <input
                  placeholder="Amount"
                  type="number"
                  name="amount"
                  onChange={onTextChange}
                />
              </div>
              <input
                placeholder="Descripton"
                name="description"
                onChange={onTextChange}
              />
            </div>
            {selectDefault?.card && (
              <Button
                className="modalbtnstyle"
                type="submit"
                onClick={() => {
                  setPayShow(!payShow)
                  paymentsSend()
                }}
              >
                Pay
              </Button>
            )}
            <Button
              className="modalcancel"
              onClick={() => setPayShow(!payShow)}
            >
              Cancel
            </Button>
          </ModalBody>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Inbox
