/* eslint-disable prettier/prettier */
import AthleteDetails from "./athlete-profile"
import CompanyHome from "./home"
import CompanyMatch from "./match"
import CompanyProfile from "./profile"
import Subscription from "./subscriptions"
import MyWallet from "./wallet"
import BankWallet from "./wallet/bank-wallet"
import Withdraw from "./wallet/withdraw"

export {
  CompanyProfile,
  CompanyHome,
  CompanyMatch,
  AthleteDetails,
  Subscription
}

export const COMPANY_ROUTES = [
  { path: "/home", component: CompanyHome },
  { path: "/home/match", component: CompanyMatch },
  { path: "/home/profile", component: CompanyProfile },
  { path: "/home/profileDetail", component: AthleteDetails },

  { path: "/home/wallet", component: MyWallet },
  { path: "/home/walletWithdraw/:key", component: Withdraw },
  { path: "/home/bankwallet", component: BankWallet },
  { path: "/home/subscription", component: Subscription }
]
