import logo_home from "../../../assets/svgs/logo_home.svg"
import "./style.css"
import React from "react"

const LeftSideBar = () => {
  return (
    <div className="leftSide text-center">
      <img src={logo_home} alt=" " />
    </div>
  )
}

export default LeftSideBar
