/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import plaidLogo from "../../../../../../assets/images/Plaid_logo.png"
import tick from "../../../../../../assets/images/tick.png"
import { AgreePolicy, Button, CardBody, Wrapper } from "./style"
import useHook from "./useHook"
import React from "react"
import { useHistory } from "react-router-dom"

const WithdrawCard = () => {
  const withdrawUseHook = useHook()
  const history = useHistory()
  const continueFunc = async () => {
    const res = await withdrawUseHook.getPlaidLinkToken()

    if (res.data.token) {
      const link = `walletWithdraw/${res.data.token}`
      history.push(link, {
        from: "/home/wallet",
        show: true
      })
    }
  }
  return (
    <Wrapper>
      <div className="walletcard">
        <div className="widthdrawl_head">
          <div>Withdraw</div>
          <img src={plaidLogo} style={{ width: "100px", height: "30px" }} />
        </div>
        <CardBody>
          <div className="plaid_desc">
            This application uses Plaid to link your bank
          </div>
          <div className="wallet_secure">
            <img
              src={tick}
              style={{ width: "20px", height: "15px", marginRight: "0.5rem" }}
            />
            <div>
              <span>Secure</span>
              <div className="secure_desc">
                Encription Helps protect your personal financial data
              </div>
            </div>
          </div>
          <div className="wallet_secure">
            <img
              src={tick}
              style={{ width: "20px", height: "15px", marginRight: "0.5rem" }}
            />
            <div>
              <span>Private</span>
              <div className="secure_desc">
                Your credentials will never be made accessible to this
                application
              </div>
            </div>
          </div>
          <div
            onClick={continueFunc}
            style={{ width: "100%", textDecoration: "none" }}
          >
            <Button>Continue</Button>
          </div>
          <AgreePolicy>
            <span className="half_agree">
              By selecting “Continue” you agree to the
              <span className="scndhalf">Plaid End User Privacy Policy</span>
            </span>
          </AgreePolicy>
        </CardBody>
      </div>
    </Wrapper>
  )
}

export default WithdrawCard
