import styled from "styled-components"

export const InputField = styled.input`
  width: 90%;
  background: none;
  border: none;
  border-bottom: 1px solid #636366;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 30px;
  color: white;
  font-size: 20px;

  &:focus {
    outline: none;
  }
`

export const SaveButton = styled.button`
  width: 90%;
  background: #636366;
  display: flex;
  text-align: center;
  justify-content: center;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 10px;
  margin: auto;
  margin-top: 30px;
`

export const SelectField = styled.select`
  width: 100%;
  display: flex;
  margin: auto;
  background: none;
  border: none;
  color: #636366;
  border-bottom: 1px solid;
  margin-top: 30px;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  option {
    background-color: #1c2028;
    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
`

export const MainTab = styled.div`
  width: 100%;
  min-height: 400px;
  margin: 1.5rem auto;
  padding: 20px 1rem;
  color: #e8f0f2;
  border-radius: 2rem;
  height: calc(100% - 14vh);
  overflow-y: scroll;
  justify-content: center;

  .TabsDetail {
    width: 600px;
    margin: auto;
    background: rgba(28, 32, 40, 1);
    flex-direction: column;
    padding: 33px;
    border-radius: 20px;
    height: max-content;
  }
  @media screen and (max-width: 480px) {
    .TabsDetail {
      width: 90%;
    }
  }

  ul.nav {
    width: 75%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 3px solid #373738;
    background-color: #373738;
    border-radius: 8px;
  }
  @media screen and (max-width: 480px) {
    width: 90%;
    margin: 1.5rem auto;

    ul.nav li {
      padding: 6px !important;
    }

    ul.nav {
      width: 90%;
      margin: auto;
    }
  }
  ul.nav li {
    width: 50%;
    padding: 0.2rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-radius: 8px;
  }
  ul.nav li:nth-child(2) {
    border-radius: 0;
    border-radius: 8px;
  }
  ul.nav li.active {
    background: #636366;
  }
  .DatePicker {
    width: 100%;
  }
`
