import AthleteSetup from "./AthleteSetup"
import CompanySetup from "./CompanySetup"
import React from "react"
import { useSelector } from "react-redux"

const SetupAccount = () => {
  const { user } = useSelector(state => state.authentication)
  return user.user_type === "athlete" ? <AthleteSetup /> : <CompanySetup />
}

export default SetupAccount
