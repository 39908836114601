import useHook from "./useHook"
import LeftSideBar from "@containers/Layout/guestSideBar"
import InputFields from "@src/sharedComponents/InputFields"
import FileUpload from "@src/sharedComponents/fileupload/FileUpload"
import {
  // BUSINESS_STRUCTURE_CHOICES,
  INDUSTRIES,
  COUNTRIES,
  US_STATES,
  CAN_STATES
} from "@src/utils/data"
import React from "react"

const CompanySetup = () => {
  const {
    errorValue,
    onSubmitCompany,
    inputValue,
    setErrorValue,
    setInputValue
  } = useHook()

  const inputHandler = e => {
    setErrorValue({ ...errorValue, [e.target.name]: "" })
    setInputValue({ ...inputValue, [e.target.name]: e.target.value })
  }

  const onChange = file => {
    setErrorValue({ ...errorValue, company_logo: "" })
    setInputValue({ ...inputValue, company_logo: file })
  }

  const FIELDS_LIST = [
    {
      label: "Company Name",
      id: "companyName",
      name: "name"
    },
    {
      label: "Industry",
      name: "industry",
      id: "industryType",
      list: INDUSTRIES,
      type: "dropdown"
    },
    {
      label: "Company Email",
      id: "companyEmail",
      name: "company_email"
    },
    {
      label: "Website URL",
      id: "website",
      name: "website_url"
    },
    {
      label: "Phone Number",
      id: "phoneNum",
      name: "phone_number"
    },
    // {
    //   label: "Business Structure *",
    //   name: "business_structure",
    //   id: "business_structure",
    //   list: BUSINESS_STRUCTURE_CHOICES,
    //   type: "dropdown"
    // },
    {
      label: "Country *",
      name: "country",
      id: "countryType",
      list: COUNTRIES,
      type: "dropdown"
    },
    {
      label: "State *",
      name: "state",
      id: "stateType",
      list: inputValue?.country !== "US" ? CAN_STATES : US_STATES,
      type: "dropdown"
    },

    {
      label: "Headquarter *",
      id: "headquarter",
      name: "headquarter"
    },

    {
      label: "About Company *",
      id: "about",
      name: "about"
    }
  ]

  return (
    <div className="mainDiv">
      <LeftSideBar />
      <div className="sign-in">
        <h1>Set up account</h1>

        <FileUpload error={errorValue.company_logo} onselectImage={onChange} />
        <form className="mb-5" onSubmit={onSubmitCompany}>
          <InputFields
            FIELDS_LIST={FIELDS_LIST}
            inputHandler={inputHandler}
            setErrorList={setErrorValue}
            errorList={errorValue}
            setUserInput={setInputValue}
            userInput={inputValue}
          />
          <input className="mt-3 mb-5" type="submit" value="Continue" />
        </form>
      </div>
    </div>
  )
}
export default CompanySetup
