import { userConstants } from "../_constants"
import { profileServices } from "../_services"
import { errorSwal } from "../sharedComponents/swal/Swal"
import { alertActions } from "./"

export const profileActions = {
  profileUpdate,
  header,
  getUserProfile
}

function profileUpdate(payload) {
  return async dispatch => {
    dispatch(alertActions.loading(true))
    await profileServices
      .updateProfile(payload)
      .then(async res => {
        dispatch(alertActions.loading(false))
        await dispatch(success(res.data))
        let redirectLink = "instructions"
        localStorage.setItem("user", JSON.stringify(res.data))
        await localStorage.setItem("stage", redirectLink)
        window.location.href = `/${redirectLink}`
      })
      .catch(function (error) {
        dispatch(alertActions.loading(false))
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
        // throw error
      })
  }
  function success(data) {
    return { type: userConstants.SETUP_COMPLETE, payload: data }
  }
}

function getUserProfile() {
  return async dispatch => {
    dispatch(alertActions.loading(true))
    await profileServices
      .getProfile()
      .then(async res => {
        dispatch(alertActions.loading(false))
        if (res.status >= 400) {
          res = await res.json()
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else if (res.status === 200) {
          res = await res.json()
          dispatch(success({ user: res }))
          localStorage.setItem("user", JSON.stringify(res))
        }
      })
      .catch(function (error) {
        console.log("error", error)
        throw error
      })
  }
  function success(data) {
    return { type: userConstants.LOGIN_SUCCESS, payload: data }
  }
}

function header(data) {
  localStorage.setItem("headerName", data)
  return { type: userConstants.HEADER, payload: data }
}
