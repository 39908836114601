/* eslint-disable prettier/prettier */
import PayCard from "./pay-card"
import { WalletMainWrapper, WalletCol1 } from "./style"
import Transactions from "./transactions"
import WalletCard from "./wallet-card"
import WithdrawCard from "./withdraw-card"
import useHome from "@containers/screens/home/useHook"
import React, { useState } from "react"

const MyWallet = () => {
  const [transactionsList, setTransactionsList] = useState(false)
  const homeHook = useHome()

  React.useEffect(() => {
    homeHook.updateHeader("wallet")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WalletMainWrapper>
      <WalletCol1>
        <WalletCard setTransactionsList={setTransactionsList} />
        <PayCard />
        <WithdrawCard />
      </WalletCol1>
      {transactionsList && <Transactions />}
    </WalletMainWrapper>
  )
}

export default MyWallet
