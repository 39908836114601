import calendarImg from "../../assets/images/Calendar.png"
import "./style.css"
import moment from "moment"
import React, { useEffect, useState } from "react"
import DatePicker from "react-modern-calendar-datepicker"
import "react-modern-calendar-datepicker/lib/DatePicker.css"

const DatePickerCustom = ({ ...props }) => {
  const [selectedDay, setSelectedDay] = useState()

  useEffect(() => {
    if (props.value) {
      let newDate = moment(props.value, "YYYY-M-D")
      setSelectedDay({
        year: newDate.year(),
        month: newDate.month() + 1,
        day: newDate.date()
      })
    }
  }, [props.value])

  // render regular HTML input element
  const renderCustomInput = ({ ref }) => (
    <span>
      <div className={`form-floating position-relative`}>
        <input
          readOnly
          id="dobType"
          ref={ref} // necessary
          placeholder=" "
          value={
            selectedDay
              ? `${selectedDay.month}/${selectedDay.day}/${selectedDay.year}`
              : ""
          }
          className={`form-control my-custom-input-class  ${
            props.editable ? "deselect_input" : ""
          } ${props.error ? "is-invalid" : ""}`} // a styling class
        />
        <label htmlFor="dobType">Date of Birth</label>
        <img
          className={`datepicker-icon  ${props.editable ? "d-none" : ""}`}
          src={calendarImg}
          alt=""
        />
      </div>
      {props.error && <span className="error">{props.error}</span>}
    </span>
  )

  const onChangeDate = e => {
    setSelectedDay(e)
    let myDate = `${e.year}-${e.month}-${e.day}`
    props.selectedDOB(myDate)
    props.onChange()
  }

  return (
    <DatePicker
      value={selectedDay}
      onChange={onChangeDate}
      renderInput={renderCustomInput} // render a custom input
      shouldHighlightWeekends
    />
  )
}

export default DatePickerCustom
