/* eslint-disable prettier/prettier */
import { companyService } from "@src/_services"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useDispatch, useSelector } from "react-redux"

const useHook = () => {
  const dispatch = useDispatch()
  const { plansList, activePlan } = useSelector(state => state.companyReducer)
  const page_position_store = useSelector(
    state => state.homeReducer.page_position
  )

  const { user } = useSelector(state => state.authentication)
  const profile = useSelector(state => state.profile)

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const getPlans = async () => {
    isLoading(true)
    await companyService
      .getPlans()
      .then(async res => {
        isLoading(false)
        dispatch(success(res.data))
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })

    function success(data) {
      return { type: "PLANS_LIST", payload: data }
    }
  }

  const activateSubscription = async data => {
    const ls_card = await localStorage.getItem("defaultCard")

    if (!ls_card || ls_card === "undefined") {
      errorSwal("oops", "Payment Information is required.")
      return
    }
    const card = JSON.parse(ls_card)
    const payload = {
      payment_method: card?.id,
      plan: data
    }
    isLoading(true)
    await companyService
      .activateSubscription(payload)
      .then(async res => {
        isLoading(false)
        dispatch(success(res.data))
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })

    function success(data) {
      return { type: "ACTIVE_PLAN", payload: data }
    }
  }

  const getActivePlan = async () => {
    isLoading(true)
    await companyService
      .getActivePlan()
      .then(async res => {
        isLoading(false)
        dispatch(success(res.data))
      })
      .catch(function (error) {
        isLoading(false)
      })

    function success(data) {
      return { type: "ACTIVE_PLAN", payload: data }
    }
  }

  const upgradeSubsciption = async data => {
    const ls_card = await localStorage.getItem("defaultCard")

    if (!ls_card || ls_card === "undefined") {
      errorSwal("oops", "Payment Information is required.")
      return
    }

    const card = JSON.parse(ls_card)
    const payload = {
      payment_method: card?.id,
      plan: data
    }

    isLoading(true)
    await companyService
      .upgradeSubsciption(payload)
      .then(async () => {
        isLoading(false)
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })
  }

  return {
    isLoading,
    profile,
    page_position_store,
    user,
    getPlans,
    plansList,
    activateSubscription,
    getActivePlan,
    activePlan,
    upgradeSubsciption
  }
}
export default useHook
