/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import useHook from "../useHook"
import WalletCard from "../wallet-card"
import {
  Banks,
  MyBanks,
  SelectBank,
  Wrapper,
  BankWrapper,
  ContinueBtn,
  PayAmount,
  AmountDiv
} from "./style"
import plaidLogo from "@src/assets/images/Plaid_logo.png"
import angleright from "@src/assets/images/angle-right.png"
import profile from "@src/assets/images/profile.png"
import React, { useState, useEffect } from "react"
import { usePlaidLink } from "react-plaid-link"
import { useParams } from "react-router-dom"

const Withdraw = () => {
  const {
    setplaidStripeToken,
    setplaidStripeBank,
    getBanksList,
    payoutToBank
  } = useHook()
  const routeParams = useParams()
  const { key } = routeParams

  const [banksList, setBanksList] = useState([])
  const [withdrawAmount, setWithdrawAmount] = useState("")
  const [walletBank, setWalletBank] = useState(null)

  const { open, ready } = usePlaidLink({
    token: key,
    onSuccess: async (public_token, metadata) => {
      const payload = {
        public_token,
        account_id: metadata.account_id
      }
      const res = await setplaidStripeToken(payload)

      const bankPayload = {
        token: res.bank_account_token
      }
      const stripeBankResponse = await setplaidStripeBank(bankPayload)

      if (stripeBankResponse) {
        getAllBanksList()
      }
    }
  })

  useEffect(() => {
    getAllBanksList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAllBanksList = async () => {
    const res = await getBanksList()
    if (res.count > 0) {
      setBanksList(res.results)
    }
  }

  const withdrawRequest = async () => {
    const payload = {
      amount: withdrawAmount,
      destination: walletBank.id
    }
    const res = await payoutToBank(payload)
    if (res) {
      setWithdrawAmount()
      setWalletBank()
    }
  }

  return (
    <Wrapper>
      <div className="wallet_card">
        <WalletCard />
      </div>
      <BankWrapper>
        <SelectBank>
          <span>Withdraw</span>
          <img src={plaidLogo} alt="" />
        </SelectBank>
        <MyBanks>
          <span>My Banks</span>
          <div className="add_new">
            <span onClick={ready ? open : null}>Add new</span>
            {/* <span>Edit</span> */}
          </div>
        </MyBanks>
        {banksList.map((item, index) => {
          const selected = walletBank?.id === item.id
          return (
            <Banks
              key={index}
              selected={selected}
              onClick={() => {
                if (selected) {
                  setWalletBank()
                  setWithdrawAmount("")
                } else {
                  setWalletBank(item)
                }
              }}
            >
              <img
                src={profile}
                style={{ width: "35px", height: "35px", borderRadius: "100%" }}
              />
              <div className="title_websit_div">
                <div className="bank_desc_div">
                  <div className="bank_title_div">{item.bank_name}</div>
                  <div className="website_bank_div">
                    ************{item.last4}
                  </div>
                </div>
                <img
                  src={angleright}
                  style={{ height: "10px", width: "10px" }}
                />
              </div>
            </Banks>
          )
        })}

        {walletBank?.id && (
          <>
            <PayAmount>Payment Amount</PayAmount>
            <AmountDiv>
              <input
                placeholder="$0.00"
                type="number"
                onChange={e => setWithdrawAmount(e.target.value)}
              />
            </AmountDiv>
          </>
        )}
        {withdrawAmount && (
          <ContinueBtn onClick={withdrawRequest}>Withdraw</ContinueBtn>
        )}
      </BankWrapper>
    </Wrapper>
  )
}

export default Withdraw
