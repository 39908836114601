/* eslint-disable prettier/prettier */
import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 90vh;
  width: 100%;
  overflow: auto;
  @media screen and (min-width: 280px) and (max-width: 600px) {
    flex-direction: column;
    overflow: auto;
    height: 90vh;
  }
`
export const Col1 = styled.div`
  height: 100%;
  width: 50%;
  border-right: 1px solid black;
  padding: 1.5rem;
  overflow: auto;
  @media screen and (min-width: 280px) and (max-width: 600px) {
    padding: 1rem 0.5rem;
    width: 100%;
    height: fit-content;
  }
`
export const WalletCards = styled.div`
  background: rgba(28, 32, 40, 1);
  border-radius: 15px;
`
export const WithdrawlHead = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  color: white;
  font-weight: 500;
  font-size: 20px;
  align-items: center;
  .plaidlogo {
    width: 80px;
    height: 30px;
  }
  @media screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 16px;
    padding: 0.5rem;
    .plaidlogo {
      width: 60px;
      height: 20px;
    }
  }
`
export const WithdrawlCardBody = styled.div`
  padding: 1rem 2rem;
  color: white;
  @media screen and (min-width: 280px) and (max-width: 1150px) {
    padding: 0.5rem;
  }
`
export const MyBanks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 500;
  padding: 0.5rem 0;
  .add_new {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
  }
  @media screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 16px;
    .add_new {
      font-size: 12px;
    }
  }
`
export const BankArrDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const TitleWebsite = styled.div`
  border-bottom: 1px solid grey;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: white;
  align-items: center;
  .bank_desc_div {
    display: flex;
    flex-direction: column;
  }
  .bank_title_div {
    font-size: 14px;
    font-weight: 400;
  }
  .website_bank_div {
    color: #b1b1b1;
    font-size: 12px;
    font-weight: 400;
  }
  @media screen and (min-width: 280px) and (max-width: 1150px) {
    .bank_title_div {
      font-size: 10px;
    }
    .website_bank_div {
      color: #b1b1b1;
      font-size: 10px;
    }
  }
`
export const PayAmount = styled.div`
  color: #b1b1b1;
  font-size: 12px;
  font-weight: 400;
  margin-top: 1rem;
  @media screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 10px;
  }
`
export const AmountDiv = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: white;
  border-bottom: 1px solid grey;
  margin-bottom: 1rem;
  @media screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 12px;
  }
`
export const ContinueBtn = styled.button`
  background: white;
  padding: 0.5rem;
  width: 100%;
  color: black;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
  outline: none;
  border: none;
  @media screen and (min-width: 280px) and (max-width: 1150px) {
    font-size: 12px;
  }
`
