/* eslint-disable prettier/prettier */
import CompanyBank from "./company_bank"
import CompanyCompaigns from "./company_compaign"
import useHook from "../useHook"
import React from "react"

const Home = () => {
  const { updateHeader, page_position_store } = useHook()

  const isBank = page_position_store === "Bank"

  React.useEffect(() => {
    updateHeader("home")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const props = {
    pagename: "home"
  }

  return isBank ? (
    <CompanyBank props={props} />
  ) : (
    <CompanyCompaigns props={props} />
  )
}

export default Home
