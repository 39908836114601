/* eslint-disable jsx-a11y/alt-text */
import Title from "../title"
import useHook from "../useHook"
import React from "react"

const HeaderPage = () => {
  const { page_position_store, updatePagePosition } = useHook()
  const buttonsList = ["Bank", "Campaigns"]

  React.useEffect(() => {
    updatePagePosition("Bank")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Title headerTitle="Home" />
      <div className="header_btns_div">
        {buttonsList.map((item, index) => {
          return (
            <button
              className={page_position_store === item ? "darkbtn" : "lightbtn"}
              key={index}
              onClick={() => {
                updatePagePosition(item)
              }}
            >
              {item}
            </button>
          )
        })}
      </div>
    </>
  )
}

export default HeaderPage
