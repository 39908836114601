/* eslint-disable prettier/prettier */

/* eslint-disable no-unused-expressions */
import { Wrapper } from "./styles"
import "@src/container/Layout/Dashboard/profile.css"
import UserInput from "@src/sharedComponents/userInput"
import instagram from "@svgs/instagram-small.svg"
import React, { Fragment } from "react"
import { useSelector } from "react-redux"

const CompanyDetails = () => {
  const { companyData } = useSelector(state => state.athleteReducer)

  if (!companyData) return <div>No Data</div>
  const {
    company_logo,
    name,
    industry,
    company_email,
    about,
    user,
    headquarter,
    website_url,
    country,
    state,
    phone_number
  } = companyData

  if (!user) return <div>No Data</div>

  return (
    <Wrapper>
      <div className="profile-card">
        <form className="profile_form">
          <img src={company_logo} alt="" className="image-upload-wrap h-272" />
          {/* profile buttons */}
          <div className="profile_social text-white mt-2">
            <div className="social-left">
              <img className="mr-3" src={instagram} alt=" " />
              <div className="media-body">
                <h5 className="mb-0">Instagram</h5>
              </div>
            </div>
          </div>

          {/* profile edit row */}
          <div className="d-flex justify-content-between text-white mt-3">
            <h5 className="mb-0">Personal details {true}</h5>
          </div>
          <Fragment>
            <UserInput readOnly={true} label={"Company Name"} value={name} />
            <UserInput readOnly={true} label={"Industry"} value={industry} />
            <UserInput
              readOnly={true}
              label={"Company Email"}
              value={company_email}
            />
            <UserInput
              readOnly={true}
              label={"Website URL"}
              value={website_url}
            />
            <UserInput readOnly={true} label={"Phone"} value={phone_number} />
            <UserInput readOnly={true} label={"Country"} value={country} />
            <UserInput readOnly={true} label={"State"} value={state} />
            <UserInput
              readOnly={true}
              label={"Head quarter"}
              value={headquarter}
            />
            <UserInput readOnly={true} label={"About Company"} value={about} />
          </Fragment>
        </form>
      </div>
    </Wrapper>
  )
}

export default CompanyDetails
