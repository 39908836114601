/* eslint-disable prettier/prettier */
import Title from "../title"
import useHook from "../useHook"
import React from "react"
import { useSelector } from "react-redux"

const HeaderPage = () => {
  const { page_position_store, updatePagePosition, profile } = useHook()
  const { companyData } = useSelector(state => state.athleteReducer)

  const { headerName } = profile

  React.useEffect(() => {
    updatePagePosition("personalDetails")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!companyData) {
    return <div />
  }

  const buttonsList = [
    { value: "Personal details", key: "personalDetails" },
    { value: "Company deals", key: "deals" }
  ]

  return (
    <>
      <Title
        headerTitle={companyData?.name || headerName}
        isBack="home"
        subTitle="company"
      />
      <div className="header_btns_div">
        {buttonsList.map((item, index) => {
          return (
            <button
              className={
                page_position_store === item.key ? "darkbtn" : "lightbtn"
              }
              key={index}
              onClick={() => {
                updatePagePosition(item.key)
              }}
            >
              {item.value}
            </button>
          )
        })}
      </div>
    </>
  )
}

export default HeaderPage
