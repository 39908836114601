/* eslint-disable prettier/prettier */
import { profileServices, athleteService, sharedService } from "@src/_services"
import { errorSwal, succesSwal } from "@src/sharedComponents/swal/Swal"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

const useHook = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.authentication)
  const page_position_store = useSelector(
    state => state.homeReducer.page_position
  )
  const { campaingsList, campaignNo, companyNo, plansList } = useSelector(
    state => state.athleteReducer
  )
  const { user_type } = user
  const isAthlete = user_type === "athlete"

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }
  const getCampaignsList = async filtersList => {
    isLoading(true)
    await athleteService
      .campainsList(filtersList)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch(success(res.data))
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })

    function success(data) {
      return { type: "CAMPAIGN", payload: data }
    }
  }

  const campaignLikeDislike = async (id, option) => {
    isLoading(true)
    await athleteService
      .campaignLikeDislike(id, { is_liked: option })
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch({ type: "CAMPAING_NO", payload: campaignNo + 1 })
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })

    // function success(data) {
    //   return { type: 'CAMPAIGN', payload: data };
    // }
  }

  const reset = () => {
    dispatch({ type: "CAMPAING_NO", payload: 0 })
    dispatch({ type: "COMPANY_NO", payload: 0 })
  }

  const getCompaniesList = async filtersList => {
    isLoading(true)
    await athleteService
      .companiesList(filtersList)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch(success(res.data))
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })

    function success(data) {
      return { type: "COMPANY_LIST", payload: data }
    }
  }

  const companyLikeDislike = async (id, option) => {
    isLoading(true)
    await athleteService
      .companyLikeDislike(id, { is_liked: option })
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch({ type: "COMPANY_NO", payload: companyNo + 1 })
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })
  }

  const reportCampaing = async (payload, callback) => {
    isLoading(true)
    await athleteService
      .reportCampaing(payload)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
          callback(false)
        } else {
          succesSwal("Thanks you", "Campaign reported successfully")
          callback(true)
        }
      })
      .catch(function (error) {
        isLoading(false)
        callback(false)
        console.log("error", error)
        throw error
      })
  }

  const reportCompany = async (payload, callback) => {
    isLoading(true)
    await athleteService
      .reportCompany(payload)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
          callback(false)
        } else {
          succesSwal("Thanks you", "Campaign reported successfully")
          callback(true)
        }
      })
      .catch(function (error) {
        isLoading(false)
        callback(false)
        console.log("error", error)
        throw error
      })
  }

  const profileUpdate = async payload => {
    isLoading(true)
    await profileServices
      .updateProfile(payload)
      .then(async res => {
        isLoading(false)
        dispatch(success(res.data))
        localStorage.setItem("user", JSON.stringify(res.data))
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
        // throw error
      })

    function success(data) {
      return { type: "USER_SETUP_COMPLETE", payload: data }
    }
  }

  const getMatches = async () => {
    isLoading(true)
    const isBank = page_position_store === "Bank";
    const api = isBank ? `matches/all/` : `campaigns/campaign-likes/?is_liked=true`
    
    await athleteService
      .matchesList(api)
      .then(async res => {
        isLoading(false)
        dispatch(success(res.data))
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })

    function success(data) {
      return { type: "MATCHES_LIST", payload: data }
    }
  }

  const startChat = async data => {
    const payload = { user: data?.user }

    isLoading(true)
    await sharedService
      .startChat(payload)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          const chatInfo = {
            image: data.company_logo,
            name: data.name,
            ...res.data
          }

          dispatch({ type: "START_CHAT", payload: chatInfo })
          history.push("/home/chat")
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })
  }

  const setFiltersList = data => {
    const isBank = page_position_store === "Bank"
    isBank ? getCompaniesList(data) : getCampaignsList(data)
  }

  const getPlans = async () => {
    isLoading(true)
    await athleteService
      .getPlans()
      .then(async res => {
        isLoading(false)
        dispatch(success(res.data))
      })
      .catch(function (error) {
        isLoading(false)
        if (error.response) {
          let message = ""
          for (const [key, value] of Object.entries(error.response.data)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        }
      })

    function success(data) {
      return { type: "PLANS_LIST", payload: data }
    }
  }

  return {
    getCampaignsList,
    campaignLikeDislike,
    getCompaniesList,
    companyLikeDislike,
    isLoading,
    isAthlete,
    user,
    history,
    profileUpdate,
    campaingsList,
    reportCampaing,
    reportCompany,
    getMatches,
    startChat,
    setFiltersList,
    reset,
    getPlans,
    plansList
  }
}
export default useHook
