/* eslint-disable prettier/prettier */
import Title from "../title"
import useHook from "../useHook"
import React from "react"

const HeaderPage = () => {
  const { page_position_store, updatePagePosition } = useHook()

  React.useEffect(() => {
    updatePagePosition("personalDetails")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buttonsList = [
    { value: "Personal details", key: "personalDetails" },
    { value: "My deals", key: "deals" }
  ]

  return (
    <>
      <Title headerTitle={"Profile"} />
      <div className="header_btns_div">
        {buttonsList.map((item, index) => {
          return (
            <button
              className={
                page_position_store === item.key ? "darkbtn" : "lightbtn"
              }
              key={index}
              onClick={() => {
                updatePagePosition(item.key)
              }}
            >
              {item.value}
            </button>
          )
        })}
      </div>
    </>
  )
}

export default HeaderPage
