/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import {
  Wrapper,
  CardsWrapper,
  CardContainer,
  AcceptRejectWrapper
} from "./styles"
import useDeals from "./useHook"
import imgImg from "@src/assets/images/icons/imgImg.png"
import Dropdown from "@src/sharedComponents/dropdown"
import SwitchTabs from "@src/sharedComponents/switchTabs"
// import del from "@svgs/del.svg"
// import edit from "@svgs/edit.svg"
import { validateURL } from "@utils/helpers"
import React, { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
// import { FiPlus } from "react-icons/fi"
import { useSelector } from "react-redux"

const MyDeals = () => {
  // Deals
  const { getDealsList, addDeal, updateDeal, deleteDeal, searchCompany } =
    useDeals()
  const { dealsList } = useSelector(state => state.athleteReducer)

  const [activeTab, setActiveTab] = useState("pending")
  const [showDealModal, setShowDealModal] = useState(false)
  const [compniesList, setCompniesList] = useState([])
  const [inputValues, setInputValues] = useState({ company: null })
  const [showDelete, setShowDelete] = useState({ show: false })
  const [showEdit, setShowEdit] = useState({ show: false })

  useEffect(() => {
    getDealsList()
    companiesSearchMethod("matches/matched-companies/", [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ==========

  const companiesSearchMethod = async (api, data, load) => {
    const res = await searchCompany(api, load)
    if (res?.results) {
      const list = [...data, ...res.results]
      if (res.next) {
        companiesSearchMethod(res.next, list)
      } else {
        const options = list.map(item => {
          return {
            label: item.company.name,
            value: item.company.id
          }
        })

        setCompniesList(options)
      }
    }
  }

  const EditDeal = async () => {
    const payload = {
      title: showEdit.title,
      description: showEdit.description
    }
    updateDeal(showEdit.id, payload)
    setShowEdit({ show: false })
  }

  const acceptOrReject = async (id, approval_status) => {
    const payload = {
      approval_status
    }
    updateDeal(id, payload)
  }

  const Card = () => {
    const data = dealsList.results
    if (!data) return <div>No Data</div>

    const list = data.filter(item => {
      return item.approval_status === activeTab
    })

    return (
      <>
        {list?.map((item, index) => {
          const { id, title, featured_image, description, athlete_user } = item

          return (
            <CardContainer key={index}>
              <div
                className="card-header"
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ display: "flex" }}>
                  <img
                    src={athlete_user.profile_picture}
                    className="logo-img"
                  />

                  <div className="card-title">
                    <strong>{`${athlete_user.first_name} ${athlete_user.last_name}`}</strong>
                    <span>{`${athlete_user.email} (Athlete)`}</span>
                  </div>
                </div>
                {/* <div>
                  <img
                    src={edit}
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setShowEdit({ ...showEdit, show: true, ...item })
                    }}
                  />
                  <img
                    src={del}
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setShowDelete({ ...showDelete, show: true, ...item })
                    }}
                  />
                </div> */}
              </div>
              <img src={featured_image} className="main-image" />
              <div className="back_card_body">
                <strong>{title}</strong>
                <p className="bank_para_div" styls={{ textAlign: "center" }}>
                  {description}
                </p>
                {activeTab === "pending" && (
                  <AcceptRejectWrapper>
                    <button
                      className="like_btn"
                      onClick={() => acceptOrReject(id, "approved")}
                    >
                      Approve
                    </button>
                    <button
                      className="like_btn"
                      onClick={() => acceptOrReject(id, "rejected")}
                    >
                      Decline
                    </button>
                  </AcceptRejectWrapper>
                )}
              </div>
            </CardContainer>
          )
        })}
      </>
    )
  }

  const submitDeal = async () => {
    let formData = new FormData()

    for (const [key, value] of Object.entries(inputValues)) {
      if (key === "link" && !validateURL(value)) {
        formData.append(key, `https://${value}`)
      } else {
        formData.append(key, value)
      }
    }
    addDeal(formData, () => {
      setShowDealModal(false)
    })
  }

  const data = dealsList.results
  if (!data) return <div>No Data</div>
  return (
    <Wrapper>
      <SwitchTabs
        TABS={["approved", "pending"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        style={{
          margin: 10
        }}
      />
      <CardsWrapper>
        {/* <div className="add-button" onClick={() => setShowDealModal(true)}>
          <FiPlus />
        </div> */}
        <Card />
      </CardsWrapper>

      {/* Add Modal */}
      <Modal
        show={showDealModal}
        onHide={() => setShowDealModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div className="modal__body1">
            <div className="addcompaignhead">Add new deal</div>
            <div className="inputdivs">
              <input
                style={{ color: "white" }}
                placeholder="Title"
                className="modalinput"
                name="title"
                onChange={event => {
                  const { name, value } = event.target
                  setInputValues({ ...inputValues, [name]: value })
                  searchCompany({ search: value })
                }}
              />
              <input
                style={{ color: "white" }}
                placeholder="Description"
                className="modalinput"
                name="description"
                onChange={event => {
                  const { name, value } = event.target
                  setInputValues({ ...inputValues, [name]: value })
                }}
              />
              <input
                placeholder="Link"
                className="modalinput"
                name="deal"
                onChange={event => {
                  const { name, value } = event.target
                  setInputValues({ ...inputValues, [name]: value })
                }}
                style={{ color: "#fff" }}
              />
              <Dropdown
                width="100%"
                valueKey="value"
                onChange={e =>
                  setInputValues({ ...inputValues, company: e.value })
                }
                getLabel={o => o.label}
                searchable
                // disabled={false}
                options={compniesList}
                placeholder="Tag a company"
                value={inputValues.company}
              />
            </div>
            <div className="image-upload-wrap" style={{ marginBottom: "2rem" }}>
              <input
                className="file-upload-input"
                type="file"
                id="fileControl"
                accept="image/*"
                name="image"
                onChange={event => {
                  // const { name, value } = event.target.files[0];
                  setInputValues({
                    ...inputValues,
                    featured_image: event.target.files[0]
                  })
                }}
              />
              {inputValues && inputValues?.featured_image ? (
                <img
                  src={URL.createObjectURL(inputValues.featured_image)}
                  style={{
                    height: "162px",
                    width: "100%",
                    borderRadius: "24px"
                  }}
                />
              ) : (
                <>
                  <img src={imgImg} style={{ margin: "1rem 0" }} />
                  <label htmlFor="fileControl" className="drag-text">
                    Upload Image
                  </label>
                </>
              )}
            </div>
            <Button
              className="modal_btn_style"
              type="submit"
              onClick={submitDeal}
            >
              Save
            </Button>
            <Button
              className="modalcancel"
              onClick={() => setShowDealModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={showDelete.show}
        onHide={() => setShowDelete({ ...showDelete, show: false })}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div className="modal__body1">
            <div className="addcompaignhead">Delete Deal</div>
            <div className="inputdivs">
              Are you sure you want to delete this "Deal"?
            </div>
            <Button
              className="modalcancel"
              onClick={() => setShowDelete({ ...showDelete, show: false })}
            >
              No
            </Button>
            <Button
              className="modal_btn_style"
              type="submit"
              onClick={() => {
                deleteDeal(showDelete.id)
                setShowDelete({ ...showDelete, show: false })
              }}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Edit Modal */}
      <Modal
        show={showEdit && showEdit.show}
        onHide={() => setShowEdit({ show: false })}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ paddingTop: "0px" }}>
          <div className="modal__body1">
            <div className="addcompaignhead">Edit Deal</div>
            <div className="inputdivs">
              <input
                style={{ color: "white" }}
                placeholder="Tile"
                className="modalinput"
                value={showEdit.title}
                name="title"
                onChange={event => {
                  const { name, value } = event.target
                  setShowEdit({ ...showEdit, [name]: value })
                }}
              />

              <input
                style={{ color: "white" }}
                placeholder="Description"
                className="modalinput"
                value={showEdit.description}
                name="description"
                onChange={event => {
                  const { name, value } = event.target
                  setShowEdit({ ...showEdit, [name]: value })
                }}
              />
            </div>
            <Button
              className="modalcancel"
              onClick={() => setShowEdit({ show: false })}
            >
              No
            </Button>
            <Button
              className="modal_btn_style"
              type="submit"
              onClick={() => EditDeal()}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}

export default MyDeals
