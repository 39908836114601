/* eslint-disable prettier/prettier */

/* eslint-disable react-hooks/exhaustive-deps */
import { profileActions, userActions } from "../../../_actions"
import rightArrow from "../../../assets/images/angle-right.png"
import "../../../container/Layout/Dashboard/header.css"
import "../../../container/Layout/Dashboard/settings.css"
import SwitchButton from "../../../sharedComponents/switchButton"
import React, { useEffect, useState, Fragment } from "react"
import { Button, Modal } from "react-bootstrap"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

const SettingsPage = ({ logout, header }) => {
  let [pagesList] = useState([
    { name: "Notifications", link: "/home/notifications", type: "button" },
    { name: "Payments and Billing", link: "/home/payments", type: "link" },
    { name: "Subscriptions", link: "/home/subscription", type: "link" },
    { name: "Terms and Conditions", link: "/home/terms", type: "link" },
    { name: "Privacy Policy", link: "/home/policy", type: "link" },
    { name: "Delete Account", link: "/home/delete", type: "delModal" },
    { name: "Log Out", link: "/home/logout", type: "logoutModal" }
  ])

  const history = useHistory()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [showAcc, setShowAcc] = useState(false)
  const handleAccClose = () => setShowAcc(false)
  const handleAccShow = () => setShowAcc(true)
  useEffect(() => {
    header("settings")
  }, [])

  const onclickLogout = () => {
    setShow(false)
    logout()
  }

  const onclickAccDel = () => {
    setShowAcc(false)
  }

  const onclickOption = user => {
    if (user.type === "link") {
      history.push(user.link, { from: "/home/settings", show: true })
      header(user.name)
    } else if (user.type === "delModal") handleAccShow(true)
    else if (user.type === "logoutModal") handleShow(true)
  }

  return (
    <Fragment>
      {pagesList.map((name, index) => (
        <div
          className="options_main"
          key={index}
          onClick={() => onclickOption(name)}
        >
          <span className="option_name text-white">{name.name}</span>
          {name.type === "button" ? (
            <SwitchButton />
          ) : (
            <span className="option_icon">
              <img src={rightArrow} alt="" className="option_img" />
            </span>
          )}
        </div>
      ))}

      {/* log out modal  */}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal__body">
            <div className="m-log">Are you sure you want to Logout?</div>
            <Button
              className="w-100"
              type="submit"
              onClick={() => onclickLogout()}
            >
              Yes
            </Button>
            <div className="m-confirm" onClick={() => handleClose()}>
              Cancel
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* deactivated modal  */}
      <Modal
        show={showAcc}
        onHide={handleAccClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal__body">
            <div className="m-log">
              Are you sure you want to delete your account?
            </div>
            <Button
              className="w-100 bg-danger text-white"
              type="submit"
              onClick={() => onclickAccDel()}
            >
              Yes
            </Button>
            <div className="m-confirm" onClick={() => handleAccClose()}>
              Cancel
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
function mapState(state) {
  const { profile } = state
  return { profile }
}

const actionCreators = {
  logout: userActions.logout,
  header: profileActions.header
}

export default connect(mapState, actionCreators)(SettingsPage)
