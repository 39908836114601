/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import Cards from "./cards"
import DigigalPayments from "./digital-payment"
import {
  Wrapper,
  ButtonWrapper,
  SelectionButton,
  TitleWrapper,
  MainContainer
} from "./styles"
import React from "react"
import { useState } from "react"

const WithdrawCard = () => {
  const [card, setCard] = useState("Card")

  return (
    <Wrapper>
      <TitleWrapper>
        <div>Payment</div>
      </TitleWrapper>
      <MainContainer>
        <ButtonWrapper>
          <SelectionButton
            active={card === "Card"}
            onClick={() => setCard("Card")}
          >
            Card
          </SelectionButton>
          <SelectionButton
            active={card === "Digital Wallet"}
            onClick={() => setCard("Digital Wallet")}
          >
            Digital Wallet
          </SelectionButton>
        </ButtonWrapper>

        {card === "Card" && <Cards />}
        {card === "Digital Wallet" && <DigigalPayments />}
      </MainContainer>
    </Wrapper>
  )
}

export default WithdrawCard
