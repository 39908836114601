/* eslint-disable prettier/prettier */
import { profileActions } from "@src/_actions"
import { companyService, sharedService, athleteService } from "@src/_services"
import { errorSwal } from "@src/sharedComponents/swal/Swal"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

const useHook = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [userInfo, setUserInfo] = useState({})
  const [messages, setMessages] = useState([])
  const [selectedFile, setSelectedFile] = useState()
  const [ChatProfile, setChatProfile] = useState(false)
  const { companyList } = useSelector(state => state.athleteReducer)
  const profile = useSelector(state => state.profile)
  const user = useSelector(state => state.authentication)
  const [cardsList, setCardsList] = useState(null)

  const { chatGroups } = useSelector(state => state.sharedReducer)

  useEffect(() => {
    updateHeader("chat")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { user_type } = user.user
  const isAthlete = user_type === "athlete"

  const isLoading = value => {
    dispatch({ type: "LOADER_STATUS", message: value })
  }

  const getChatGroups = async () => {
    // dispatch({ type: "START_CHAT", payload: [] })
    setMessages([])
    isLoading(true)
    await sharedService
      .chatGroups()
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          apiErrorHandler(res.data)
        } else {
          dispatch(success(res.data))
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })

    function success(data) {
      let chats = []
      console.log({ isAthlete, data })
      if (isAthlete) {
        data?.results?.forEach(element => {
          const { name, company_logo } = element?.company
          chats.push({
            name,
            image: company_logo,
            ...element
          })
        })
      } else {
        data?.results?.forEach(element => {
          const { first_name, last_name, profile_picture } =
            element.athlete_user
          chats.push({
            image: profile_picture,
            name: `${first_name} ${last_name}`,
            ...element
          })
        })
      }

      return { type: "CHAT_GROUPS", payload: chats }
    }
  }

  const handleMessageSender = async (payload, message, callback) => {
    if (selectedFile) {
      uploadChatMedia(payload, message, callback)
    } else {
      updateLastMessage(payload, message, callback)
    }
  }

  const updateLastMessage = async (payload, msg, callback) => {
    const pay = payload

    pay["last_message"] = payload.last_message ? payload.last_message : "Photo"
    isLoading(true)
    await sharedService
      .updateLastMessage(payload.id, pay)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          apiErrorHandler(res)
        } else {
          callback(msg)
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })
  }

  const uploadChatMedia = async (room, msg, callback) => {
    const formdata = new FormData()
    formdata.append("file", selectedFile)

    isLoading(true)
    await sharedService
      .uploadChatMedia(room.id, formdata)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          apiErrorHandler(res)
        } else {
          setSelectedFile()
          const data = res?.data
          const message = {
            media: data.file,
            image: data.file,
            _id: msg._id,
            timetoken: Date.now(),
            user: msg.user,
            createdAt: new Date(),
            isImage: true
          }
          updateLastMessage(room, message, callback)
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })
  }

  const getPaymentMethods = async payload => {
    isLoading(true)
    return await sharedService
      .getPaymentMethods(payload)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          apiErrorHandler(res)
        } else {
          setCardsList(res.data.results)
          dispatch({ type: "CARDS_LIST", payload: res.data.results })
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })
  }

  const apiErrorHandler = res => {
    let message = ""
    for (const [key, value] of Object.entries(res)) {
      message = message + `${key} : ${JSON.stringify(value)} `
    }
    errorSwal("oops", message)
  }
  const getAthleteDetail = async id => {
    isLoading(true)
    await companyService
      .athleteDetail(id)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch(success(res.data))
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })

    function success(data) {
      return { type: "ATHLETE_DETAILS", payload: data }
    }
  }
  const updateHeader = value => {
    dispatch(profileActions.header(value))
  }
  const getCompaniesList = async filtersList => {
    isLoading(true)
    await athleteService
      .companiesList(filtersList)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          let message = ""
          for (const [key, value] of Object.entries(res)) {
            message = message + `${key} : ${JSON.stringify(value)} `
          }
          errorSwal("oops", message)
        } else {
          dispatch(success(res.data))
        }
      })
      .catch(function (error) {
        isLoading(false)
        console.log("error", error)
        throw error
      })

    function success(data) {
      return { type: "COMPANY_DETAILS", payload: data }
    }
  }
  const paymentSend = async (payload, callback) => {
    isLoading(true)
    await sharedService
      .paymentSend(payload)
      .then(async res => {
        isLoading(false)
        if (res.status >= 400) {
          apiErrorHandler(res.data)
        }
      })
      .catch(function (error) {
        isLoading(false)
        apiErrorHandler(error.response.data)
        throw error
      })
  }

  return {
    isLoading,
    getChatGroups,
    profile,
    history,
    isAthlete,
    chatGroups,
    user,
    getAthleteDetail,
    ChatProfile,
    setChatProfile,
    userInfo,
    setUserInfo,
    messages,
    setMessages,
    updateLastMessage,
    handleMessageSender,
    uploadChatMedia,
    setSelectedFile,
    selectedFile,
    getCompaniesList,
    companyList,
    paymentSend,
    cardsList,
    getPaymentMethods
  }
}
export default useHook
