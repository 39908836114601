/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/alt-text */
import useHook from "../useHook"
import ReportModal from "@components/report"
import dislike from "@svgs/dislike.svg"
import like from "@svgs/like.svg"
import report from "@svgs/report.svg"
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"

const Compaigns = ({ props }) => {
  const { campaingsList, campaignNo } = useSelector(
    state => state.athleteReducer
  )

  const { getCampaignsList, campaignLikeDislike, reportCampaing, reset } =
    useHook()
  const [showReportModal, setShowReportModal] = useState(false)
  const [reportInfo, setReportInfo] = useState({})

  const submitReport = () => {
    reportCampaing(reportInfo, res => {
      if (res) {
        setShowReportModal(false)
        setReportInfo({})
      }
    })
  }

  useEffect(() => {
    getCampaignsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (campaignNo === campaingsList?.results?.length - 3) {
      getCampaignsList()
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignNo])

  if (!campaingsList.results || !campaingsList.results[campaignNo])
    return <div>No data</div>

  const isHome = props.pagename === "home"
  const { id, company, image, description, slug } =
    campaingsList.results[campaignNo]
  const { industry, company_logo, name } = company

  return (
    <div className={isHome ? "bank_card_div" : "bank_card_div1"}>
      <div className={isHome ? "bank_card_head" : "bank_card_head1"}>
        <div className="nike_logo_div">
          <img src={company_logo} />
        </div>
        <div className="head_content_div">
          <span className="nike_head">{name}</span>
          <span className="sub_head">{industry}</span>
        </div>
      </div>
      <img src={image} className="main-img" />
      <div className="back_card_body">
        <p className="bank_para_div" style={{ textAlign: "center" }}>
          {description}
        </p>
        <p className="bank_para_div" style={{ textAlign: "center" }}>
          {slug}
        </p>
        <div
          className="reportdiv"
          onClick={() => {
            setReportInfo({ ...reportInfo, campaign: id })
            setShowReportModal(true)
          }}
        >
          <img
            src={report}
            style={{ width: "17px", height: "17px", marginRight: "10px" }}
          />
          <span className="location_name">Report</span>
        </div>
        <div className={isHome ? "like_dislike_btns_div" : "no_display"}>
          <button
            className="like_btn"
            onClick={() => campaignLikeDislike(id, false)}
          >
            <img src={dislike} style={{ marginRight: "10px" }} />
            Dislike
          </button>
          <button
            className="like_btn"
            onClick={() => campaignLikeDislike(id, true)}
          >
            <img src={like} style={{ marginRight: "10px" }} />
            Like
          </button>
        </div>
      </div>

      <ReportModal
        show={showReportModal}
        handleClose={() => setShowReportModal(false)}
        submitReport={submitReport}
        onChange={e =>
          setReportInfo({ ...reportInfo, description: e.target.value })
        }
      />
    </div>
  )
}

export default Compaigns
