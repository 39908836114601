import Athlete from "./athlete"
import Company from "./company"
import { useSelector } from "react-redux"

function Sidebar() {
  const { user } = useSelector(state => state.authentication)
  return user.user_type === "athlete" ? <Athlete /> : <Company />
}

export default Sidebar
