/* eslint-disable prettier/prettier */

/* eslint-disable no-unused-expressions */
import useHook from "../../useHook"
import { Wrapper } from "./styles"
import "@src/container/Layout/Dashboard/profile.css"
import UserInput from "@src/sharedComponents/userInput"
import {
  FacebookSvg,
  InstagramSvg,
  LinkedinSvg,
  TiktokSvg,
  TwitterSvg,
  youtubeSvg,
  WorldSvg
} from "@svgs"
import { socialDataParser } from "@utils/helpers"
import React, { useEffect, Fragment, useState } from "react"

const Profile = () => {
  const { athleteDetail } = useHook()
  const [athleteData, setAthleteDate] = useState(null)

  useEffect(() => {
    setAthleteDate(athleteDetail)
  }, [athleteDetail])
  if (!athleteData) return <div>No Data</div>

  const {
    date_of_birth,
    user,
    gender,
    country,
    state,
    school,
    bio,
    phone_number,
    social_accounts
  } = athleteData

  if (!user) return <div>No Data</div>
  const { first_name, last_name, profile_picture } = user

  const Social_Icon = {
    facebook: FacebookSvg,
    google: youtubeSvg,
    linkedin_oauth2: LinkedinSvg,
    twitter: TwitterSvg,
    tiktok: TiktokSvg,
    instagram: InstagramSvg
  }

  const instagram = social_accounts?.find(e => e?.provider === "instagram")
  const instagramItem = instagram ? socialDataParser(instagram) : null

  return (
    <Wrapper>
      <div className="profile-card">
        <form className="profile_form">
          <img
            src={profile_picture}
            alt=""
            className="image-upload-wrap h-272"
          />
          {/* profile buttons */}
          {instagramItem && (
            <div
              className="profile_social text-white mt-2"
              onClick={() => window.open(instagramItem.profileLink)}
            >
              <div className="social-left">
                <InstagramSvg className="mr-3" />
                <div className="media-body">
                  <h5 className="my-0">{instagramItem.name}</h5>
                  <p className="mb-0">Instagram</p>
                </div>
              </div>
            </div>
          )}

          <div className="location_div">
            {social_accounts?.map((val, index) => {
              const Icon = Social_Icon[val.provider] || WorldSvg
              const item = socialDataParser(val)

              if (val.provider !== "instagram") {
                return (
                  <span
                    onClick={
                      item.profileLink
                        ? () => window.open(item.profileLink)
                        : null
                    }
                    className="social_icon"
                    key={index}
                  >
                    <Icon />
                  </span>
                )
              } else return <></>
            })}
          </div>

          {/* profile edit row */}
          <div className="d-flex justify-content-between text-white mt-3">
            <h5 className="mb-0">Personal details {true}</h5>
          </div>
          <Fragment>
            <UserInput
              readOnly={true}
              label={"First Name"}
              value={first_name}
            />
            <UserInput readOnly={true} label={"Last Name"} value={last_name} />
            <UserInput
              readOnly={true}
              label={"Date of birth"}
              value={date_of_birth}
            />
            <UserInput readOnly={true} label={"Gender"} value={gender} />
            <UserInput readOnly={true} label={"Country"} value={country} />
            <UserInput readOnly={true} label={"State"} value={state} />
            <UserInput readOnly={true} label={"School"} value={school} />
            {/* <UserInput readOnly={true} label={'email'} value={} /> */}
            <UserInput
              readOnly={true}
              label={"Phone Number"}
              value={phone_number}
            />
            <UserInput readOnly={true} label={"Bio"} value={bio} />
          </Fragment>
        </form>
      </div>
    </Wrapper>
  )
}

export default Profile
