/* eslint-disable prettier/prettier */
export function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem("token")
  if (token) {
    return {
      // 'Content-Type': 'multipart/form-data; boundary=something',
      Authorization: "Token " + token
      // 'Accept':' */*'
    }
  } else {
    return {}
  }
}

export function authHeaderFormData() {
  // return authorization header with jwt token
  let token = localStorage.getItem("token")
  if (token) {
    return {
      "Content-Type": "multipart/form-data",
      Authorization: "Token " + token
    }
  } else {
    return {}
  }
}

export function authHeaderAxios() {
  // return authorization header with jwt token
  let token = localStorage.getItem("token")
  if (token) {
    return {
      headers: { Authorization: `Token ${token}` }
    }
  }
}
