import { NotiContainer } from "./style"
import { getLocalTimeFromNow } from "@src/utils/helpers"
import React from "react"
import { useSelector } from "react-redux"

export const MatchNotification = ({ data, onDelete, onRead }) => {
  const user = useSelector(state => state.authentication)
  const { user_type } = user.user
  const isCompany = user_type === "company"

  const userInfo = data.data
  const userName = isCompany ? userInfo.athlete_name : userInfo.company_name
  const img = isCompany
    ? userInfo.athlete_profile_picture
    : userInfo.company_logo

  return (
    <NotiContainer isRead={data.is_read}>
      <div className="notirowclass">
        <img src={img} alt="" />
        <div className="columnclass">
          <div className="rowclass">
            <div className="name">{userName}</div>
            <div className="time">{getLocalTimeFromNow(data.created)}</div>
          </div>
          <div className="time">{data.body}</div>
        </div>
      </div>
      <div className="notiactions">
        {!data.is_read && (
          <div
            className="action"
            style={{ borderBottom: "1px solid rgba(74, 81, 96, 1)" }}
            onClick={onRead}
          >
            Mark as read
          </div>
        )}
        <div className="action" onClick={onDelete}>
          Delete
        </div>
      </div>
    </NotiContainer>
  )
}
