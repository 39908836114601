import Company from "./company"
import Owner from "./owner"
import { MainTab } from "./style"
import React, { useState } from "react"

const CompanyPaymentPage = props => {
  const [activeTab, setActiveTab] = useState(1)

  return (
    <MainTab>
      <ul className="nav">
        <li
          className={activeTab ? "active" : ""}
          onClick={() => setActiveTab(1)}
        >
          Owner Details
        </li>
        <li
          className={!activeTab ? "active" : ""}
          onClick={() => setActiveTab(0)}
        >
          Company Details
        </li>
      </ul>
      <div className="outlet">
        {activeTab ? <Owner {...props} /> : <Company {...props} />}
      </div>
    </MainTab>
  )
}

export default CompanyPaymentPage
