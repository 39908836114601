/* eslint-disable react-hooks/exhaustive-deps */
import useHook from "./useHook"
import "@containers/Layout/Dashboard/policy.css"
import { profileServices } from "@src/_services"
import IG_small from "@src/assets/images/social-icons/IG-small.png"
import instagram_img from "@src/assets/svgs/instagram.svg"
import social_new from "@src/assets/svgs/social.svg"
import UserInput from "@src/sharedComponents/userInput"
import {
  FACEBOOK_OAUTH,
  INSTAGRAM_OAUTH,
  LINKEDIN_OAUTH,
  TIKTOK_OAUTH,
  TWITTER_OAUTH,
  YOUTUBE_OAUTH
} from "@src/utils/social_data"
import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"

const SocialLinks = () => {
  const { socialList, setSocialList, isLoading } = useHook()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [showIG, setShowIG] = useState(false)
  const handleCloseIG = () => setShowIG(false)
  const handleShowIG = () => setShowIG(true)

  const [socialFields, setSocialFields] = useState({
    name: "",
    url: "",
    img: social_new,
    linked: false
  })
  const [nameError, setNameError] = useState("")
  const [urlError, setUrlError] = useState("")

  const handleChange = e => {
    setSocialFields({ ...socialFields, [e.target.name]: e.target.value })
  }

  const onSubmitForm = async e => {
    e.preventDefault()

    if (socialFields.name.trim().length <= 0) await setNameError("Invalid name")
    else if (socialFields.url.trim().length <= 0)
      await setUrlError("Invalid url")
    else {
      handleClose()
      await setSocialList([...socialList, socialFields])
      setSocialFields({ name: "", url: "", img: social_new, linked: false })
    }
  }

  const onClickLink = async link => {
    switch (link) {
      case "instagram":
        handleCloseIG()
        window.location.href = INSTAGRAM_OAUTH(link)
        break
      case "facebook":
        window.location.href = FACEBOOK_OAUTH(link)
        break
      case "linkedin_oauth2":
        window.location.href = LINKEDIN_OAUTH(link)
        break
      case "twitter":
        window.location.href = TWITTER_OAUTH(link)
        break
      case "tiktok":
        const oauthLink = await TIKTOK_OAUTH(link)
        window.location.href = oauthLink
        break
      case "google":
        window.location.href = YOUTUBE_OAUTH(link)
        break
      default:
        break
    }
  }

  const handleUnlink = async provider => {
    isLoading(true)
    if (provider !== "instagram") {
      await profileServices.unlinkSocial({
        provider
      })
      const newState = socialList.map(obj => {
        if (obj.link === provider) {
          return { ...obj, details: null }
        }
        return obj
      })
      setSocialList(newState)
      isLoading(false)
    } else {
      isLoading(false)
      handleShowIG()
    }
  }

  return (
    <div className="social_main">
      <h5 className=" text-white mt-3 mb-4">
        Add your social media for better promotions inside the app
      </h5>
      {socialList.map((list, index) => {
        const { details } = list || {}
        const isLinked = details ? true : false
        const profilePicture = list.img
        // const profilePicture = list?.details?.profilePicture || list.img
        return (
          <div className="profile_social social-bg text-white mt-3" key={index}>
            <div className="social-left">
              <div
                className="social-img"
                onClick={() =>
                  details?.profileLink
                    ? window.open(details?.profileLink)
                    : null
                }
              >
                <img className="mr-3" src={profilePicture} alt=" " />
              </div>

              <div className="media-body">
                <h5 className="my-0">
                  {isLinked ? `${details.name}` : `${list.name}`}
                </h5>
                {isLinked && <p className="mb-0">{list.name}</p>}
              </div>
            </div>

            <h6
              className="my-0"
              onClick={() =>
                isLinked ? handleUnlink(list.link) : onClickLink(list.link)
              }
            >
              {isLinked ? "Unlink" : "Link"}
            </h6>
          </div>
        )
      })}
      <div className="add-round my-3" onClick={handleShow}>
        +
      </div>

      {/* add social modal  */}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div
            className="modal__body"
            style={{ display: "inherit", textAlign: "start" }}
          >
            <div className="m-log mb-2">New social media</div>
            <form className="social-form" onSubmit={onSubmitForm}>
              <UserInput
                label={"Name"}
                id="socialName"
                name="name"
                error={nameError}
                value={socialFields.name}
                onChange={e => {
                  handleChange(e)
                  setNameError("")
                }}
              />
              <UserInput
                label={"URL"}
                id="socialurl"
                name="url"
                error={urlError}
                value={socialFields.url}
                onChange={e => {
                  handleChange(e)
                  setUrlError("")
                }}
              />
              <Button className="w-100 mt-4" type="submit">
                Yes
              </Button>
              <div
                className="m-confirm text-center"
                onClick={() => handleClose()}
              >
                Cancel
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      {/* instagaram modal  */}
      <Modal
        show={showIG}
        onHide={handleCloseIG}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div
            className="modal__body"
            style={{ display: "inherit", textAlign: "start" }}
          >
            <div className="m-log mb-2">Link your instagram</div>
            <div className="mute-text" style={{ textAlign: "left" }}>
              Please add your new instagram account before deleting existing
              one.
            </div>
            <form onSubmit={() => onClickLink("instagram")}>
              <span className="d-flex justify-content-center mt-5 mb-4">
                <img width={124} height={124} src={instagram_img} alt=" " />
              </span>
              <button className="my-3" type="submit">
                <img style={{ paddingRight: "10px" }} src={IG_small} alt=" " />
                Connect your Instagram
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SocialLinks
